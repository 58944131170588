//*************************
// STAR RATING Component
//*************************

// definitions
$starSize: 24px; // dimension of one star

@mixin stars {
	display: inline-block;
	height: $starSize;
	background-color: transparent;
	background-position: 0 0;
	background-repeat: repeat;
}

// rating
.rating {
	width: 100%;

	.rating-header {
		margin-bottom: $bu/4;
	}

	.stars {
		@include stars;
		width: $starSize*5;
		position: relative;

		&:before {
			@include stars;
			content: "";
			width: 0%;
			position: absolute;
			top: 0;
			left: 0;
		}

		&.stars-orange {
			background-image: url(../../i/v3/components/icons/sprites/star-muted.svg);

			&:before {
				background-image: url(../../i/v3/components/icons/sprites/star.svg);
			}
		}


		// ratings ---> 5 stars is 50, 4.5 stars is 45 etc.
		&.value50:before { width: 100%; }
		&.value45:before { width: 90%; }
		&.value40:before { width: 80%; }
		&.value35:before { width: 70%; }
		&.value30:before { width: 60%; }
		&.value25:before { width: 50%; }
		&.value20:before { width: 40%; }
		&.value15:before { width: 30%; }
		&.value10:before { width: 20%; }
		&.value05:before { width: 10%; }
		&.value00:before { width: 0%; }
	}


	&:not(.only-stars) {
		text-align: center;
	}

	&.only-stars .rating-values .stars {
		margin: 0;
	}

	.rating-values {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: $bu/2 12px;

		.stars {
			margin: 0 auto;
		}

		&.values-inline {
			@include media-breakpoint-up(lg) {
				flex-direction: row;
				align-items: center;

				.stars {
					margin: 0;
				}
			}
		}

		.text-footer {
			a {
				text-decoration: dotted;
				text-decoration-line: underline;
				text-decoration-thickness: 1px;
			}
		}

		@include media-breakpoint-up(lg) {
			gap: 12px $bu;
		}
	}

	// itemprop data
	[itemprop="ratingValue"] {
		display: none;
	}
}