.techcorner {
	padding: $bu*1.5;
	border-radius: 0 $bu*1.5 $bu*1.5;
	position: relative;
	text-align: start;

	.h7 {
		margin-bottom: 12px;
	}

	@include media-breakpoint-up(lg) {
		padding: $bu*2;
		border-radius: 0 $bu*2 $bu*2;

		.h7 {
			margin-bottom: $bu;
		}
	}

	.tc-content {
		position: relative;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-bottom: $bu;
		transition: all .5s ease;
		-webkit-mask-image: linear-gradient(black, black);
		mask-image: linear-gradient(black, black);

		@include media-breakpoint-up(lg) {
			margin-bottom: $bu*1.5;
		}

		&.collapse:not(.show) {
			display: block;
			height: $bu*4 !important;
			-webkit-mask-image: linear-gradient(black, transparent);
			mask-image: linear-gradient(black, transparent);
		}

		&.collapsing {
			&[style*='height: 0px'] {
				height: $bu*4 !important;
				-webkit-mask-image: linear-gradient(black, transparent);
				mask-image: linear-gradient(black, transparent);
			}
		}
	}

	.hide-label,
	.show-label {
		text-decoration: underline;
	}

	// Corner style
	&:before,
	&:after {
		content: '';
		display: block;
		background-color: $orange-bright;
		position: absolute;
		border-radius: 3px;
	}

	&:before {
		width: $bu*3;
		height: 3px;
		top: -2px;
		left: -2px;
	}

	&:after {
		width: 3px;
		height: $bu*3;
		top: -2px;
		left: -2px;
	}

	&.inverse {
		.btn.link {
			color: $white;
		}
	
		.h7 {
			color: $orange-bright;
		}
	}
}
