// Select dropdown
.form-select,
.custom-select {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	outline: none;
	border-radius: 400px;
	padding: 0;
	padding-inline-start: 18px;
	padding-inline-end: 38px;
	height: 40px;

	// Arrow down
	background-image: url('../../i/v3/components/illustrations/dropdown-down-s-16.svg');
	background-size: 16px 16px;
	background-repeat: no-repeat;
	background-position: (calc(100% - 12px) 50%);
	
	[dir='rtl'] & {
		background-position: 12px 50%;
	}


	//SIZES
	@include media-breakpoint-up(lg) {
		&.size-lg {
			font-size: $body-2-font-size;
			line-height: $body-2-line-height;
			background-position: (calc(100% - 16px) 50%);
			padding: 0;
			padding-inline-start: 28px;
			padding-inline-end: 52px;
			height: 56px;

			[dir='rtl'] & {
				background-position: 16px 50%;
			}
		}

		&.size-md {
			background-position: (calc(100% - 12px) 50%);
			padding: 0;
			padding-inline-start: 24px;
			padding-inline-end: 44px;
			height: 48px;


			[dir='rtl'] & {
				background-position: 12px 50%;
			}
		}

		&.size-sm {
			font-size: $body-4-font-size;
			line-height: $body-4-line-height;
			padding: 0;
			padding-inline-start: 20px;
			padding-inline-end: 36px;
			height: 40px;
		}
	}


	// COLORS
	background-color: $white;
	border: 1px solid $gray07;
	box-shadow: 0px 6px 12px rgba($blue-dark, .08);

	&.bold {
		border: none;

		&,
		&.orange,
		&.theme-orange {
			box-shadow: inset 0 0 0 4px $orange-pale; // DEFAULT COLOR
		}

		&.gray,
		&.theme-gray {
			box-shadow: inset 0 0 0 4px $gray07;
		}

		&.blue,
		&.theme-blue {
			box-shadow: inset 0 0 0 4px $blue-bright;
		}
	}


	&[disabled='disabled'] {
		border-color: $gray09;
		pointer-events: none;
		background-image: none;
		padding: 0 20px;

		&.bold {
			&,
			&.orange,
			&.theme-orange {
				box-shadow: inset 0 0 0 4px $orange-faint; // DEFAULT COLOR
			}
			
			&.gray,
			&.theme-gray {
				box-shadow: inset 0 0 0 4px rgba($gray07, .4);
			}

			&.blue,
			&.theme-blue {
				box-shadow: inset 0 0 0 4px rgba($blue-bright, .4);
			}
		}

		@include media-breakpoint-up(lg) {
			&.size-lg {
				padding: 0 28px;
			}
	
			&.size-md {
				padding: 0 24px;
			}
		}
	}

	&:hover {
		border-color: $gray05;

		&.bold {
			&,
			&.orange,
			&.theme-orange {
				box-shadow: inset 0 0 0 4px $orange-light; // DEFAULT COLOR
			}

			&.gray,
			&.theme-gray {
				box-shadow: inset 0 0 0 4px $gray06;
			}

			&.blue,
			&.theme-blue {
				box-shadow: inset 0 0 0 4px $blue-light;
			}
		}
	}

	&:active,
	&:focus,
	&:focus-visible {
		outline-style: none;
		border-color: $blue-bright;
		box-shadow:
			inset 0px 2px 4px rgba($blue-dark, .12),
			0 0 0 4px rgba($blue-bright, .24);

		&.bold {
			&,
			&.orange,
			&.theme-orange {
				border: 1px solid rgba($orange-bright, .24); // DEFAULT COLOR
				box-shadow: 0 0 0 3px rgba($orange-bright, .24), inset 0 0 0 2px $orange-bright; // DEFAULT COLOR
			}

			&.gray,
			&.theme-gray {
				border: 1px solid rgba($gray05, .24);
				box-shadow: 0 0 0 3px rgba($gray05, .24), inset 0 0 0 2px $gray05;
			}

			&.blue,
			&.theme-blue {
				border: 1px solid rgba($blue-pale, .24);
				box-shadow: 0 0 0 3px rgba($blue-bright, .24), inset 0 0 0 2px $blue-bright;
			}
		}
	}
}