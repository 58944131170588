.unsupported-browser-message {
	display: none;
	background-color: $red;
	color: $white;
	padding: $bu*1.5 $bu;
	text-align: center;
	
	@include media-breakpoint-up(lg) {
		padding: $bu*2 $bu*7.5;
	}
	
	a:not(.btn) {
		color: $white;
	}

	.notSupportedBrowser & {
		display: block;
	}
}