/**************************
// Sticky Bar
**************************/

.sticky-bar {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	transform: translateY(-200%);
	transition: all 150ms ease-out;
	background: $white;
	padding: 12px 20px 11px; // bottom padding is less by border
	box-shadow: 0px 24px 40px rgba($blue-dark, 0.08);
	border-bottom: 1px solid $gray07;

	@include media-breakpoint-up(lg) {
		padding: $bu $bu*2.5 15px;
		height: auto;
	}

	&.is-sticky {
		display: block;
		position: fixed;
		z-index: map-get($z-index, cmp-sticky-bar);
		transform: translateY(0);
	}

	&-content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: $bu;

		@include media-breakpoint-up(lg) {
			gap: $bu*1.5;
		}
	}

	&__right {
		display: flex;
		align-items: center;
		gap: $bu*.75;

		@include media-breakpoint-up(lg) {
			gap: $bu*2;
		}
	}

	.buttons {
		display: flex;
		align-items: center;
		gap: $bu*.75;

		@include media-breakpoint-up(lg) {
			gap: $bu;
		}
	}


	// Mobile elements
	@include media-breakpoint-down(xl2) {
		.award-badges-wrap {
			display: none;
		}
	}

	@include media-breakpoint-down(xl) {
		.header-text,
		.sticky-bar__right .h7 {
			display: none;
		}
	}

	@include media-breakpoint-down(lg) {
		// Product Icon size on mobile
		.product-icon {
			&.size-32 {
				&.box img,
				&.unbox img {
					width: $bu*1.5;
					height: $bu*1.5;
				}
			}
		}
	}

	@include media-breakpoint-down(md) {
		.product-name .h7 {
			display: none;
		}
	}

	@include media-breakpoint-down(sm) {
		// On mobile are buttons hidden unless have .btn-stay class
		.btn:not(.btn-stay):not(.js-pc):not(.js-mac):not(.js-android):not(.js-ios) {
			display: none;
		}
	}

	//AWARD SMALL LONG has specific length in sticky bar
	.award-badges-wrap.size-sm.long {
		.badge-text {
			max-width: 256px;
		}
	}
}
