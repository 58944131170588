.alert {
	text-align: start;
	width: 100%;
	padding: 12px $bu;
	border-radius: $bu;
	@extend .body-3;

	@include media-breakpoint-up(lg) {
		padding: $bu $bu*1.5;
	}

	&.alert-blue-bright {
		box-shadow: 0px 4px 16px 0px rgba(0, 112, 246, 0.24), 0px 12px 16px 0px rgba($blue-dark, 0.08);

		@extend .inverse;
		@extend .bg-blue-bright;
	}

	&.alert-orange-faint {
		box-shadow: 0px 4px 16px 0px rgba(255, 120, 0, 0.12), 0px 12px 12px 0px rgba($blue-dark, 0.08);

		border: 1px solid $orange-pale;
		@extend .bg-orange-faint;
	}
}