// remove white gap caused by tracking pixel in body at the bottom of pages
// the pixel get proper classes with very long delay
body > img {
	position: absolute;
}

// On smallest resolution the edge padding is 20px (not 16px)
.content-holder,
.footer-links,
.footer,
.region-selector,
.unsupported-browser-message,
.stripe-wrap {
	.container {
		@include media-breakpoint-down(sm) {
			padding-inline: $bu*1.25;
		}
	}
}

// Vertical padding and margin for sections
.section-py {
	padding-top: $bu*5; //80px
	padding-bottom: $bu*5;

	@include media-breakpoint-up(lg) {
		padding-top: $bu*7; //112px
		padding-bottom: $bu*7;
	}
}

.section-pt {
	padding-top: $bu*5; //80px

	@include media-breakpoint-up(lg) {
		padding-top: $bu*7; //112px
	}
}

.section-pb {
	padding-bottom: $bu*5; //80px

	@include media-breakpoint-up(lg) {
		padding-bottom: $bu*7; //112px
	}
}

.section-my {
	margin-top: $bu*5; //80px
	margin-bottom: $bu*5;

	@include media-breakpoint-up(lg) {
		margin-top: $bu*7; //112px
		margin-bottom: $bu*7;
	}
}

.section-mt {
	margin-top: $bu*5; //80px

	@include media-breakpoint-up(lg) {
		margin-top: $bu*7; //112px
	}
}

.section-mb {
	margin-bottom: $bu*5; //80px

	@include media-breakpoint-up(lg) {
		margin-bottom: $bu*7; //112px
	}
}

// Section header margin
h2.section-header {
	margin-bottom: $bu*.75;

	@include media-breakpoint-up(lg) {
		margin-bottom: $bu;
	}
}

// Global Focus style for tabbing
*:focus-visible {
	&:not(.form-control):not(.form-select) {
		outline-offset: 8px;
		outline-style: dotted;
		outline-width: 2px;
		outline-color: $gray04;
	}
}