/**************************
// Modal
**************************/

.modal {
	padding-inline: 0 !important;

	@include media-breakpoint-down(lg) {
		padding-inline: $bu*1.25 !important;
	}

	.modal-dialog {
		margin: $bu*1.25 auto;

		@include media-breakpoint-up(lg) {
			margin: $bu*2.5 auto;
		}
	}
	
	.btn-close {
		position: absolute;
		inset-inline-end: $bu;
		top: $bu;
		z-index: 3;

		@include media-breakpoint-up(lg) {
			inset-inline-end: $bu*1.5;
			top: $bu*1.5;
		}
	}

	.modal-content {
		box-shadow: none;
		border: none;
		border-radius: $bu*2;
		background-color: $white;
		padding: $bu*4 $bu*2 $bu*2;

		@include media-breakpoint-up(lg) {
			padding: $bu*4 $bu*3 $bu*3;
		}

		video,
		iframe {
			width: 100%;
		}

		.modal-oops {
			text-align: center;

			&-title,
			&-desc {
				margin-bottom: $bu;
			}

			&-btn-requested,
			&-btn {
				margin-bottom: $bu*1.5;
			}

			.btn {
				&.link {
					margin-left: auto;
					margin-right: auto;
					cursor: pointer;
				}
			}
		}
	}

	.modal-body {
		padding: 0;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;

		&.video {
			align-items: center;
			justify-content: center;
		}

		.modal-title,
		.h3 {
			margin-bottom: $bu*.75;

			@include media-breakpoint-up(lg) {
				margin-bottom: $bu;
			}
		}
	}

	&.modal--full {
		opacity: unset;

		.modal-dialog {
			max-width: 100%;
			margin: 0;
			height: 100%;
		}

		.modal-content {
			border-radius: 0;
			height: 100%;
		}

		.modal-body {
			align-items: center;
			justify-content: center;
			text-align: center;
		}
	}

	&.modal--large {
		.modal-dialog {
			@include media-breakpoint-up(sm) {
				max-width: 688px;
			}

			@include media-breakpoint-between(lg,xl) {
				max-width: 896px;
			}

			@include media-breakpoint-up(xl) {
				max-width: 1128px;
			}
		}

		.modal-content {
			@include media-breakpoint-up(lg) {
				border-radius: $bu*3;
				padding: $bu*5 $bu*7.25;
	
				.btn-close {
					inset-inline-end: $bu*2.5;
					top: $bu*2.5;
				}
			}
		}

		/* .modal-body {
			&.video {
				padding: $bu*1.5 $bu*2 $bu*2;

				@include media-breakpoint-up(lg) {
					padding-top: $bu*2;
				}
			}
		} */
	}

	&.modal--medium {
		.modal-dialog {
			@include media-breakpoint-up(xl) {
				max-width: 664px;
			}
		}

		/* .modal-body {
			&.video {
				padding: $bu*1.5 $bu*2 $bu*2;

				@include media-breakpoint-up(lg) {
					padding-top: $bu*2;
				}
			}
		} */
	}

	&.modal--small {
		.modal-dialog {
			@include media-breakpoint-up(xl) {
				max-width: 432px;
			}
		}
	}
}

.modal-backdrop {
	background-color: $blue-dark;

	&.show {
		opacity: .8;
	}
}


.overlay {
	display: none;
	position: fixed;
	z-index: 99;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;

	.overlay-open & {
		display: block;
		background-color: $blue-dark;
	}
}

// Style for non href links, in hero for example
.modal-oops-hint-link {
	text-decoration: underline;
	font-weight: 700;
	cursor: pointer;

	&:hover {
		text-decoration: none;
	}
}
