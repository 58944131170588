/**************************
// ACCORDION V3
**************************/

.accordion-holder {
	display: flex;
	flex-direction: column;
	text-align: start;
	margin: 0 auto;

	@include media-breakpoint-up(sm) {
		max-width: 482px;
	}

	@include media-breakpoint-up(md) {
		max-width: 632px;
	}

	@include media-breakpoint-up(lg) {
		max-width: 832px;
	}

	@include media-breakpoint-up(xl) {
		max-width: 848px;
	}

	@include media-breakpoint-up(xl2) {
		max-width: 912px;
	}

	@include media-breakpoint-up(xxl) {
		max-width: 960px;
	}


	.accordion-item {
		position: relative;
		display: flex;
		flex-direction: column;
		background-color: $white;
		border: 1px solid $gray07;
		padding-bottom: $bu*1.5;

		@include media-breakpoint-up(lg) {
			padding-bottom: $bu*2;
		}

		&:not(.advanced) {
			&:first-child {
				border-radius: $bu*1.5 $bu*1.5 0 0;

				@include media-breakpoint-up(lg) {
					border-radius: $bu*2 $bu*2 0 0;
				}
			}

			&:last-child {
				border-radius: 0 0 $bu*1.5 $bu*1.5;

				@include media-breakpoint-up(lg) {
					border-radius: 0 0 $bu*2 $bu*2;
				}
			}

			&:not(:first-of-type) {
				margin-top: -1px;
			}
		}

		&:not(.preface-show) {
			.accordion-body {
				padding-top: $bu*.5;
				padding-inline-end: $bu*4.75;

				@include media-breakpoint-up(lg) {
					padding-bottom: 0;
					padding-inline-end: $bu*5.5;
				}
			}
		}

		//Accordion header
		.accordion-header {
			padding: $bu*1.5 $bu*1.5 0;
			text-decoration: none;

			@include media-breakpoint-up(lg) {
				padding: $bu*2 $bu*2 0;
			}

			&:hover {
				.accordion-header-content {
					.accordion-title-icon {
						filter: $filter-blue-bright;
					}

					.h6 {
						color: $blue-bright-alt;
					}
				}

				.button-circle {
					&:before {
						background-color: $blue-bright;
					}

					.icon {
						filter: $filter-white;
					}
				}
			}

			//Accordion header-content
			.accordion-header-content {
				display: flex;
				align-items: flex-start;
				gap: $bu*.75;
				border-bottom-width: 0;

				@include media-breakpoint-up(lg) {
					gap: $bu;
					align-items: center;
				}

				.accordion-title-icon {
					filter: $filter-gray05;
				}

				.h6 {
					flex-grow: 3;
				}

				+ .list-group .list-group-item:first-child {
					border-top: 1px;
				}
			}

			&.preface-show {
				padding-bottom: 0;
			}

			.accordion-button {
				@include media-breakpoint-down(lg) {
					margin-inline-start: $bu/2;
				}

				.button-circle {
					transition: all ease-in .1s;

					.icon {
						transition: transform ease-in .2s;
					}

					&:hover {
						.icon {
							filter: $filter-white;
						}
					}
				}
			}

			//Collapsed styles
			&:not(.collapsed) {
				.h6 {
					color: $blue-bright;
				}

				.accordion-title-icon {
					filter: $filter-blue-bright;
				}

				.button-circle {
					&:before {
						background-color: $blue-bright;
					}

					.icon {
						transform: rotate(45deg) scale(1.1);
						filter: $filter-white;
					}
				}
			}
		}

		//Accordion preface
		.accordion-preface {
			color: $gray05;
			padding-inline: $bu*3.25 $bu*4.75;

			@include media-breakpoint-up(lg) {
				padding-inline: $bu*4.5 $bu*5.5;
			}
		}

		.accordion-collapse {
			width: 100%;
		}

		//Accordion body
		.accordion-body {
			word-break: break-word;
			padding: $bu $bu*1.5 0;

			@include media-breakpoint-up(lg) {
				padding: $bu*1.5 $bu*2 0;
			}
		}

		/***************
		Variant with illustration
		***************/
		&.illustration {
			align-items: flex-start;

			.illustration-wrapper {
				display: flex;
				padding: $bu*1.5 $bu*1.5 0;
				width: 100%;

				@include media-breakpoint-up(lg) {
					gap: $bu*1.5;
					padding: $bu*2 $bu*2 0;
				}

				.illustration-img {
					&.illustration-80 {
						width: 80px;
						height: 100%;
					}

					&.illustration-160 {
						width: 160px;
						height: 100%;
					}
				}

				.illustration-header {
					width: 100%;
					
					.accordion-header {
						display: block;
						padding: 0;
					}

					.accordion-preface {
						padding-inline: 0 $bu*3.25;
						//padding-top: 0;
					}
				}
			}
		}
	}

	/***************
	Variant Advanced
	***************/
	&.advanced {
		gap: $bu*1.5;

		@include media-breakpoint-up(lg) {
			gap: $bu*2.5;
		}

		.accordion-item {
			border-radius: $bu*1.5;

			@include media-breakpoint-up(lg) {
				border-radius: $bu*2;
			}

			&:hover {
				box-shadow: 0px 32px 56px rgba($blue-dark, .08);
			}
		}
	}

	//Link under Accordion
	.accordion-bottom-link {
		margin-top: $bu*2;
		text-align: center;

		@include media-breakpoint-up(lg) {
			margin-top: $bu*2.5;
		}
	}

	//FAQ section
	&.faq {
		h2 {
			margin: $bu*5 0 $bu*.75;
			@include media-breakpoint-up(lg) {
				margin: $bu*7 0 $bu;
			}
		}

		p {
			&.body-2 {
				margin-bottom: $bu*2.5;
				@include media-breakpoint-up(lg) {
					margin-bottom: $bu*3.5;
				}
			}
		}

		.accordion-item {
			.accordion-body {
				color: $gray05;
			}

			&:not(.icon-none) {
				.accordion-body {
					padding-inline-start: $bu*3.2;
					@include media-breakpoint-up(lg) {
						padding-inline-start: $bu*4.5;
					}
				}
			}
		}

		&.bottomLink {
			.accordion-item {
				&:nth-last-child(-n + 2) {
					border-radius: 0 0 $bu*1.5 $bu*1.5;

					@include media-breakpoint-up(lg) {
						border-radius: 0 0 $bu*2 $bu*2;
					}
				}
			}
		}

		.list-item {
			.list-item__body {
				.icon {
					filter: $filter-gray05;
				}
			}

			ol,
			ul {
				padding-inline-start: $bu;

				li::marker {
					color: $blue-dark;
				}
			}
		}
	}
}
