.hero {
	padding: $bu*2 0 $bu*4;
	overflow-x: hidden;

	@include media-breakpoint-up(sm) {
		padding:  $bu*3 0 $bu*4;
	}

	@include media-breakpoint-up(lg) {
		padding:  $bu*4 0 $bu*5;

		header.lp ~ & {
			padding-top: 0;
		}
	}

	&>.container>.row {
		position: relative;
		text-align: center;

		@include media-breakpoint-up(lg) {
			text-align: start;
		}
	}

	.hero-breadcrumbs {
		padding-bottom: $bu*2;

		@include media-breakpoint-up(lg) {
			padding-bottom: $bu*3;
		}
	}

	.hero-content {
		position: relative;

		.hero-tagline {
			padding-bottom: $bu;
		}

		.product-name {
			margin-bottom: $bu/2;

			@include media-breakpoint-down(lg) {
				justify-content: center;
			}
		}

		.hero-text {
			margin-top: 12px;

			@include media-breakpoint-up(lg) {
				margin-top: $bu;
			}
		}

		.list-item.list-item--large {
			margin-top: $bu;

			@include media-breakpoint-up(lg) {
				margin-top: $bu*1.5;
			}
		}

		.list-item.list-item--medium {
			margin-top: $bu*1.5;

			@include media-breakpoint-up(lg) {
				margin-top: $bu*2;
			}
		}

		.hero-note {
			display: flex;
			gap: $bu $bu*4;
			align-items: center;
			flex-direction: column;
			margin-top: $bu*1.5;

			@include media-breakpoint-up(lg) {
				flex-direction: row;
				margin-top: $bu*2;
			}
		}

		.hero-legal-note {
			margin-top: $bu*2;
			text-align: center;
			@extend .body-4;
		}

		.hero-price-box {
			margin-top: $bu*2.5;

			@include media-breakpoint-up(lg) {
				margin-top: $bu*3.5;
			}

			.pricebox {
				position: relative;
				z-index: 1;
			}
		}
	}

	// Awards wrapper
	.hero-awards-wrap {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		flex-direction: column;
		margin-top: $bu*3;
		gap: $bu*1.5 $bu*4;

		@include media-breakpoint-up(lg) {
			flex-direction: row;
			margin-top: $bu*2.5;
		}

		.award-badges-wrap.size-sm {
			// Awards badges in hero need to stay inline
			@include media-breakpoint-down(lg) {
				flex-direction: row;
				align-items: center;
				flex-wrap: nowrap;
				width: 100%;

				.award-badge {
					flex-direction: column;
					flex: 1;

					.badge-text {
						text-align: center;
						max-width: 154px;
					}
				}

				.trustpilot-widget {
					flex: 1;
					align-self: center;

					iframe {
						margin-inline: auto;
					}
				}
			}
			@include media-breakpoint-down(sm) {
				gap: $bu*0.75;
			}

			// Only four awards are visible on MD displays
			@include media-breakpoint-down(md) {
				div:nth-child(n+5):nth-child(-n+6) {
					display: none;
				}
			}

			// Only three awards are visible on SM displays
			@include media-breakpoint-down(sm) {
				div:nth-child(n+4):nth-child(-n+6) {
					display: none;
				}
			}
		}
	}


	// Awards wrapper
	&:not(.centered) .hero-awards-wrap {
		@include media-breakpoint-up(lg) {
			width: max-content; // awards should be on one line and overlap to the image part
		}
	}

	.pricing {
		width: 100%;

		@include media-breakpoint-down(lg) {
			text-align: center;
		}
	}

	&:not(.centered) .pricing {
		@include media-breakpoint-up(lg) {
			justify-content: initial;
		}
	}



	// CENTERED Hero variant
	&.centered {
		& > .container > .row {
			text-align: center;
			align-items: center;
			flex-direction: column;
		}

		.hero-content {
			.product-name,
			.hero-buttons,
			.hero-awards-wrap {
				justify-content: center;
				text-align: center;
			}
		}

		.pricing {
			justify-content: center;
			text-align: center;
		}

		.hero-awards-wrap {
			margin-top: $bu*2;

			@include media-breakpoint-up(lg) {
				margin-top: $bu*2.5;
			}
		}

		.hero-buttons {
			margin-top: $bu*2;

			@include media-breakpoint-up(lg) {
				margin-top: $bu*3;
			}
		}

		.hero-pricebox-wrap {
			padding-top: $bu*2.5;

			@include media-breakpoint-up(lg) {
				padding-top: $bu*3.5;
			}

			.ribbon {
				margin-bottom: $bu*3;

				@include media-breakpoint-up(lg) {
					margin-bottom: $bu*3.5;
				}
			}
		}


		.hero-note {
			justify-content: center;
		}

		[class*='hero-img-'] {
			position: absolute;
			bottom: 0;
			width: 172px;

			@include media-breakpoint-up(xl2) {
				width: 256px;
			}

			@include media-breakpoint-down(xl) { // images are visible only above 1280px breakpoint
				display: none;
			}

			img {
				max-width: 100%;
				max-height: 281px;

				@include media-breakpoint-only(xl) {
					height: auto;
				}

				@include media-breakpoint-up(xl2) {
					max-height: 420px;
				}
			}

			&.hero-img-right {
				text-align: end;
				right: calc(var(--bs-gutter-x) / 2);
			}

			&.hero-img-left {
				text-align: start;
				left: calc(var(--bs-gutter-x) / 2);
			}
		}
	}

	// Buttons wrapper
	.hero-buttons {
		display: flex;
		gap: $bu $bu*1.5;
		margin-top: $bu*2;
		flex-wrap: wrap;

		@include media-breakpoint-down(lg) {
			justify-content: center;
		}

		@include media-breakpoint-up(lg) {
			gap: $bu*2 $bu*1.5;
			margin-top: $bu*2.5;
		}

		.button-hint {
			margin-top: $bu;

			@include media-breakpoint-up(lg) {
				margin-top: $bu*1.5;
			}
		}
	}


	// Right Image behaviour
	.hero-image-wrapper {
		display: flex;
		height: 100%;
		justify-content: center;
		align-items: flex-start;

		@include media-breakpoint-down(lg) {
			padding-top: $bu*2;
		}
	}

	// Bitmaps
	&:not(.autoheight) .hero-image-wrapper {
		@include media-breakpoint-up(lg) {
			justify-content: flex-end;
		}

		.hero-image {
			max-width: 100%;

			&.hero-image-rounded {
				border-radius: $bu*2;

				@include media-breakpoint-up(lg) {
					border-radius: $bu*3;
				}
			}
		}
	}

	// Illustrations
	&.autoheight .hero-image-wrapper {
		@include media-breakpoint-up(lg) {
			justify-content: flex-start;
			align-items: stretch;

			.hero-image {
				max-height: 752px;
			}
		}

		@include media-breakpoint-down(lg) {
			.hero-image {
				max-width: 100%;
			}
		}
	}
}
