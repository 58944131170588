/**************************
// System requirements
**************************/

.system-requirements {
	display: flex;
	flex-direction: column;
	text-align: center;
	padding: $bu*3 $bu*1.5;
	border-radius: $bu*2;
	background-color: $gray10;

	@include media-breakpoint-up(lg) {
		padding: $bu*5 $bu*7.25;
		border-radius: $bu*3;
	}

	.system-req-header + .system-req-steps {
		margin-top: $bu*3;

		@include media-breakpoint-up(lg) {
			margin-top: $bu*5;
		}
	}

	.system-req-steps + .system-req-content {
		margin-top: $bu*3;

		@include media-breakpoint-up(lg) {
			margin-top: $bu*4;
		}
	}

	.system-req-header {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 12px;

		@include media-breakpoint-up(lg) {
			gap: $bu;
		}

		.btn {
			margin-top: 20px;

			@include media-breakpoint-up(lg) {
				margin-top: $bu*1.5;
			}
		}
	}

	.system-req-steps {
		.h3 {
			margin-bottom: $bu;
		}

		.body-3+.feature-items-steps,
		.h3+.feature-items-steps {
			margin-top: $bu*2;

			@include media-breakpoint-up(lg) {
				margin-top: $bu*3;
			}

			.feature-item {
				&:not(:last-child) {
					@include media-breakpoint-down(lg) {
						padding-bottom: $bu*2.5;
					}
				}
			}
		}
	}

	.system-req-content {
		display: flex;
		flex-direction: column;
		gap: $bu;

		.list-item {
			margin-top: $bu;

			.icon {
				filter: none !important;
			}
		}
	}

	// Platform compatibilites
	.system-req-platforms {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.system-req-platform {
			display: flex;
			align-items: center;
			gap: $bu/2;
		}
	}
}
