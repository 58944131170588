.button-circle {
	position: relative;
	z-index: 0;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	padding: 0;
	border-radius: 50%;
	transition: all .1s ease-in;

	// reset
	color: inherit;
	border-color: transparent;
	border-width: 0;
	background-color: transparent;

	// when there is different icon for hover status
	.btn-icon-normal { display: block; }
	.btn-icon-hover { display: none;}


	&:before {
		content: "";
		position: absolute;
		height: 100%;
		width: 100%;
		z-index: -1;
		transition: all ease-out .2s;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 50%;
		border-style: solid;
		border-width: 1px;

		// reset
		border-color: transparent;
	}
	
	&.disabled,
	&:disabled {
		cursor: initial;
	}

	&:hover:not(:disabled):not(.disabled) {
		// when there is different icon for hover status
		.btn-icon-normal { display: none; }
		.btn-icon-hover { display: block;}

		&:before {
			height: calc(100% + 4px);
			width: calc(100% + 4px);
		}
	}


	// SMALL SIZE
	&.size-sm {
		width: 32px;
		height: 32px;

		@include media-breakpoint-up(lg) {
			width: 40px;
			height: 40px;
		}
	}


	// MEDIUM SIZE
	&.size-md {
		width: 40px;
		height: 40px;

		@include media-breakpoint-up(lg) {
			width: 48px;
			height: 48px;

			&:hover:not(:disabled):not(.disabled) {
				&:before {
					height: calc(100% + 6px);
					width: calc(100% + 6px);
				}
			}
		}
	}
}
