/*********************
BUTTON component

Content:
variant: full, outline, link
size: lg, md, sm
theme: blue, gold, dark
************************/

// STYLES BY SIZE //

// DEFAULT SIZE (MIDDLE)
.btn {
	position: relative;
	font-weight: 750;
	display: inline-flex;
	align-items: center;
	z-index: 0;
	text-decoration: none;
	border-radius: 400px;
	transition: all ease-out .2s;
	cursor: pointer; // defined for buttons without link

	// reset
	color: inherit;
	background-color: transparent;

	&.with-label {
		position: relative;

		.label {
			position: absolute;
			z-index: 1;
			top: 0;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	&:not(.link):before {
		content: "";
		position: absolute;
		height: 100%;
		width: 100%;
		z-index: -1;
		transition: all ease-out .2s;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 400px;
		border-style: solid;

		// reset
		border-color: transparent;
	}

	&.disabled,
	&:disabled {
		cursor: initial;
		pointer-events: none;
	}

	&.btn-icon-left,
	&.btn-icon-right {
		span {
			text-align: start;
		}
	}
	
	&:not(.btn-icon-left):not(.btn-icon-right) {
		span {
			text-align: center;
		}
	}

	// SMALL SIZE
	&.size-sm {
		height: 36px;
		padding: 0 18px;
		gap: 8px;

		&:before {
			border-width: 1px;
		}

		&:hover:not(:disabled):not(.disabled) {
			&:before {
				height: calc(100% + 4px);
				width: calc(100% + 4px);
			}
		}

		@include media-breakpoint-up(lg) {
			height: 40px;
			padding: 0 20px;
			gap: 10px;
		}
	}

	// MEDIUM SIZE
	&.size-md {
		height: 40px;
		padding: 0 20px;
		gap: 10px;

		&:before {
			border-width: 2px;
		}

		&:hover:not(:disabled):not(.disabled) {
			&:before {
				height: calc(100% + 4px);
				width: calc(100% + 4px);
			}
		}

		@include media-breakpoint-up(lg) {
			height: 56px;
			padding: 0 28px;
			gap: 14px;

			&:hover:not(:disabled):not(.disabled) {
				&:before {
					height: calc(100% + 6px);
					width: calc(100% + 6px);
				}
			}
		}
	}

	// LARGE SIZE
	&.size-lg {
		height: 56px;
		padding: 0 28px;
		gap: 14px;

		&:before {
			border-width: 2px;
		}

		&:hover:not(:disabled):not(.disabled) {
			&:before {
				height: calc(100% + 6px);
				width: calc(100% + 6px);
			}
		}

		@include media-breakpoint-up(lg) {
			height: 72px;
			padding: 0 40px;
			gap: 20px;

			&:hover:not(:disabled):not(.disabled) {
				&:before {
					height: calc(100% + 8px);
					width: calc(100% + 8px);
				}
			}
		}
	}
}



// Button Link
.btn.link {
	display: flex;
	width: fit-content;
	text-decoration: underline;
	padding-left: 0;
	padding-right: 0;
	margin: -11px 0;
	gap: 6px;

	&.link span { // increased specificity to override button alignment
		text-align: start;
	}

	@include media-breakpoint-up(lg) {
		gap: 8px;
		margin: -12px 0;
	}

	.inverse & {
		color: $gray07;
		.btn-icon { filter: $filter-orange-bright; }
	}

	&,
	.light & {
		color: initial;
		.btn-icon { filter: $filter-blue-bright; }
	}

	&.disabled,
	&:disabled {
		opacity: $btn-disabled-opacity;
	}

	&:hover:not(:disabled):not(.disabled) {
		text-decoration: none;

		.inverse & {
			color: $white;
			.btn-icon { filter: $filter-orange-bright; }
		}

		&,
		.light & {
			color: $blue-bright-alt;
			.btn-icon { filter: $filter-blue-bright; }
		}
	}
}



// COLOR THEMES ASSIGNMENT (Applies also on .button-circle) //
@each $color, $value in $btn-themes {
	@include btn-theme($color);
}
