.icon {
	flex: 0 0 auto; //so the icon does not shrink in flex

	$sprite-sizes: map-get($sprite-icons, sizes); // inner map for sizes
	$sprite-rows: map-get($sprite-icons, rows); // inner map for names

	@each $size in $sprite-sizes {
		$mobileSize: if($size == 16, $size, $size - 8);

		&.icon-#{$size} {
			background-image: url("https://static3.avast.com/web/i/v3/components/icons/sprites/icons-#{$mobileSize}.svg?v1");
			width: #{$mobileSize}px;
			height: #{$mobileSize}px;

			@include media-breakpoint-up(lg) {
				background-image: url("https://static3.avast.com/web/i/v3/components/icons/sprites/icons-#{$size}.svg?v1");
				width: #{$size}px;
				height: #{$size}px;
			}

			@each $row, $row-data in $sprite-rows {
				@each $name in $row-data {
					// Icon Position in sprite:
					$position: index($row-data, $name)-1;
					$column: $row - 1;

					//add row and col gap to all items - except first row and each fist item in row
					$gap-position: if($position == 0, 0, 16);
					$gap-row: if($column == 0, 0, 16);

					// cordinates calculation
					$ico-x: ($position * $size) + ($position * $gap-position);
					$ico-y: ($column * $size) + ($column * $gap-row);
					$ico-x-m: ($position * ($mobileSize)) + ($position * $gap-position);
					$ico-y-m: ($column * ($mobileSize)) + ($column * $gap-row);

					&.icon-#{$name} {
						background-position: -#{$ico-x-m}px -#{$ico-y-m}px;

						@include media-breakpoint-up(lg) {
							background-position: -#{$ico-x}px -#{$ico-y}px;
						}
					}
				}
			}
		}
	}
}

// Specification for Avast One product logo
.avast-one-logo {
	height: 27px;
	width: 133px;

	@include media-breakpoint-up(lg) {
		height: 36px;
		width: 178px;
	}	
}