/* ❗ If you do some changes in this file, please inform @Martin Prokop about it. ❗ */

@import "header-desktop";
@import "header-mobile";


// Shared (mobile, desktop) ----------------------
.header {
	position: relative;
	z-index: 1000;
	height: $bu*4.5;

	@include media-breakpoint-up(lg) {
		height: $bu*6;
	}


	// hides the menu until JS is loaded
	// JS will add .navigation-for... class into a BODY, that changes it to display: block;
	// TODO: when JS is off, Logo is not visible
	.header-wrap {
		visibility: hidden;

		.navigation-for-desktop &,
		.navigation-for-mobile & {
			visibility: visible;
		}
	}


	// Header types --------------------------------
	&.web,
	&.lp-rs {
		box-shadow: inset 0 -1px $gray07;
	}

	// 🔷 theme variants
	&.default {
		background-color: $white;
	}

	&.inverse {
		background-color: $blue-dark;
	}

	&.transparent {
		background: transparent;
	} 

	&.lp {
		+ .content-holder > .hero,
		+ .content-holder > .js-platform-switch > .hero {
			padding-top: $bu*6.5;
			margin-top: -$bu*4.5;

			@include media-breakpoint-up(lg) {
				padding-top: $bu*8;
				margin-top: -$bu*6;
			}
		} 

		+ .content-holder > .hero.centered,
		+ .content-holder > .js-platform-switch > .hero.centered {
			@include media-breakpoint-up(lg) {
				padding-top: $bu*6;
				margin-top: -$bu*6;
			}
		}
	}
}



// Info for JS from where is the navigation being viewed
@include media-breakpoint-up(lg) {
	body:before {
		content: 'navigation-desktop' !important;
		display: none !important;
	}
}
@include media-breakpoint-down(sm) {
	body:before {
		content: 'navigation-mobile' !important;
		display: none !important;
	}
}

[class*="navigation-for-mobile"] {
	.header {
		.third-menu {
			display: none;
		}
	}
}

/* Logic */
// used for both versions
[class*="navigation-for"] {

	// basic logic that is same for desktop and mobile version

	// second menu items are flex
	&.first-menu-for-home .second-menu.for-home,
	&.first-menu-for-business .second-menu.for-business,
	&.first-menu-for-partners .second-menu.for-partners,
	&.first-menu-about-us .second-menu.about-us,
	&.first-menu-regions .second-menu.regions,
	&.blogs-special-menu .second-menu.blogs {
		display: flex;
	}

	// third menu items are block
	&.second-menu-security .third-menu.security,
	&.second-menu-privacy .third-menu.privacy,
	&.second-menu-about-pages .third-menu.about-pages,
	&.second-menu-privacy-pages .third-menu.privacy-pages,
	&.second-menu-performance .third-menu.performance,
	&.second-menu-family .third-menu.family,
	&.second-menu-bundles .third-menu.bundles,
	&.second-menu-products .third-menu.products,
	&.second-menu-press-center .third-menu.press-center,
	&.second-menu-investors .third-menu.investors,
	&.second-menu-diversity .third-menu.diversity,
	&.second-menu-business-partners .third-menu.business-partners,
	&.second-menu-resources .third-menu.resources,
	&.second-menu-login .third-menu.login,
	&.blogs-special-menu .third-menu.topics {
		display: block;
	}


	// Navigation styles - general
	.header {
		// only desktop and mobile are used
		.second-menu {
			display: none;
			
			ul:not(.mobile-links-top) {
				li {
					.subcategory.avast-one-item {
						.string-label-wrap {
							display: flex;
						}
					}
				}
			}
		}

		// Colors
		a:hover {
			.product-name,
			.subcategory {
				color: $blue-bright;
			}
		}

		// Reset
		ul {
			margin: 0;
			padding: 0;

			li {
				padding: 0;
				list-style: none;
			}
		}

		/* // user avatar icon
		// used in business products
		.block-header {
			.header-info {
				&:before {
					content: '';
					display: inline-block;
					position: relative;
					vertical-align: middle;
					top: -2px;
					margin-right: 8px;
					width: 16px;
					height: 16px;
					
					background: transparent url(../../i/v2/components/navigation/user-avatar.svg) center center no-repeat;
					//@include sprite-icon('account', 16);
				}
			}
		} */

		// displayed region
		.region-trigger .category {
			display: flex;
			align-items: center;
			gap: $bu/2;
			//height: $bu*1.5;

			&:before {
				position: relative;
				margin-top: -2px;
				margin-bottom: -2px;

				@include region-selector-flag;
			}
		}

		
		// label next to product names
		.third-menu {
			.product-name {
				.string-label-wrap {
					white-space: nowrap;

					.product-name-string {
						white-space: normal;
						padding-inline-end: 8px;
					}

					.label {
						display: inline-flex;
					}
				}
			}
		}


		// operation system logos
		// styles for latte template
		.os {
			display: flex;
			flex-direction: row;
			gap: $bu/2;

			&.empty {
				height: $bu;
			}
		}
	}

}


/* Platform switching */
// using only hiding, because content is displayed in modes (block, flex...)

// win
.js-pc .header {
	.content-mac,
	.content-android,
	.content-ios {
		display: none;
	}
}
// mac
.js-mac .header {
	.content-windows,
	.content-android,
	.content-ios {
		display: none;
	}
}
// android
.js-android .header {
	.content-windows,
	.content-mac,
	.content-ios {
		display: none;
	}
}
// ios
.js-ios .header {
	.content-windows,
	.content-mac,
	.content-android {
		display: none;
	}
}
