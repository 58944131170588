/* Testimonial */

// used with grid
.testimonial {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	position: relative;
	margin: 0 auto;
	border-radius: $bu*2.5;

	// Default borders. May be overridden via custom classes
	border: 1px solid $gray07;

	@include media-breakpoint-up(sm) {
		max-width: 418px;
	}

	@include media-breakpoint-up(md) {
		max-width: 448px;
	}

	@include media-breakpoint-up(lg) {
		border-radius: $bu*3;
		max-width: none;
	}

	// region flag
	.region.with-flag {
		width: $bu*2;
		height: $bu*2;
		flex: 0 0 auto;

		&:before {
			@include region-selector-flag;
			transform: matrix(1.333333, 0, 0, 1.333333, 6, 6);
		}

		@include media-breakpoint-up(lg) {
			width: $bu*2.5;
			height: $bu*2.5;

			&:before {
				transform: matrix(1.666666, 0, 0, 1.666666, 8, 8);
			}
		}
	}

	.quotation-icon {
		filter: $filter-orange-bright;
	}

	.testimonial-img-wrapper {
		margin: 0 auto;
	}

	.text-wrapper {
		flex-grow: 2;
		flex-direction: column;
		display: flex;
		gap: $bu/2;

		@include media-breakpoint-up(lg) {
			gap: 12px;
		}
	}

	.details {
		display: flex;
		gap: $bu;
	}

	.person {
		display: flex;
		flex-direction: column;

		.h6 {
			margin-bottom: 0;
		}

		.rating {
			margin-top: 4px;
		}
	}



	// TYPES
	&.type-lg {
		padding: $bu*3;
		background-color: $green-faint;
		gap: $bu*2;
		border: none;

		.h3 {
			margin-bottom: 0;
		}

		.quotation-icon {
			filter: none;
			position: absolute;
			left: 50%;
			top: 0;
			transform: translate(-50%, -50%);
		}

		@include media-breakpoint-up(sm) {
			max-width: none;
		}

		@include media-breakpoint-up(lg) {
			padding: $bu*5 $bu*5 $bu*4;
			border-radius: $bu*3;
			gap: $bu*3;

			.quotation-icon {
				left: -74px;
				top: 80px;
				transform: none;
			}
		}

		@include media-breakpoint-up(xl) {
			padding: $bu*6 $bu*7 $bu*5;
		}
	}

	&.type-sm {
		padding: $bu*2.5 $bu*2;
		border-radius: $bu*1.5;
		gap: $bu*1.5;

		@include media-breakpoint-up(lg) {
			border-radius: $bu*2;
			gap: $bu*2;
		}

		@include media-breakpoint-up(xl) {
			padding: $bu*3 $bu*2.5;
		}
	}

	&.type-profile {
		padding: $bu*2.5;
		gap: $bu;
		flex-direction: column;

		.testimonial-img-wrapper {
			height: 160px;
			width: 160px;

			.testimonial-img {
				border-radius: 50%;
				height: 100%;
				//width: 160px;
			}
		}

		.profile-body {
			display: flex;
			flex-direction: column;
			gap: $bu*1.5;
		}

		@include media-breakpoint-up(sm) {
			max-width: none;
		}

		@include media-breakpoint-up(lg) {
			padding: $bu*3;
			border-radius: $bu*2;
			gap: $bu*2;
			flex-direction: row;

			.profile-body {
				gap: $bu*2;
			}
		}
	}
	// /TYPES


	// itemprop data
	/* [itemprop="itemReviewed"] {
		display: none;
	} */
}
