/**************************
// Stripe
**************************/
.stripe {
	// DEFAULT - viewport width stripe
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	// When title is defined
	&.with-title {
		gap: $bu*1.5;
	}


	// BOX - container width, rounded corners
	&.box {
		border: 1px solid $orange-pale;
		border-radius: $bu*1.5;
		padding: $bu*2 $bu*1.25;

		@include media-breakpoint-up(lg) {
			padding: $bu*2 $bu*5;
		}
	}


	// Content based definitions:
	// USP - Feature Items content
	&.contents-usp {
		gap: $bu*1.5 $bu*2;
		padding: $bu*2 20px;

		@include media-breakpoint-down(sm) {
			width: 100%;
			margin: 0 auto;
		}

		@include media-breakpoint-between(sm, lg) {
			flex-direction: row;
			flex-wrap: wrap;
			padding: $bu $bu*2.5;
		}

		@include media-breakpoint-up(lg) {
			gap: $bu;
			flex-direction: row;
			padding: $bu*1.5 $bu*2;
		}

		@include media-breakpoint-up(xl) {
			gap: $bu*2;
			padding: $bu*2 $bu*5;
		}
	}


	// AWARDS - Award Badges content
	&.contents-awards {
		padding: $bu*3 20px;

		@include media-breakpoint-up(lg) {
			padding: $bu*2.5 $bu*5;
		}

		.award-badges-wrap {
			justify-content: center;

			@include media-breakpoint-up(lg) {
				flex-direction: row;
			}
		}
	}


	// METRICS - Metrics content
	&.contents-metrics {
		padding: $bu*3 20px;
	}
}
