.toggler {
	position: relative;
	border-radius: 150px;
	display: inline-flex;
	padding: 4px;

	// .toggler-check is a radio input
	.toggler-check {
		position: absolute;
		clip-path: circle(0);
		pointer-events: none;
	}

	// Toggle item
	.toggler-item {
		display: flex;
		gap: 6px;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		border-radius: 100px;
		border: none;
		cursor: pointer;
		padding: 5px 12px;
		white-space: nowrap;
		overflow: hidden;
		margin: 0;
		
		.inverse & {
			color: $white;
		}
	}

	// Make items width equal when toggler has width=100% but only on desktop
	&.w-100,
	&.full-width {
		@include media-breakpoint-up(lg) {
			.toggler-item {
				flex: 1 1 0px;
			}
		}

		@include media-breakpoint-down(lg) {
			width: auto !important;
		}
	}

	// Single item = Disabled
	&.disabled button.toggler-item,
	.toggler-check:disabled + label.toggler-item {
		pointer-events: none;
	}


	// Active or Selected item
	button.toggler-item.selected,
	button.toggler-item.active,
	.toggler-check:checked + label.toggler-item {
		.inverse & {
			background-color: $gray07;
			color: $blue-dark;
		}

		&,
		.light & {
			background-color: $white;
		}

		.icon {
			filter: $filter-orange-bright;
		}
	}

	// THEMES base on BG color class
	&.bg-orange-pale {
		button.toggler-item:not(.selected):not(.active),
		.toggler-check:not(:checked) + label.toggler-item {
			&:hover,
			&:focus {
				background-color: $orange-faint;
			}
		}
	}

	&.bg-gray07 {
		.inverse & {
			background-color: $gray04 !important;
		}

		button.toggler-item:not(.selected):not(.active),
		.toggler-check:not(:checked) + label.toggler-item {
			&:hover,
			&:focus {
				.inverse & {
					background-color: $gray05;
				}

				&,
				.light & {
					background-color: $gray09;
				}
			}
		}
	}

	&.bg-blue-bright {
		button.toggler-item:not(.selected):not(.active),
		.toggler-check:not(:checked) + label.toggler-item {
			color: $white;

			.icon {
				filter: $filter-white;
			}

			&:hover,
			&:focus {
				background-color: $blue-pale;
				color: $blue-dark;

				.icon {
					filter: $filter-blue-bright;
				}
			}
		}

		button.toggler-item.selected,
		button.toggler-item.active,
		.toggler-check:checked + label.toggler-item {
			.icon {
				filter: $filter-blue-bright;
			}
		}
	}


	// SIZES
	&.size-lg .toggler-item {
		@include media-breakpoint-up(lg) {
			font-size: $body-2-font-size;
			line-height: $body-2-line-height;
			padding: 9px $bu*2;
			gap: 12px;
		}
	}
	
	&.size-md .toggler-item {
		@include media-breakpoint-up(lg) {
			padding: 7px $bu*1.5;
			gap: 8px;
		}
	}
	
	&.size-sm .toggler-item {
		@include media-breakpoint-up(lg) {
			font-size: $body-4-font-size;
			line-height: $body-4-line-height;
			padding: 5px $bu;
		}
	}
}