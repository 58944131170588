/******************
// NOTICE COMPONENT
******************/

.signpost {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	border-radius: $bu*2.5;
	text-align: center;
	position: relative;
	width: 100%;
	margin: 0 auto;

	// Default borders. May be overridden via custom classes
	border: 1px solid $gray07;

	.label-top {
		position: absolute;
		top: 0;
		transform: translateY(-50%); 
		z-index: 1;
	}

	.label + .signpost-title {
		margin-top: 12px;
	}

	.signpost-title + .signpost-text {
		margin-top: 12px;
	}

	.signpost-body {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.signpost-footer {
		flex: 1;
		display: flex;
		align-items: flex-end;

		.btn {
			margin-top: $bu*2;
		}
	}

	@include media-breakpoint-up(sm) {
		max-width: 418px;
	}

	@include media-breakpoint-up(md) {
		max-width: 448px;
	}

	@include media-breakpoint-up(lg) {
		border-radius: $bu*3;
		max-width: none;
	
		.label + .signpost-title {
			margin-top: $bu;
		}
	
		.signpost-title + .signpost-text {
			margin-top: $bu;
		}
	}

	@include media-breakpoint-up(xl) {
		.signpost-footer {
			.btn {
				margin-top: $bu*2.5;
			}
		}
	}

	// Images and paddings
	&.type-circle {
		padding: $bu*2.5 $bu*2;

		@include media-breakpoint-up(xl) {
			padding: $bu*3 $bu*2.5;
		}

		.signpost-img-wrapper {
			padding-bottom: $bu*2;

			.signpost-img {
				margin: 0 auto;
				border-radius: 50%;
				max-height: 208px;
				max-width: 208px;
			}

			@include media-breakpoint-up(xl) {
				padding-bottom: $bu*2.5;

				.signpost-img {
					max-height: 272px;
					max-width: 272px;
				}
			}
		}
	}
	
	&.type-icon {
		padding: $bu*2.5 $bu*2;

		.signpost-img-wrapper {
			padding-bottom: 12px;

			.signpost-img {
				margin: 0 auto;
				max-height: 96px;
				max-width: 160px;
			}
		}

		@include media-breakpoint-up(lg) {
			.signpost-img-wrapper {
				padding-bottom: $bu;
			}
		}

		@include media-breakpoint-up(xl) {
			padding: $bu*3 $bu*2.5;
		}
	}

	&.type-full {
		.signpost-img-wrapper {
			padding: calc((100%/3)*2) 50% 0;
			width: 100%;
			position: relative;
			overflow: hidden;
			border-radius: $bu*2.5 $bu*2.5 0 0;

			@include media-breakpoint-up(lg) {
				border-radius: $bu*3 $bu*3 0 0;
			}
	
			.signpost-img {
				position: absolute;
				top: 50%;
				left: 0;
				width: 100%;
				transform: translateY(-50%);
			}
		}

		.signpost-body {
			padding: $bu*2 $bu*2 $bu*2.5;

			@include media-breakpoint-up(xl) {
				padding: $bu*2.5 $bu*2.5 $bu*3;
			}
		}
	}

	&.type-none {
		.signpost-body {
			padding: $bu*2.5 $bu*2;

			@include media-breakpoint-up(xl) {
				padding: $bu*3 $bu*2.5;
			}
		}
	}
}
