/* Form Text Inputs */

.form-control {
	background-color: $white;
	border: 1px solid $gray07;
	border-radius: 20px;
	box-shadow: inset 0px 2px 4px rgba($blue-dark, .12);
	text-align: start;
	padding: $bu/2 18px;
	transition: .1s;
	position: relative;

	@include media-breakpoint-up(lg) {
		padding: 10px 24px;
		border-radius: 24px;

		&.size-sm {
			font-size: $body-4-font-size;
			line-height: $body-4-line-height;
			padding: $bu/2 20px;
		}

		&.size-lg {
			font-size: $body-2-font-size;
			line-height: $body-2-line-height;
			padding: 12px 28px;
			border-radius: 28px;
		}
	}

	// TEXT AREA
	@at-root textarea#{&} {
		border-bottom-right-radius: $bu/2;

		@include media-breakpoint-up(lg) {
			border-bottom-right-radius: 10px;
	
			&.size-sm {
				border-bottom-right-radius: $bu/2;
			}
	
			&.size-lg {
				border-bottom-right-radius: 12px;
			}
		}
	}
	
	
	&:disabled,
	&[readonly] {
		pointer-events: none;
		border-color: $gray09;
		color: rgba($blue-dark, .4);

		&::placeholder {
			color: $gray05;
		}
	}
	
	&:hover {
		border-color: $gray05;
	}
	
	&:active,
	&:focus-visible {
		outline-style: none;
		border-color: $blue-bright;
		box-shadow:
			inset 0px 2px 4px rgba($blue-dark, .12),
			0 0 0 4px rgba($blue-bright, .24);
	}


	// Icons in the input element
	// Sprite should be visible only if input element is wrapped in .form-control-wrapper (see down in code)
	~ .icon {
		display: none;

		// Override mobile icon default size
		@include media-breakpoint-down(lg) {
			&[class*='icon-'] {
				background-image: url("https://static3.avast.com/web/i/v3/components/icons/sprites/icons-24.svg");
				width: 24px;
				height: 24px;

				$sprite-sizes: map-get($sprite-icons, sizes); // inner map for sizes
				$sprite-rows: map-get($sprite-icons, rows); // inner map for names

				@each $row, $row-data in $sprite-rows {
					@each $name in $row-data {
						// Icon Position in sprite:
						$position: index($row-data, $name)-1;
						$column: $row - 1;
	
						//add row and col gap to all items - except first row and each fist item in row
						$gap-position: if($position == 0, 0, 16);
						$gap-row: if($column == 0, 0, 16);
	
						// cordinates calculation
						$ico-x: ($position * 24) + ($position * $gap-position);
						$ico-y: ($column * 24) + ($column * $gap-row);
	
						&.icon-#{$name} {
							background-position: -#{$ico-x}px -#{$ico-y}px;
						}
					}
				}
			}
		}
	}

	// 2 - Icon as background image
	&.form-icon-before { padding-left: 42px; }
	&.form-icon-after { padding-right: 42px; }

	@include media-breakpoint-up(lg) {
		&.form-icon-before { padding-left: 48px; }
		&.form-icon-after { padding-right: 48px; }

		&.size-sm {
			&.form-icon-before { padding-left: 36px; }
			&.form-icon-after { padding-right: 36px; }
		}

		&.size-lg {
			&.form-icon-before { padding-left: 52px; }
			&.form-icon-after { padding-right: 52px; }
		}
	}
}


// Wrapper for input with icon component
.form-control-wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	//align-items: start;

	.form-control {
		& ~ .icon {
			display: block;
			position: absolute;
			top: $bu/2;
		}
		
		&.form-icon-before ~ .icon { left: $bu/2; }
		&.form-icon-after ~ .icon { right: $bu/2; }

		@include media-breakpoint-up(lg) {
			& ~ .icon {
				top: 12px;
			}
			
			&.form-icon-before ~ .icon { left: 12px; }
			&.form-icon-after ~ .icon { right: 12px; }

			&.size-lg {
				& ~ .icon {
					top: $bu;
				}
				
				&.form-icon-before ~ .icon { left: $bu; }
				&.form-icon-after ~ .icon { right: $bu; }
			}
		}
	}
}