//*************************
// Trustpilot widget
//*************************
.trustpilot-widget {
	&.size-sm {
		&,
		&.dynamic {
			iframe {
				width: 118px;
				height: 80px;
			}
		}

		&.long,
		&.vertical {
			iframe {
				width: 250px;
				height: 40px;
				margin-inline: auto;
			}
		}

		@include media-breakpoint-up(lg) {
			&.long {
				iframe {
					width: 100%;
					height: 22px;
				}
			}
			
			&.dynamic {
				iframe {
					width: 250px;
					height: 40px;
					margin-inline: auto;
				}
			}
		}
	}

	&.size-md,
	&.size-lg {
		iframe {
			width: 130px;
			height: 86px;
		}
	}

	// LG size is terminated (GLOWEB-7859)
	/* &.size-lg {
		iframe {
			width: 182px;
			height: 106px;
		}
	} */

	// LANGUAGES EXCEPTIONS ---------------------------------------
	&.size-sm {
		&:not(.long):not(.vertical) {
			iframe {
				.mod-fi-fi &,
				.mod-da-dk & {
					height: 92px;
				}
				
				.mod-pt-pt &,
				[class*="mod-ru-"] & {
					width: 120px;
				}

				.mod-sv-se &,
				[class*="mod-es-"] & {
					width: 130px;
				}

				.mod-it-it &,
				.mod-pt-br & {
					width: 135px;
				}

				.mod-ja-jp & {
					width: 160px;
				}


				[class*="mod-de-"] & {
					@include media-breakpoint-down(lg) {
						height: 92px;
					}

					@include media-breakpoint-up(lg) {
						width: 160px;
					}
				}
			}
		}

		&.long {
			iframe {
				.mod-fi-fi &,
				.mod-it-it & {
					@include media-breakpoint-only(lg) {
						height: 44px;
						width: 220px;
					}
				}
			}
		}
	}
}