/**************************
// Countdown
**************************/
.cmp-countdown {
	display: flex;
	align-items: flex-start;
	//color: $blue-dark;

	.time-block {
		width: 32px;

		@include media-breakpoint-up(md) {
			width: 40px;
		}

		.unit {
			font-size: $base-unit*.6875;
			line-height: $base-unit;

			@include media-breakpoint-up(md) {
				font-size: $base-unit*.75;
				line-height: $base-unit*1.125;
			}
		}
	}

	.colon {
		.divider {
			width: 4px;
			height: 18px;
			font-size: $base-unit*.875;
			line-height: $base-unit*1.125;
			font-weight: 750;

			@include media-breakpoint-up(md) {
				width: 5px;
				height: 24px;
				font-size: $base-unit*1.125;
				line-height: $base-unit*1.5;
			}
		}
	}

	.time-block,
	.colon {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
