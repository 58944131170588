.table-basic-wrap {
	overflow-x: auto;
}

.table-basic {
	width: 100%;
	background-color: $white;

	thead {
		tr {
			-webkit-sticky: sticky;
			position: sticky;
			top: 0;
			z-index: 2;
			background-color: $white;
			box-shadow: 0 1px $gray07;
		}

		th {
			@extend .h7;
			
			@include media-breakpoint-up(lg) {
				white-space:nowrap;

				&:first-child {
					width:100%;
				}
			}

			@include media-breakpoint-down(lg) {
				max-width: 160px;
			}
		}
	}

	tbody {
		tr:nth-child(odd) {
			background-color: $gray10;
		}

		td {
			border-bottom: 1px solid $gray07;
			@extend .body-4;
		}
	}
	
	thead th,
	tbody td {
		vertical-align: top;
		padding: $bu $bu/2;
	}


	&.centered {
		thead th,
		tbody td {
			text-align: center;
		}
	}
}
