.compare-box-grid {
	// Grid definition-----------------------------------
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 24px;

	.mbg-above4-boxes {
		display: none;
	}

	@include media-breakpoint-up(lg) {
		grid-template-columns: repeat(2, 1fr);
		display: grid; //switch to flex only on desktop resolutions
		gap: 0 $bu*2;
		align-items: stretch;
			
		// Pricebox overrides
		.pricebox .box-container[class*='size-'] {
			width: 100%;
			grid-template-columns: auto;
			-ms-grid-columns: auto; //IE
		}

		.compare-box:last-child {
			.compare-box-top,
			.compare-box-content {
				width: 100%;
				max-width: 448px;
				justify-self: center;
			}
		}
	}

	&.boxcount-2 {
		@include media-breakpoint-up(xl) {
			grid-template-columns: repeat(2, 432px);
		}

		@include media-breakpoint-up(lg) {
			grid-template-areas:
				"top1 top2"
				"content1 content2"
			;
		}
	}

	&.boxcount-3 {
		@include media-breakpoint-up(lg) {
			grid-template-rows: repeat(2, auto) $bu*2 repeat(2, auto);
			grid-template-areas:
				"top2 top3"
				"content2 content3"
				". ."
				"top1 top1"
				"content1 content1"
			;
		}

		@include media-breakpoint-up(xl) {
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: repeat(2, auto);
			grid-template-areas:
				"top1 top2 top3"
				"content1 content2 content3"
			;
		}
	}

	// Boxcount-4 should be only as SMALL variant <----



	// SMALL SIZE GRID --------------------
	&.size-sm {
		@include media-breakpoint-up(lg) {
			grid-template-columns: repeat(2, 25%);
		}
	
		&.boxcount-2 {
			@include media-breakpoint-up(xl) {
				grid-template-columns: repeat(2, 1fr);
			}
		}
	
		&.boxcount-3 {
			@include media-breakpoint-up(lg) {
				grid-template-columns: repeat(3, 1fr);
				grid-template-rows: repeat(2, auto);
				grid-template-areas:
					"top1 top2 top3"
					"content1 content2 content3"
				;
			}
		}
	
		&.boxcount-4 {
			.mbg-above4-boxes {
				display: flex;
			}
	
			.mbg-upto3-boxes {
				display: none;
			}
	
			@include media-breakpoint-up(lg) {
				grid-template-columns: repeat(3, 1fr);
				grid-template-rows: repeat(2, auto) $bu*2 repeat(2, auto);
				grid-template-areas:
					"top1 top2 top3"
					"content1 content2 content3"
					". . ."
					". top4 ."
					". content4 ."
				;
			}
	
			@include media-breakpoint-up(xl) {
				grid-template-columns: repeat(4, 1fr);
				grid-template-rows: repeat(2, auto);
				grid-template-areas:
					"top1 top2 top3 top4"
					"content1 content2 content3 content4"
				;
			}
		}
	}


	@for $a from 1 through 4 {
		.compare-box:nth-child(#{$a}):not([class*="d-boxorder-"]) {
			.compare-box-top {
				$topArea: top+$a;
				grid-area: $topArea;
			}

			.compare-box-content {
				$contentArea: content+$a;
				grid-area: $contentArea;
			}
		}

		.compare-box.d-boxorder-#{$a} {
			.compare-box-top {
				$topArea: top+$a;
				grid-area: $topArea;
			}

			.compare-box-content {
				$contentArea: content+$a;
				grid-area: $contentArea;
			}
		}
	}
	// Grid definition-----------------------------------


	// Multi box (toggler options) // Deprecated solution - DELETE this wrapper from the DOM, please
	.compare-multibox-wrap {
		display: contents;
	}

	// Hide options
	.compare-box [class*='js-option'].hide.hide { // doubled class selector to higher specificity
		display: none;
	}



	// TOGGLER
	.toggler,
	.form-select {
		position: absolute;
		width: 100%;
		top: -54px;
		z-index: 1;

		.toggler-item {
			flex: 1 1 0px;
		}

		@include media-breakpoint-up(lg) {
			width: calc(100% - #{$bu}*5);

			&.size-md {
				top: -24px;
			}

			&.size-sm {
				top: -22px;
			}
		}
	}

	// CROWN
	.promoted {
		.compare-box-top:before {
			content: "";
			display: block;
			width: 80px;
			height: 64px;
			background-image: url('../../i/v3/components/illustrations/crown.svg');
			background-size: 80px 64px;
			background-repeat: no-repeat;
			position: absolute;
			z-index: 2;
			left: 50%;
			transform: translateX(-50%);
		}

		// CROWN WITHOUT TOGGLER
		margin-top: $bu*4;

		.compare-box-top:before {
			top: -98px;
		}

		@include media-breakpoint-up(lg) {
			.compare-box-top {
				margin-top: $bu*4;

				&:before {
					top: -66px;
				}
			}
		}

		// CROWN WITH TOGGLER
		&.with-toggler {
			margin-top: $bu*5.25;

			.compare-box-top:before {
				top: -118px;
			}

			@include media-breakpoint-up(lg) {
				.compare-box-top {
					margin-top: $bu*5.5;

					&:before {
						top: -88px;
					}
				}
			}
		}
	}

	// TOP MARGIN WITH TOGGLER, NO PROMOTION
	.with-toggler {
		margin-top: $bu*1.25;
	
		@include media-breakpoint-up(lg) {
			.compare-box-top {
				margin-top: $bu*1.5;
			}
		}
	}


	// Compare Box
	.compare-box {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 12px;
		width: fit-content;
		border-radius: $bu*2;
		border: 1px solid $gray07;
		padding: $bu*2;
		max-width: 384px;

		@include media-breakpoint-up(lg) {
			padding: $bu*2.5;
		}

		.compare-box-top,
		.compare-box-content {
			display: flex;
			flex-direction: column;
		}

		.compare-box-top {
			position: relative;
			text-align: center;
			align-content: center;
			
			.compare-box-headline.h4 {
				@include media-breakpoint-down(lg) {
					font-size: $h3-font-size-m;
					line-height: $h3-line-height-m;
				}
			}

			.subtitle {
				font-weight: 700;
				padding-bottom: $bu/2;
			}

			.pricebox {
				width: 100%;
				padding-top: 12px;

				@include media-breakpoint-up(lg) {
					padding-top: $bu;
				}

				.box-container {
					@include media-breakpoint-down(lg) {
						padding-bottom: 0;
					}
				}
			}
		}

		.compare-box-content {
			display: flex;
			flex-direction: column;
			flex-grow: 2;
			gap: 12px;

			@include media-breakpoint-up(lg) {
				gap: $bu;
			}

			.list-set {
				display: flex;
				flex-direction: column;
				gap: 12px;

				@include media-breakpoint-up(lg) {
					gap: $bu;
				}

				@include media-breakpoint-down(lg) {
					padding-top: 12px;
				}

				.h7,
				.fw-bold {
					@include media-breakpoint-up(lg) {
						padding-top: $bu;
					}
				}
			}

			.compare-box-btn {
				padding-top: $bu/2;
			}

			.money-back {
				margin-top: $bu/2;
			}
		}


		// Desktop grid styles
		@include media-breakpoint-up(lg) {
			display: contents;

			.compare-box-top,
			.compare-box-content {
				border-style: solid;
				border-width: 1px;
				padding-inline: $bu*2.5;
			}

			.compare-box-top {
				border-bottom: none;
				border-radius: $bu*2 $bu*2 0 0;
				padding-top: $bu*2.5;
				align-self: end;
			}

			.compare-box-content {
				border-top: none;
				border-radius: 0 0 $bu*2 $bu*2;
				padding-bottom: $bu*2.5;
			}

			.compare-box-top + .compare-box-content {
				padding-top: $bu;
			}

			&.promoted:not(.theme-white) {
				.compare-box-top,
				.compare-box-content {
					border-width: 2px;
				}
			}

			// Colors
			&.theme-white {
				.compare-box-top,
				.compare-box-content {
					background-color: $white;
					border-color: $gray07;
				}
			}

			&.theme-orange {
				.compare-box-top,
				.compare-box-content {
					background-color: $orange-faint;
					border-color: $orange-pale;
				}

				&.promoted {
					.compare-box-top,
					.compare-box-content {
						border-color: $orange-bright;
					}
				}
			}

			&.theme-gray {
				.compare-box-top,
				.compare-box-content {
					background-color: $gray10;
					border-color: $gray07;
				}

				&.promoted {
					.compare-box-top,
					.compare-box-content {
						border-color: $gray06;
					}
				}
			}
		}

		// Color Themes for mobile
		&.theme-white {
			background-color: $white;
		}

		&.theme-gray {
			background-color: $gray10;

			&.promoted {
				border: 2px solid $gray06;
			}
		}

		&.theme-orange {
			background-color: $orange-faint;
			border-color: $orange-pale;

			&.promoted {
				border: 2px solid $orange-bright;
			}
		}


		// Pricebox overrides
		.pricebox {
			.box-container[class*='size-'] {
				position: static;

				@include media-breakpoint-up(lg) {
					&[class*='size-lg'] {
						.box-cta-note {
							margin-top: 16px;
						}
					}
				}

				&[class*="promo-"] [abox],
				[abox] {
					border-width: 0;
					background-color: transparent;
				}

				[abox] {
					padding-inline: 0;
				}

				.box-cta-note {
					text-align: start;
					padding-top: 0;
					margin-top: 12px;
				}

				.box-top,
				.box-bottom {
					display: none;
				}

				// column widths in compare box are different than standalone box
				@include media-breakpoint-down(lg) {
					grid-template-columns: minmax(auto, 320px);
					-ms-grid-columns: 318px; //IE
				}
			}

			.box-container.size-lg {
				.box-cta-note {
					@include media-breakpoint-up(lg) {
						// twice the line-height so buttons are properly aligned
						min-height: 44px;
					}
				}
			}

			.box-container.size-sm {
				.box-cta-note {
					@include media-breakpoint-up(lg) {
						// twice the line-height so buttons are properly aligned
						min-height: 40px;
					}

					@include media-breakpoint-only(xl) {
						// treetimes the line-height so buttons are properly aligned in DE and FR langs
						[class*='mod-fr-'] &,
						[class*='mod-de-'] & {
							min-height: 60px;
						}
					}
				}
			}
		}

		
		// SMALL BOXES VARIANT ---------------------------------
		&.size-sm {
			@include media-breakpoint-up(lg) {
				.compare-box-top {
					padding: $bu*2 $bu*2 0;
				}
		
				.compare-box-content {
					padding: 0 $bu*2 $bu*2;
				}

				&.with-toggler .compare-box-top {
					margin-top: $bu*1.25;
				}
			}

			&.promoted {
				// CROWN WITHOUT TOGGLER
				margin-top: $bu*4;

				@include media-breakpoint-up(lg) {
					.compare-box-top {
						margin-top: $bu*4;

						&:before {
							top: -65px;
						}
					}
				}


				// CROWN WITH TOGGLER
				&.with-toggler {
					margin-top: $bu*5.25;

					@include media-breakpoint-up(lg) {
						.compare-box-top {
							margin-top: $bu*5.25;

							&:before {
								top: -86px;
							}
						}
					}
				}
			}

			@include media-breakpoint-up(lg) {
				.toggler,
				.form-select {
					width: calc(100% - #{$bu}*4);
				}
			}
		}
	}
}