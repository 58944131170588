.form-check {
	display: flex;
	align-items: center;
	gap: 12px;

	.form-check-input {
		float: inline-start;
	}
}

.form-check-input {
	appearance: none;
	background-color: $white;
	background-repeat: no-repeat;
	background-position: center;
	width: $bu*1.5;
	height: $bu*1.5;
	border: none; //1px solid $gray07;
	box-shadow: inset 0 2px 4px rgba($blue-dark, 0.12), inset 0 0 0 1px $gray07;
	flex-shrink: 0;

	&[type="checkbox"] {
		border-radius: 7px;
	}

	&[type="radio"] {
		border-radius: 12px;
	}

	&:hover {
		box-shadow: inset 0 2px 4px rgba($blue-dark, 0.12), inset 0 0 0 1px $blue-bright;
		background-color: $blue-faint;
	}

	&:checked {
		box-shadow: inset 0 2px 4px rgba($blue-dark, 0.12), inset 0 0 0 1px $blue-bright;

		&[type="checkbox"] {
			background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'><path d='M2 8L5.55498 11.4473C5.87048 11.7532 6.37371 11.7473 6.68202 11.4341L14 4' stroke='#{$blue-dark}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>"));
		}

		&[type="radio"] {
			background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'><circle cx='8' cy='8' r='3' fill='#{$blue-dark}'/></svg>"));
		}
	}

	&:disabled {
		pointer-events: none;
		filter: none;
		opacity: $form-check-input-disabled-opacity;
	}

	// Use disabled attribute in addition of :disabled pseudo-class
	// See: https://github.com/twbs/bootstrap/issues/28247
	&[disabled],
	&:disabled {
		~ .form-check-label {
			cursor: default;
			opacity: $form-check-label-disabled-opacity;
		}
	}

	& + .form-check-label {
		flex-grow: 1;
	}
}


//
// Switch
//

.form-switch {
	.form-check-input {
		width: 50px;
		height: 18px;
		border: none;
		box-shadow: none;
		background-color: $gray07;
		background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$gray06}'/></svg>"));
		background-position: left center;
		border-radius: 9px;
    @include transition($form-switch-transition);

		&:checked {
			background-color: $blue-light;
			background-position: $form-switch-checked-bg-position;
			background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$blue-bright}'/></svg>"));
		}
	}
}