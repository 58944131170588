/*
Carousel Component
*/
.carousel {
	overflow-x: hidden;
	position: relative;

	.container {
		position: relative;
	}

	.tns-item {
		height: auto;

		&:not(.tns-slide-active) {
			pointer-events: none;
		}
	}

	// masking style for all variants
	&:before {
		content: '';
		position: absolute;
		z-index: 1;
		height: 100%;
		width: 100%;
		top: 0;
		pointer-events: none;

		@include media-breakpoint-up(sm) {
			background-color: $white;
		}
	}


	// Carousel width based on content
	//--------------------------------
	&.duo {
		.tiny-slider {
			max-width: 256px;
		}

		$duoSliderWidths: (
			sm: 256px,
			md: 536px,
			lg: 432px,
			xl: 896px,
			xl2: 896px,
			xxl: 896px,
		);

		@include sliderMask($duoSliderWidths);
	}

	&.trio {
		.tiny-slider {
			max-width: 256px;
		}

		$trioSliderWidths: (
			sm: 256px,
			md: 536px,
			lg: 664px,
			xl: 1012px,
			xl2: 1012px,
			xxl: 1012px,
		);

		@include sliderMask($trioSliderWidths);
	}

	&.articles {
		.tiny-slider {
			max-width: 264px;
		}

		$articlesSliderWidths: (
			sm: 508px,
			md: 508px,
			lg: 928px,
			xl: 928px,
			xl2: 1264px,
			xxl: 1264px,
		);

		@include sliderMask($articlesSliderWidths, $align: true);
	}

	&.single-l {
		.tiny-slider {
			max-width: 320px;
		}

		$singleLSliderWidths: (
			sm: 418px,
			md: 448px,
			lg: 608px,
			xl: 784px,
			xl2: 848px,
			xxl: 896px,
		);

		@include sliderMask($singleLSliderWidths);
	}
	//--------------------------------
	// /Carousel width based on content



	// Controls (arrow buttons)
	//------------------------
	.slider-controls {
		@include media-breakpoint-down(lg) {
			height: $bu*4;	// reserved space for controls and slide counter
		}

		button {
			position: absolute;
			z-index: 10;

			@include media-breakpoint-down(lg) {
				width: $bu*2;
				height: $bu*2;
			}

			&.next {
				inset-inline-end: $bu*2.5;

				@include media-breakpoint-down(lg) {
					// 46px = (22px width of the slide counter) + (24px margin)
					inset-inline-end: calc(50% - 46px);
					transform: translateX(50%);
				}
			}

			&.prev {
				inset-inline-start: $bu*2.5;

				@include media-breakpoint-down(lg) {
					// 46px = (22px width of the slide counter) + (24px margin)
					inset-inline-start: calc(50% - 46px);
					transform: translateX(-50%);
				}
			}

			&.next,
			&.prev {
				@include media-breakpoint-down(lg) {
					bottom: 2px;
				}
				@include media-breakpoint-up(lg) {
					top: calc(50% - 60px);
				}
			}

			&.button-circle {
				&.size-md {
					@include media-breakpoint-down(lg) {
						box-shadow: none; // disable shadow, it was cropped by container

						&:hover {
							box-shadow: none;
						}
					}
				}
			}
		}

		@include media-breakpoint-up(lg) {
			button { display: flex; }
		}
	}

	&.joint {
		.tiny-slider {
			margin: 0;
		}

		.slider-controls button {
			&.prev {
				@include media-breakpoint-up(lg) {
					inset-inline-start: auto;
					inset-inline-end: $bu;
					top: 124px;
				}
			}

			&.next {
				@include media-breakpoint-up(lg) {
					inset-inline-end: $bu;
					top: 60px;
				}
			}

			@include media-breakpoint-up(xl) {
				&.next,
				&.prev {
					inset-inline-end: 0;
				}
			}

			@include media-breakpoint-up(xxl) {
				&.prev {
					inset-inline-end: $bu*4.5;
				}

				&.next {
					inset-inline-end: 0;
				}

				&.next,
				&.prev {
					top: $bu*6.5;
				}
			}
		}
	}

	&.packed {
		.packed-control {
			position: absolute;
			display: flex;
			align-items: center;
			flex-direction: column;
			bottom: 80px;
			inset-inline-start: 0;
			inset-inline-end: 0;
			margin: 0 auto;
			width: fit-content;

			.tns-nav {
				padding: 12px 0;

				@include media-breakpoint-up(lg) {
					padding: 14px 0;
				}
			}

			.slider-controls {
				display: flex;
				justify-content: center;
				position: absolute;

				button.prev,
				button.next {
					position: relative;
					display: inline-flex;
				}

				button.prev {
					@include media-breakpoint-up(lg) {
						inset-inline-start: -64px;
					}
				}

				button.next {
					@include media-breakpoint-up(lg) {
						inset-inline-end: -64px;
					}
				}
			}
		}

		.tiny-slider {
			.tns-liveregion {
				bottom: $bu*3.45;
			}
		}
	}



	// Content exceptions
	//-------------------
	&.articles {
		.article {
			background-color: transparent;
			border-color: transparent;

			&:hover {
				background-color: $white;
				border-color: $gray07;
				box-shadow: none;
			}

			@include media-breakpoint-up(lg) {
				max-width: 316px;
			}
		}

		.article-items {
			display: flex; // to have articles in line if JS failed to load

			.article-img {
				width: auto;
				height: 100%;
			}
		}

		// tns-nav got to be taken out from the flow so the it's alignment can be centered (see calculation in mixin above)
		.tns-outer {
			position: relative;

			@include media-breakpoint-up(lg) {
				padding-bottom: 60px;
			}

			.tns-nav {
				position: absolute;
				width: calc(100% + (100vw - 304px)); // viewport - slider width - container and content paddings
			}

			.tns-liveregion {
				position: absolute;
				width: calc(100% + (100vw - 320px));
			}
		}
	}

	&.single-l {
		.tns-item {
			.testimonial.type-lg {
				@include media-breakpoint-down(lg) {
					margin-top: 21px;
				}
			}

			&:not(.tns-slide-active) {
				.testimonial.type-lg {
					.quotation-icon {
						display: none !important;
					}
				}
			}
		}

		.tns-nav {
			@include media-breakpoint-down(lg) {
				margin-top: 21px;
			}
		}

		// Testimonial - visible only with carousel type 'single-l' and custom class '.show-dog'
		.testimony-dog {
			display: none;
			position: absolute;
			z-index: 2;
			inset-inline-end: 76.7647%;
			bottom: 44px;
		}

		&.show-dog {
			.testimony-dog {
				@include media-breakpoint-up(lg) {
					display: block;
				}

				@include media-breakpoint-up(xl) {
					width: 264px;
					height: 212px;
					bottom: 38px;
				}
			}

			.slider-controls {
				@include media-breakpoint-down(lg) {
					margin-top: 21px;
				}
			}

			.tns-liveregion {
				bottom: -$bu*4.85;
			}
		}

		// Images as slides
		.image-wrapper {
			justify-content: center;

			.single-image-slide {
				max-width: 100%;
				height: fit-content;
			}
		}
	}

	&.sections {
		overflow: hidden;

		&:before {
			display: none;
		}

		& > .container {
			display: contents;
		}

		.tns-item {
			&.media {
				& > .container {
					padding-bottom: 112px;

					@include media-breakpoint-up(lg) {
						padding-bottom: 120px;
					}
				}

				@include media-breakpoint-down(lg) {
					.media-text {
						padding-bottom: $bu*1.5;
					}
				}
			}
		}
	}
	//-----------------------
	// /Content exceptions


	// Dots / Navigation
	//------------------------
	.tns-nav {
		display: none;

		@include media-breakpoint-up(lg) {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: $bu*3 $bu/2 0;
			gap: $bu*1.5;
		}

		/* @include media-breakpoint-down(lg) {
			margin-top: 21px;
		} */

		button {
			flex-shrink: 0;	// prevents the dot to be deformed
			height: 6px;
			width: 6px;
			border-radius: 50%;
			background-color: $blue-light;
			padding: 0;
			border: 0;

			&.tns-nav-active {
				height: 9px;
				width: 9px;
				background-color: $blue-bright;
			}

			.inverse & {
				background-color: $gray03;

				&.tns-nav-active {  background-color: $orange-bright; }
			}

			@include media-breakpoint-up(lg) {
				height: 8px;
				width: 8px;

				&.tns-nav-active {
					height: 12px;
					width: 12px;
				}
			}
		}
	}



	// Carousel tiny slider styling
	//-----------------------------
	.tiny-slider {
		display: flex;
		position: relative;
		margin: 0 auto;

		.tns-outer {
			width: 100%;
		}

		.tns-inner {
			position: relative;
		}

		// Slider counter
		.tns-liveregion {
			width: 100%;
			position: absolute;
			bottom: -$bu*3.55;
			font-size: $bu*.85;
			text-align: center;
			user-select: none;

			@include media-breakpoint-up(lg) {
				display: none;
			}
		}

		.tns-slider {
			display: flex; // allows to have same height on all items

			.tns-item {
				transition: .4s all ease;
				display: inline-flex;
				flex: 1;
			}

			&:not(.tns-gallery) .tns-item {
				&:not(.tns-slide-active) > * {
					// all direct children have opacity (not for gallery)
					opacity: .4;
				}
			}

			&.tns-gallery {
				position: relative;
				z-index: 0;
				inset-inline-start: 0;
				min-height: 1px;

				&>.tns-item {
					position: absolute;
					transition: opacity 1.1s !important;
				}

				&>.tns-slide-active {
					position: relative;
				}

				&>.tns-moving {
					-webkit-transition: all 0.25s;
					-moz-transition: all 0.25s;
					transition: all 0.25s;
				}

				// Animation
				.tns-fadeIn {
					opacity: 1;
					z-index: 0;
					transition-timing-function: ease-out !important;
				}

				.tns-fadeOut,
				.tns-normal {
					opacity: 0;
					z-index: -1;
					transition-timing-function: ease-in !important;
				}
			}
		}
	}

	&.overflow-visible {
		.tns-ovh { overflow: visible; }
	}

	// Gradients for other than white backgrounds (based on maps in variables)
	// -----------------------------------------------------------------------
	@each $color, $value in $theme-colors {
		&.bg-#{$color} {
			&:before {
				@include media-breakpoint-up(sm) {
					background-color: $value;
				}
			}
		}
	}
}
