/*******
Product Icons
*******/
.product-icon {
	display: inline-flex;
	align-items: center;
	justify-content: center;

	&.box {
		background-color: $orange-bright;
		border-radius: 22%;

		&.light {
			background-color: $white;
			border: 1px solid $gray07;
			box-shadow: 0 15px 30px -15px rgba($blue-dark, .3) !important;
		}

		&.smb:not(.light) {
			background-color: $blue-bright;
		}
	}

	&.unbox img {
		transform: scale(156.25%);
	}

	&.size-80 {
		img {
			width: $bu*5;
			height: $bu*5;
		}

		&.box img,
		&.unbox img { 
			margin: 9px;
		}
	}

	&.size-48 {
		img {
			width: $bu*3;
			height: $bu*3;
		}

		&.box img,
		&.unbox img {
			margin: 6px;
		}
	}

	&.size-40 {
		img {
			width: $bu*2.5;
			height: $bu*2.5;
		}

		&.box img,
		&.unbox img {
			margin: 5px;
		}
	}

	&.size-32 {
		img {
			width: $bu*2;
			height: $bu*2;
		}

		&.box img,
		&.unbox img {
			margin: 4px;
		}
	}

	&.size-24 {
		img {
			width: $bu*1.5;
			height: $bu*1.5;
		}

		&.box img,
		&.unbox img {
			margin: 3px;
		}
	}
}

.product-name {
	display: flex;
	gap: 12px;
	align-items: center;
	
	@include media-breakpoint-up(lg) {
		gap: $bu;
	}
	
	.product-icon {
		@include media-breakpoint-down(lg) {
			img {
				width: $bu*1.5;
				height: $bu*1.5;
			}
	
			&.box img,
			&.unbox img {
				margin: 3px;
			}
		}
	}

	.header & {
		.product-icon {
			@include media-breakpoint-down(lg) {
				&.size-32 {
					&.box img {
						width: $bu*2;
						height: $bu*2;
						margin: 4px;
					}
			
					&.unbox img {
						width: $bu*2;
						height: $bu*2;
						margin: 4px;
					}
				}
			}
		}
	}
}