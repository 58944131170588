#cross-sell-modal {
    .modal-body {
        align-items: center;
        text-align: center;

        h4, h6, .product-icon, a.link {
            margin-bottom: 24px;
        }

        hr {
            width: 100%;
            margin-top: 0;
            margin-bottom: 40px;
        }

    }
}
