@mixin flag-position ($region, $y: 0, $x: 0) {
	&.#{$region}.with-flag:before { background-position: #{$x}px #{$y}px; }
}
.region {
	@include flag-position('en-eu');
	@include flag-position('es-ww',-24);
	@include flag-position('en-ww',-48);
	@include flag-position('ar-sa',-72);
	@include flag-position('ar-ww',-96);
	@include flag-position('cs-cz',-120);
	@include flag-position('cs-sk',-144);
	@include flag-position('da-dk',-168);
	@include flag-position('de-ch',-192);
	@include flag-position('fr-ch',-192);
	@include flag-position('de-de',-216);
	@include flag-position('el-gr',-240);
	@include flag-position('en-ae',-264);
	@include flag-position('ar-ae',-264);
	@include flag-position('en-au',-288);
	@include flag-position('en-ca',-312);
	@include flag-position('fr-ca',-312);
	@include flag-position('en-nz',-336);
	@include flag-position('en-gb',-360);
	@include flag-position('en-us',-384);
	@include flag-position('es-us',-384);
	@include flag-position('en-za',-408);
	@include flag-position('es-ar',-432);
	@include flag-position('es-cl',-456);
	@include flag-position('es-co',-480);
	@include flag-position('es-es',-504);
	@include flag-position('es-mx',-528);
	@include flag-position('fi-fi',-552);
	@include flag-position('fr-fr',-576);
	@include flag-position('he-il',-600);
	@include flag-position('en-in',-624);
	@include flag-position('hi-in',-624);
	@include flag-position('hu-hu',-648);
	@include flag-position('id-id',-672);
	@include flag-position('en-id',-672);
	@include flag-position('it-it',-696);
	@include flag-position('ja-jp',-720);
	@include flag-position('ko-kr',-744);
	@include flag-position('ms-my',-768);
	@include flag-position('en-my',-768);
	@include flag-position('nl-be',-792);
	@include flag-position('fr-be',-792);
	@include flag-position('nl-nl',-816);
	@include flag-position('no-no',-840);
	@include flag-position('pl-pl',-864);
	@include flag-position('pt-br',-888);
	@include flag-position('pt-pt',-912);
	@include flag-position('ru-kz',-936);
	@include flag-position('ru-ru',-960);
	@include flag-position('en-sg',-984);
	@include flag-position('sv-se',-1008);
	@include flag-position('th-th',-1032);
	@include flag-position('tl-ph',-1056);
	@include flag-position('en-ph',-1056);
	@include flag-position('tr-tr',-1080);
	@include flag-position('uk-ua',-1104);
	@include flag-position('ru-ua',-1104);
	@include flag-position('vi-vn',-1128);
	@include flag-position('zh-cn',-1152);
	@include flag-position('zh-tw',-1176);
	@include flag-position('ro-ro',-1200);
}
