/* ❗ If you do some changes in this file, please inform @Martin Prokop about it. ❗ */


// utility mixins
@mixin vertical-separation-line {
	position: absolute;
	right: -1px;
	top: 34px;
	content: "";
	display: block;
	width: 1px;
	height: calc(100% - #{$bu*4});
	background-color: $gray07;
}

@mixin horizontal-separation-line {
	content: "";
	display: block;
	height: 1px;
	width: 100%;
	position: absolute;
	left: 0;
	bottom: 0;
	background-color: $gray07;
}


// variables
$hover-delay: 250ms;
$hover-animation: ease-in;


// mobile overlay
.navigation-mobile-overlay {
	display: none;
}

/* Logic */
.navigation-for-desktop {
	// first level active state
	&.first-menu-for-home a.for-home,
	&.first-menu-for-business a.for-business,
	&.first-menu-for-partners a.for-partners,
	&.first-menu-about-us a.about-us,
	&.first-menu-regions .item.region {
		color: $blue-bright !important;
		text-decoration: underline;
	}

	// hover and active state over blog and regions - inverse
	&.blogs-special-menu .item.blogs,
	&.first-menu-regions .item.region {
		.category {
			color: $blue-bright;
		}
	}

	&.blogs-special-menu {
		.header {
			.first-menu ul li.blogs {
				color: $blue-bright;
				border-color: $gray07;
				z-index: 21;
				background: $white;
				border-radius: $bu $bu 0 0;
				padding: 13px 16px 12px;
				margin: 2px 4px -12px;

				.category {
					text-decoration: underline;
				}

				&:after {
					filter: $filter-blue-bright;
					transform: rotate(180deg);
				}
			}

			.second-menu.blogs {
				visibility: visible; // Used for readability by screen readers
				opacity: 1; // Used for the fade out animation effect
				border-radius: $bu;
			}
		}

		&.blogs-special-menu-isOpen { // ??? needed?
			.first-menu ul li.blogs,
			.second-menu.blogs {
				transition: none;
			}
		}
	}

	// Navigation styles //
	.header {
		padding: 0 $bu*2.5;

		// wrap for logo and the rest
		.header-wrap {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 100%;
			gap: 36px;

			.mobile,
			.hidden-desktop {
				display: none !important;
			}

			.desktop {
				display: block !important;
			}
		}

		a {
			text-decoration: none;
			display: inline-block; // needed to properly display focus
		}

		// Avast logo
		.avast-logo {
			a {
				&:focus {
					outline-offset: $bu/4;
				}
			}
		}

		// navigation menus
		nav {
			flex: 0 1 100%;
		}

		.side {
			display: flex;
			padding-bottom: 0;
		}

		.first-menu,
		.second-menu {
			display: flex;
			justify-content: space-between;

			// about-us should have all items on the left (the only exception)
			&.about-us {
				justify-content: flex-start;
			}

			// change icon color
			a:hover {
				.subcategory img,
				.subcategory .icon {
					filter: $filter-blue-bright;
				}
			}

			ul {
				li {
					//display: inline-block;
					display: flex;
					gap: 3px;
					flex-direction: column;
					position: relative; // for absolute positioning of third menu

					&.blogs,
					&.region-trigger {
						flex-direction: row;
						align-items: center;
					}

					&.blogs {
						border: solid 1px transparent;
						border-bottom: none;
						margin-top: -1px;
					}
				}
			}
		}

		// ### first menu //
		// top row of header
		.first-menu {
			@extend .body-4;

			.side {
				&.side1 {
					gap: $bu*2.5;
					padding-left: 20px;
				}

				&.side2 {
					gap: $bu*1.5;
				}
			}

			ul {
				li {
					padding-top: $bu;
					position: relative;
					transition: $hover-delay color $hover-animation, $hover-delay background $hover-animation;
					border-radius: $bu $bu 0 0;

					a,
					.category {
						&:focus {
							outline-offset: $bu/4;
						}
					}

					&:hover {
						.category {
							a {
								color: $orange-bright;
							}
						}

					}

					&:not(.region-trigger) {
						// to prevent unvanted spaces around region
						padding-inline: 20px;

						// decrease T1 padding close to breakpoint - to better fit longer texts
						@include media-breakpoint-down(xl2) {
							padding-inline: 12px;

							&.item.for-home.bi-nav-menu {
								padding-inline-start: $bu/2; // compensate smaller padding on second-menu and make proper start alignment
							}
						}

						.category {
							&:hover {
								text-decoration: none;
								color: $blue-bright;
							}
						}
					}

					&.blogs {
						.blogs-second-menu & {
							border: 1px solid $gray07;
							border-bottom: none;
							z-index: 21;
							background: $white;

							.category {
								color: $blue-bright;
							}

							&:after {
								@include sprite-icon('down-s', 16);
								transform: rotate(180deg);
							}
						}
					}
				}
			}
		}
		
		// landing page with region selector needs different style on region selector
		&.lp-rs {
			// top row of header
			.first-menu {
				ul {
					li {
						margin: 0;
						padding: 0;

						&.region-trigger:before {
							display: none;
						}
					}
				}
			}
		}

		// ### second menu //
		// bottom row of header
		// items that opens third level has arrow and active state
		.second-menu {
			/* &.about-us > ul > li {
				@media (max-width: 1100px) {
					flex: 1 auto;
				}
			} */

			&.about-us ul[role="menu"] li:nth-child(n + 2) .subcategory,
			&.about-us ul[role="menubar"] li .subcategory {
				@media (max-width: 1450px) {
					padding-inline: $bu/2;
				}
			}

			.subcategory {
				display: flex;
				border: 1px solid transparent; // color is defined when hovered
				border-bottom: none;
				padding: $bu 20px 15px;
				transition: $hover-delay background $hover-animation;
				position: relative;
				border-radius: 12px 12px 0 0;
				height: 100%;

				// decrease T1 padding close to breakpoint + decrease font size
				@include media-breakpoint-down(xl2) {
					padding-inline: $bu/2;
					font-size: $body-4-font-size;
				}

				&.arrow-inline {
					display: inline-block;
					white-space: nowrap;

					.subcategory-text {
						white-space: normal;
					}

					.icon-down {
						display: inline-block;
						vertical-align: middle;

						@include sprite-icon('down-s', 16);
					}
				}

				// subcategory icons
				img,
				.icon {
					position: relative;
					margin-inline-end: $bu/2;
					vertical-align: middle;
					margin-block: auto;
				}

				&.account {
					padding-inline-end: 0;
				}

				&.arrow:after,
				.icon-down {
					transition: $hover-delay transform $hover-animation;
				}

				&.arrow {
					//align-items: flex-start;
					gap: 3px;
					height: 100%;
				}

				&.shop {
					padding-inline-start: $bu;

					&:before {
						content: "";
						display: inline-block;
						width: 1px;
						height: $bu;
						background-color: $gray07;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						inset-inline-start: 0;
					}
				}

				&.avast-one-item {
					.string-label-wrap {
						.product-name-string {
							padding-inline-end: $bu/2;
						}
					}
				}
			}

			.isActive {
				.subcategory {
					color: $blue-bright;
				}
			}

			li[data-second-menu] {
				&:hover,
				&.isOpen {
					text-decoration: none;

					.subcategory {
						position: relative;
						color: $blue-bright;
						border-color: $gray07;
						border-radius: 12px 12px 0 0;
						z-index: 21;
						background: $white;
						text-decoration: underline;

						&:after,
						.icon-down {
							filter: $filter-blue-bright;
							transform: rotate(180deg);
						}
					}

					.third-menu {
						visibility: visible; // Used for readability by screen readers
						opacity: 1; // Used for the fade out animation effect
					}
				}

				&.isOpen {
					.third-menu,
					.subcategory {
						transition: none;
					}
				}
			}

			// special kind of second menu that looks like 3rd menu products
			// blogs
			&.blogs {
				display: flex;
				position: absolute;
				z-index: 20;
				inset-inline-end: 100px;
				top: 50px;
				padding: $bu*2 0;
				margin-top: -1px; // compensate the broder
				visibility: hidden; // Used for readability by screen readers
				opacity: 0; // Used for the fade out animation effect
				transition: $hover-delay opacity $hover-animation,
				$hover-delay visibility;

				@include media-breakpoint-down(xl2) {
					width: 500px;
					flex-wrap: wrap;
					padding: 10px 0;
				}

				// tile
				& > li {
					padding: 0 $bu*2;
					max-width: 260px; // otherwise text makes them too wide
					min-width: 180px;

					[lang="uk"] & {
						max-width: 320px;
					}

					@include media-breakpoint-down(xl2) {
						position: relative;
						flex: 1;
						padding: $bu*2;

						&:not(:last-child):before {
							position: absolute;
							right: -1px;
							top: 34px;
							content: "";
							display: block;
							width: 1px;
							height: calc(100% - #{$bu*4});
							background-color: $gray07;
						}

						&:nth-child(2) {
							border-right: none;
						}

						&:nth-child(-n+3):after {
							content: "";
							display: block;
							height: 1px;
							width: 100%;
							position: absolute;
							left: 0;
							bottom: 0;
							background-color: $gray07;
						}
					}

					&:not(:last-child) {
						border-inline-end: 1px solid $gray07;

						@include media-breakpoint-down(xl2) {
							border-inline-end: none;
						}
					}

					.name {
						display: block;
						scroll-padding-bottom: 20px;
						@extend .h7;
					}

					.description {
						display: block;
						font-size: $body-4-font-size;
						padding-top: $bu;
						@extend .body-4;
					}

					// hover state
					&:hover {
						.name {
							text-decoration: underline;
							color: $blue-bright;
						}
					}
				}
			}
		}

		// ### third menu //
		.third-menu {
			position: absolute;
			//left: 0;
			inset-inline-start: 0;
			top: 100%;
			z-index: 20;
			margin-top: -1px;
			visibility: hidden; // Used for readability by screen readers
			opacity: 0; // Used for the fade out animation effect
			transition: $hover-delay opacity $hover-animation, $hover-delay visibility;
			min-width: calc(100% + 24px);
			border-radius: $bu;
			border-start-start-radius: 0;


			// do not underline links on home products
			&.security,
			&.privacy,
			&.performance,
			&.family,
			&.bundles {
				a:hover {
					text-decoration: none;

					.product-name-string {
						text-decoration: underline;
					}
				}
			}

			// login must be aligned to right
			&.login {
				left: auto;
				right: 0;
			}

			// label
			.label {
				font-size: 12px;
				line-height: 14px;
				letter-spacing: .8px;
				margin: 0;
				vertical-align: text-bottom;
			}


			// third menu - block products
			// used to display consumer and business product boxes

			.block-products {
				display: grid;
				padding: 0;
				margin: 0;
				//width: 100%;

				& > li {
					padding: 0 32px;

					// list inside the main sections (used on Business and in About us)
					& > ul {
						// rewriting to flex to let them have lenght based on content (because of arrows at the end
						// arrows has been removed
						/* display: flex;
						flex-direction: column;
						align-items: stretch; // can have on the whole lenght because arrows removed */

						li {
							text-align: left;
							//white-space: nowrap;

							&:not(:last-child) {
								padding-bottom: 20px;
							}

							a {
								display: block;

								&:hover {
									color: $blue-bright;
								}
							}
						}
					}
				}

				// product items
				.product {
					// defined in grid template
					text-align: left;
					padding: $bu*2;
					font-size: $body-4-font-size;
					position: relative;

					&:not(:last-child):before {
						position: absolute;
						right: -1px;
						top: 34px;
						content: "";
						display: block;
						width: 1px;
						height: calc(100% - #{$bu*4});
						background-color: $gray07;
					}

					.product-name {
						padding-bottom: $bu*1.375;
						@extend .h7;
					}

					.os {
						padding-bottom: $bu;
					}

					.category {
						display: flex;
						align-items: center;
						margin-bottom: $bu;

						&:before {
							content: '';
							display: inline-block;
							margin-right: $bu/2;
							filter: $filter-gray06;
							@include sprite-icon('account', 16);
						}
					}

					.description {
						font-size: $body-4-font-size;
					}
				}
			}

			.hint {
				margin-bottom: 0; // removing paragraph default
				padding: $bu*1.5;
				//border-top: 1px solid $gray07;
				font-size: $body-4-font-size;
				cursor: default;
				text-align: center;

				a:not(.btn),
				a:not(.btn):hover {
					font-weight: bold;
					text-decoration: underline;
					cursor: pointer;
				}

				.hint-title {
					display: block;
				}
			}
		}

		// For business modifications
		.for-business {
			.block-products {
				color: $white;

				.divider-horizontal {
					display: block;
					margin: $bu*2 0;
					height: 1px;
					width: 100%;
					background: $gray07;
				}

				.product {
					&:not(:last-child):before,
					&:not(:last-child):after {
						background-color: $gray07;
					}
				}
			}

			.hint {
				color: $white;
				background-color: $gray07;
				font-size: $bu;
				font-weight: 600;
				line-height: $bu*1.5;
				border: none;
				border-radius: 0 0 $bu $bu;
				display: flex;
				justify-content: center;
				align-items: center;

				p {
					margin: 0 $bu*.75 0 0;
				}

				.hint-button {
					margin: 0 $bu*.75;

					a {
						display: inline-flex;
					}
				}
			}
		}

		.for-business .third-menu.products.solutions .block-products>li {
			&:nth-child(1),
			&:nth-child(2) {
				&:after {
					background-color: $gray07;
				}
			}
		}

		// About us modifications
		.about-us .third-menu {
			.block-products {
				& > li {
					padding-block: $bu*1.25;
					min-width: 240px;
					max-width: 316px; // do not extend items too much in some langs (ru, uk, ...)

					.name {
						//font-size: 1rem;
						font-weight: bold;
						//line-height: 28px;
						display: block;
						white-space: nowrap;
						padding-bottom: 1rem;
					}
				}
			}
		}


		// ### icons //
		// collapsible item
		.arrow:after {
			content: "";
			transition: $hover-delay transform $hover-animation;
			align-self: center;
			display: inline-block;
			//margin-inline-start: $bu/2;

			@include sprite-icon('down-s', 16);
		}

		// for blogs and region trigger
		.bi-nav-menu.arrow:after {
			margin-top: 0;
		}

		// region - last item in the top menu
		.region-trigger {
			position: relative;
			cursor: pointer;
			padding-inline-start: $bu*1.5;

			// separator
			&:before {
				display: block;
				content: "";
				position: absolute;
				inset-inline-start: 0;
				//left: 0;
				width: 1px;
				height: $bu;
				background-color: $gray07;
			}

			a:hover {
				text-decoration: none;
			}

			&:hover {
				a {
					color: $blue-bright;
				}

				&:after {
					filter: $filter-blue-bright;
				}
			}
		}

		// variants
		&.default {
			background-color: $white;

			.second-menu.blogs,
			.third-menu {
				background-color: $white;
				box-shadow: 0 $bu $bu*2 rgba($blue-dark, .12);
				border: 1px solid $gray07;
			}

			.second-menu.blogs {
				border-radius: $bu;
			}
		}



		// PRODUCT TILES GRID ------------------------
		// grid definitions

		// security
		.security .block-products {
			@include media-breakpoint-up(xl) {
				grid-template-columns: repeat(3, 300px);

				[lang="ru-ru"] & {
					grid-template-columns: repeat(2, 300px);
				}

				[lang="en-ww"] &,
				[lang="en-us"] &,
				[lang="en-ca"] &,
				[lang="en-gb"] &,
				[lang="en-au"] &,
				[lang="de-de"] &,
				[lang="de-ch"] &,
				[lang="fr-fr"] &,
				[lang="fr-ch"] &,
				[lang="fr-ca"] & {
					grid-template-columns: repeat(4, 300px);
				}

				li {
					&:nth-child(1),
					&:nth-child(2),
					&:nth-child(3),
					&:nth-child(4) {
						&:after {
							@include horizontal-separation-line;
						}
					}
				}
			}

			@include media-breakpoint-down(xl) {
				grid-template-columns: repeat(2, 300px);

				li {
					&:nth-child(1),
					&:nth-child(2) {
						&:after {
							@include horizontal-separation-line;
						}
					}
				}

				// Add separator for locales where "Ultimate" is available
				[lang="en-ww"] &,
				[lang="en-us"] &,
				[lang="en-ca"] &,
				[lang="en-gb"] &,
				[lang="en-au"] &,
				[lang="de-de"] &,
				[lang="de-ch"] &,
				[lang="fr-fr"] &,
				[lang="fr-ch"] &,
				[lang="fr-ca"] & {
					li {
						&:nth-child(1),
						&:nth-child(2),
						&:nth-child(3),
						&:nth-child(4) {
							&:after {
								@include horizontal-separation-line;
							}
						}
					}
				}				
			}
		}


		// privacy
		.privacy .block-products {
			grid-template-rows: auto;

			@include media-breakpoint-up(xxl) {
				grid-template-columns: repeat(4, 300px);

				li {
					&:nth-child(1),
					&:nth-child(2),
					&:nth-child(3),
					&:nth-child(4) {
						&:after {
							@include horizontal-separation-line;
						}
					}
				}
			}

			@include media-breakpoint-between(xl,xxl) {
				grid-template-columns: repeat(3, 300px);

				li {
					&:nth-child(1),
					&:nth-child(2),
					&:nth-child(3) {
						&:after {
							@include horizontal-separation-line;
						}
					}

					// Add separator for locales where "Secure Identity" is available
					[lang="en-us"] &,
					[lang="en-gb"] &,
					[lang="en-au"] &,
					[lang="en-nz"] &,
					[lang="sv-se"] &,
					[lang="es-es"] &,
					[lang="es-mx"] &,
					[lang="de-de"] &,
					[lang="de-ch"] &,
					[lang="fr-fr"] &,
					[lang="fr-be"] &,
					[lang="fr-ch"] &,
					[lang="it-it"] &,
					[lang="nl-nl"] &,
					[lang="nl-be"] &,
					[lang="pt-br"] &,
					[lang="pl-pl"] & {
						&:nth-child(4),
						&:nth-child(5),
						&:nth-child(6) {
							&:after {
								@include horizontal-separation-line;
							}
						}
					}
				}
			}

			@include media-breakpoint-down(xl) {
				grid-template-columns: repeat(2, 300px);

				li {
					&:nth-child(1),
					&:nth-child(2),
					&:nth-child(3),
					&:nth-child(4) {
						&:after {
							@include horizontal-separation-line;
						}
					}

					// Add separator for locales where "Secure Identity" is available
					[lang="en-us"] &,
					[lang="en-gb"] &,
					[lang="en-au"] &,
					[lang="en-nz"] &,
					[lang="sv-se"] &,
					[lang="es-es"] &,
					[lang="es-mx"] &,
					[lang="de-de"] &,
					[lang="de-ch"] &,
					[lang="fr-fr"] &,
					[lang="fr-be"] &,
					[lang="fr-ch"] &,
					[lang="it-it"] &,
					[lang="nl-nl"] &,
					[lang="nl-be"] &,
					[lang="pt-br"] &,
					[lang="pl-pl"] & {
						&:nth-child(4),
						&:nth-child(5),
						&:nth-child(6) {
							&:after {
								@include horizontal-separation-line;
							}
						}
					}
				}
			}
		}

		// performance
		.performance .block-products {
			grid-template-columns: repeat(2, 300px);

			[lang*="ru-"] & {
				@include media-breakpoint-only(lg) {
					grid-template-columns: repeat(2, 250px);
				}
			}
		}

	}
}