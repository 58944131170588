/*
Browser Switcher
=================
*/
$browsers: ('chrome', 'firefox', 'edge', 'edgchrom', 'ie', 'avast', 'opera', 'safari', 'yandex');

// Show default variant for users without javascript
html.no-js {
	.js-chrome,
	.js-firefox,
	.js-edge,
	.js-edgchrom,
	.js-ie,
	.js-avast,
	.js-opera,
	.js-safari,
	.js-yandex {
		&:not(.js-chrome) {
			display: none !important;
			position: absolute !important;
			left: -9999px !important;
		}
	}
}
// Default non js


@for $x from 1 through length($browsers) {
	$browser: nth($browsers, $x);

	html.js-#{$browser} {
		@for $y from 1 through length($browsers) {
			$subBrwsr: nth($browsers, $y);

			@if $subBrwsr != $browser {
				.js-#{$subBrwsr} {
					&:not(.js-#{$browser}) {
						display: none;
					}
				}
			}
		}
	
		.js-not-#{$browser} {
			display: none;
		}
	}
}