.product-card {
	display: flex;
	flex-direction: column;
	height: 100%;
	position: relative;
	width: 100%;
	margin: 0 auto;
	padding: $bu*2 $bu*1.5;
	border-radius: $bu*2;
	gap: $bu;

	// Default borders. May be overridden via custom classes
	border: 1px solid $gray07;

	@include media-breakpoint-up(sm) {
		max-width: 418px;
	}

	@include media-breakpoint-up(md) {
		max-width: 448px;
	}

	@include media-breakpoint-up(lg) {
		max-width: none;
	}

	@include media-breakpoint-up(xl) {
		padding: $bu*2 $bu*2 $bu*2.5;
	}

	.icon-wrap {
		margin-bottom: $bu/2;
	}

	.label-top {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%,-50%);
	}

	.h7, .h5 {
		margin-bottom: 0;
	}

	.h7 {
		margin-top: $bu;
	}

	.platforms {
		/* display: flex;
		justify-content: center;
		gap: $bu/2; */
		margin-inline: auto;
		margin-bottom: $bu/4;

		@include media-breakpoint-up(lg) {
			margin-bottom: $bu/2;
		}
	}

	.product-card-body {
		flex-grow: 2;
		display: flex;
		flex-direction: column;
		gap: $bu/2;
		text-align: center;
	}

	.product-card-footer {
		align-self: center;

		@include media-breakpoint-up(lg) {
			padding-top: $bu/2;
		}
	}
}