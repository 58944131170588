//
// Award badges
//
.award-badges-wrap {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	align-items: flex-start;

	@include media-breakpoint-up(sm) {
		flex-direction: row;
		align-items: center;
	}

	@include media-breakpoint-down(lg) {
		justify-content: center;
	}

	&.centered {
		justify-content: center;
		align-items: center;
	}

	.award-badge {
		display: flex;
		align-items: center;

		@include media-breakpoint-down(sm) {
			flex: 1 0 0%;
		}

		.badge-image {
			display: flex;
			align-items: center;
			flex-direction: column;
		}
	}

	.badge-text {
		width: auto;
		text-align: start;
	}

	//
	//	Tooltip
	//
	.badge-tooltip {
		position: relative;
		top: -1px;
		display: inline-block;
		vertical-align: text-bottom;
		@include sprite-icon('info', 16);
		cursor: pointer;

		&:hover {
			@include sprite-icon('hover-info', 16);
		}
	}


	// LG SIZE
	&.size-lg {
		gap: $bu*2;

		.award-badge {
			gap: $bu;

			.rating {
				margin-top: $bu/2;
			}
		}

		&.no-text {
			gap: $bu*2;
			flex-direction: row;
		}

		.badge-text {
			max-width: 176px;
			@extend .body-3;
		}

		@include media-breakpoint-up(lg) {
			gap: $bu*1.5 $bu*4;

			.award-badge {
				gap: $bu*1.5;
			}

			.badge-text {
				max-width: 336px;
			}

			&.no-text {
				gap: $bu*4;
			}
		}
	}


	// MD SIZE
	&.size-md {
		gap: $bu*2;

		.award-badge {
			gap: 12px;

			.rating {
				margin-top: 2px;
				text-align: start;
			}
		}

		.badge-text {
			max-width: 180px;
			font-size: $body-2-font-size-m;
			line-height: initial;

			@include media-breakpoint-up(lg) {
				font-size: $body-2-font-size;
				line-height: initial;
			}
		}

		@include media-breakpoint-up(lg) {
			gap: $bu*4;

			.award-badge {
				gap: $bu;
			}

			.badge-text {
				max-width: 168px;
			}
		}
	}

	// SM SIZE
	&.size-sm {
		gap: $bu*1.5;

		.award-badge {
			gap: $bu/2;
		}

		.badge-text {
			max-width: 180px;
			font-size: $body-5-font-size-m;
			line-height: initial;
		}

		// Adjust Tooltip Info icon position
		[data-bs-toggle] {
			top: 0;
		}

		&.long {
			.badge-text {
				max-width: 154px;
			}
		}

		@include media-breakpoint-up(lg) {
			.badge-text {
				max-width: 96px;
				font-size: $body-5-font-size;
				line-height: initial;
			}

			&.long {
				.badge-text {
					max-width: 136px;
				}
			}
		}
	}
}
