.productbox {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: $bu*2;
	margin: 0 auto;
	width: 100%;
	height: 100%;
	background-color: $orange-faint;
	border-radius: $bu*1.5;
	border: 1px solid $orange-light;
	overflow: hidden; // show properly rounded corner during the expanding animation

	@include media-breakpoint-up(lg) {
		gap: $bu*3;
		border-radius: $bu*3;
		border: none;
		padding: $bu*2;
	}

	@include media-breakpoint-up(xl) {
		flex-direction: row;
		padding: $bu*3;
	}


	.productbox-prices {
		display: flex;
		flex-direction: column;
		align-items: center;
		
		.pricing-desktop {
			width: 100%;
			
			.pricebox {
				@include media-breakpoint-down(lg) {
					width: 100%;

					.box-container {
						width: 100%;
						justify-content: center;
						border-width: 0;

						&.multibox {
							border-radius: 0;
						}
		
						&.boxcount-1 [abox] {
							border: none;
						}
		
						&.show-toggler {
							@include media-breakpoint-up(md) {
								&:not(.multibox) {padding-top: 0;}
							}
		
							@include media-breakpoint-down(md) {
								&:not(.multibox) .box-toggler {margin-top: 0;}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(lg) {
			.collapse-btn {
				display: none;
			}

			.productbox-pricebox.collapse:not(.show) {
				display: block;
			}
		}

		@include media-breakpoint-down(lg) {
			width: 100%;
		}
	}

	// dual-pricing
	// TRUE => pricebox on mobile and pricing-desktop BLOCK on desktop
	// FALSE => pricebox is both on mobile and desktop (pricing-desktop BLOCK should not be defined)
	&.dual-pricing {
		.productbox-prices {
			@include media-breakpoint-down(lg) {
				//.pricebox,
				.usp-pricebox {
					border: none;
					border-radius: 0 0 $bu*1.5 $bu*1.5;
				}
			}

			@include media-breakpoint-down(md) {
				//.pricebox,
				.pricing-desktop {
					display: none;
				}
			}

			@include media-breakpoint-up(lg) {
				.collapse-btn {
					display: none;
				}
			}

			@include media-breakpoint-up(md) {
				.productbox-pricebox {
					display: none;
				}
			}
		}
	}


	// Collapse Button
	.productbox-prices:not(.m-collapsed) {
		.collapse-btn {
			display: none;// do not show button if expanded
		}
	}
	
	@include media-breakpoint-down(lg) {
		.collapse-btn {
			margin: 0 $bu*1.5 $bu*1.5;
		}
	}

	.collapse-btn:not(.collapsed) {
		display: none;
	}


	// USP Pricebox overrides
	.usp-pricebox {
		background-color: $white;
		width: 100%;
	}

	// kinda different width of boxes
	.pricebox .box-container.multibox {
		@include media-breakpoint-up(md) {
			&.boxcount-3 {grid-template-columns: repeat(3, 218px);}
		}

		@include media-breakpoint-up(lg) {
			&.boxcount-3 {grid-template-columns: repeat(3, 280px);}
			&.boxcount-2 {grid-template-columns: repeat(2, 280px);}
		}

		@include media-breakpoint-up(xl2) {
			&.boxcount-3 {grid-template-columns: repeat(3, 316px);}
			&.boxcount-2 {grid-template-columns: repeat(2, 316px);}
		}
	}


	// Pricebox overrides
	.productbox-pricebox {
		@include media-breakpoint-down(lg) {
			align-self: stretch;
			width: 100%;
		}

		.pricebox {
			//width: 100%;

			.box-container {
				&:not([class*="promo-"]) {
					padding-bottom: 0;
				}

				@include media-breakpoint-up(lg) {
					&.boxcount-1 {grid-template-columns: 280px;}
				}
			}
			
			@include media-breakpoint-down(lg) {
				width: 100%;

				.box-container.multibox {
					grid-template-columns: 100%;
					width: 100%;

					&.size-sm .box-top {
						border-radius: 0;
					}

					[abox] {
						border: none;
					}
				}
				
				.box-container:not(.multibox) {
					grid-template-columns: 100%;
					width: 100%;

					&.size-sm .box-top {
						border-radius: 0;
					}

					[abox] {
						border: none;
					}
				}

				.box-container {
					.box-toggler {
						max-width: 288px;
						padding: 0;
					}
					
					.btn.full {
						max-width: 240px;
						padding: 0;
					}

					&.multibox {
						border: none;
						border-top-left-radius: 0;
						border-top-right-radius: 0;
						width: 100%;
						justify-content: center;
					}
				}
			}
		}
	}
	

	// Product Card overrides
	.product-card {
		border: none;
		padding-bottom: 0;

		@include media-breakpoint-up(lg) {
			padding: 0;
			margin: auto;
			justify-content: center;
		}

		&.hoverable-wrap.hoverable-wrap:hover { // increased specificity
			box-shadow: none;
			background-color: transparent;
		}

		.product-card-body {
			flex-grow: 0;
		}
	}
}