/* Badge */
.label {
	display: flex;
	justify-content: center;
	align-items: center;
	width: fit-content;
	color: $blue-dark;
	text-align: center;
	height: fit-content;

	&.bg-blue-bright,
	&.bg-red,
	&.bg-gray03 {
		color: $white;
	}

	&.bg-white {
		box-shadow: inset 0 0 0 1px $gray08;
	}


	/* Size modifications */
	&.label-lg {
		padding: 5px 14px;
		border-radius: 9px;

		@include media-breakpoint-up(lg) {
			padding: 6px $bu;
			border-radius: 10px;
		}
	}

	&.label-sm {
		padding: 4px 10px;
		border-radius: 6px;

		@include media-breakpoint-up(lg) {
			padding: 4px 12px;
			border-radius: 7px;
		}
	}

	// Fake borders
	@each $color, $value in $theme-colors {
		&.fauxborder-#{$color} {
			box-shadow: inset 0 0 0 1px $value;
		}
	}
}
