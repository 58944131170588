/* ❗ If you do some changes in this file, please inform @Martin Prokop about it. ❗ */

.navigation-for-mobile {
	// screen overlay when navigation is opened
	.navigation-closed .navigation-mobile-overlay {
		display: none;
	}

	&:not(.navigation-closed) .navigation-mobile-overlay {
		display: block;
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: "";
		background-color: $blue-dark;
		opacity: 0.8;
	}

	// active menu colors
	&.second-menu-products .second-menu .products,
	&.second-menu-security .second-menu .security,
	&.second-menu-privacy .second-menu .privacy,
	&.second-menu-performance .second-menu .performance,
	&.second-menu-business-partners .second-menu .business-partners,
	&.second-menu-resources .second-menu .resources,
	&.second-menu-press-center .second-menu .press-center,
	&.second-menu-about-pages .second-menu .about-pages,
	&.second-menu-privacy-pages .second-menu .privacy-pages,
	&.second-menu-family .second-menu .family,
	&.second-menu-bundles .second-menu .bundles,
	&.second-menu-investors .second-menu .investors,
	&.second-menu-diversity .second-menu .diversity {
		background-color: $white;

		&.arrow,
		&.arrow-inline {
			&:before {
				transform: translateY(-50%) rotate(-90deg) !important;
			}

			/* &:after {
				display: none;
			} */
		}
	}

	// disable secondary menu separators when opened
	&.second-menu-security .header .second-menu .subcategory.security,
	&.second-menu-privacy .header .second-menu .subcategory.privacy,
	&.second-menu-performance .header .second-menu .subcategory.performance,

	&.second-menu-products .header .second-menu .subcategory.products,
	&.second-menu-business-partners .header .second-menu .subcategory.business-partners,
	
	&.second-menu-privacy-pages .header .second-menu .subcategory.privacy-pages,
	&.second-menu-press-center .header .second-menu .subcategory.press-center,
	&.second-menu-diversity .header .second-menu .subcategory.diversity {
		&:not(:last-child):after {
			background-color: transparent !important;
		}
	}


	&.navigation-regions-0 .regions-0,
	&.navigation-regions-1 .regions-1,
	&.navigation-regions-2 .regions-2 {
		background-color: $white;

		.subcategory.arrow {
			@include sprite-icon('placeholder', 16);
		}
	}

	// navigation closed state
	&.navigation-closed {
		.header {
			.first-menu,
			.second-menu {
				display: none !important;
			}

			.toggle-menu {
				border: none;
				height: calc(100% - 1px);

				&:before {
					content: "";
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);

					//@include sprite-icon("cross", 40); // icons are shrinking on mobiles, thats why there is the '40' value
					background-image: url(https://static3.avast.com/web/i/v3/components/icons/sprites/icons-32.svg?v2);
					width: 32px;
					height: 32px;
					background-position: -48px -192px;	// Hamburger icon


					/* @include media-breakpoint-up(lg) {
						@include sprite-icon("cross", 32);
					} */
				}
			}
		}
	}

	// first menu items closed by default
	&[class*="first-menu-"] .first-menu,
	&.blogs-special-menu .first-menu,
	&.first-menu-about-us .item.about-us,
	&.blogs-special-menu .item.blogs {
		display: none;
	}

	.header {
		display: block; // shows the header after JS is loaded

		.header-wrap {
			.desktop,
			.hidden-mobile {
				display: none !important;
			}

			.mobile {
				display: block !important;
			}
		}

		// Reordering items
		// because for mobile navigation tree some items needs to be reordered
		.first-menu,
		.first-menu .side {
			flex-direction: column;
			position: relative;
		}

		.second-menu.for-home {
			& > :nth-child(1) {
				order: 1;
			}

			& > :nth-child(2) {
				order: 3;
			}

			& > :nth-child(3) {
				order: 2;
			}
		}

		.second-menu.for-business {
			& > :nth-child(1) {
				order: 1;
			}

			& > :nth-child(2) {
				order: 3;
			}

			& > :nth-child(3) {
				order: 2;
			}
		}
		// /Reordering items



		// white stripe with logo (yes, its actually a pseudo element :) )
		/* &:before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			width: 100%;
			height: $bu*4;
		} */

		.container {
			padding: 0; // removing default mobile container padding
			max-width: none; // removing default mobile max width
		}

		a {
			text-decoration: none;

			&:hover {
				text-decoration: none;
			}
		}

		// logo
		.avast-logo {
			position: absolute;
			top: 18px;
			inset-inline-start: 20px;
			//top: 13px;
			//left: 16px;
			z-index: 1010;

			a {
				display: inline-block;
			}
		}

		// hamburger
		.toggle-menu {
			position: absolute;
			top: 0;
			inset-inline-end: 0;
			//right: 0;
			z-index: 1;
			width: 72px;
			height: 73px;
			background-color: $white; // bg for a close icon
			cursor: pointer;
			border: none;
			padding: 0;
			border: 1px solid $gray07;
			border-bottom: none;
			border-radius: $bu $bu 0 0;

			&:before {
				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
	
				//@include sprite-icon("cross", 40); // icons are shrinking on mobiles, thats why there is the '40' value
				background-image: url(https://static3.avast.com/web/i/v3/components/icons/sprites/icons-32.svg?v2);
				width: 32px;
				height: 32px;
				background-position: -528px -0px;

				/* @include media-breakpoint-up(lg) {
					@include sprite-icon("cross", 32);
				} */
			}

			span {
				display: none;
			}
		}

		// navigation tree
		nav {
			position: absolute;
			top: calc(#{$bu}*4.5 - 1px);
			//right: 0;
			inset-inline-end: 0;
			overflow-y: auto;
			width: calc(100% - (#{$bu}*1.5));
			max-width: $bu*33; // reduce width - too wide navigation is decreasing orientability
			max-height: calc(100vh - ( #{$bu*5} + #{$bu*4} )); // mobile nav height + mobile MSG bar height
			margin-inline-start: $bu*1.5; // reserved space - to see the main content below the open navigation (it gives visitor a sense of context where he is on the page)
			border-radius: 0 0 $bu $bu;
		}

		.first-menu {
			border-radius: 0 0 $bu $bu;
		}


		// first menu
		.first-menu,
		.mobile-bottom-menu {
			ul {
				li {
					padding: $bu*1.5;
					padding-inline-end: $bu*4;
					background-color: $white;
					background-repeat: no-repeat;
					cursor: pointer;
					position: relative;

					&:before {
						content: "";
						position: absolute;
						top: 50%;
						//right: $bu*1.5;
						inset-inline-end: $bu*1.5;
						transform: translateY(-50%);
						@include sprite-icon("next", 16);

						html[dir="rtl"] & {
							transform: rotate(180deg) translateY(50%);
						}
					}

					// separator
					&:not(:last-child):after {
						display: block;
						content: "";
						position: absolute;
						left: $bu*1.5;
						bottom: 0;
						width: calc(100% - #{$bu} * 3);
						height: 1px;
						background-color: $gray07; // separator on lighter bg
					}

					&.for-partners:after {
						display: none;
					}

					&.blogs,
					&.about-us {
						&:after {
							background-color: $gray07; // separator on darker bg
						}
					}

					// darker items
					&.about-us,
					&.blogs,
					&.region-trigger {
						background-color: $gray09; // bg for bottom first level items
					}

					// category label
					.category:not(.with-flag) {
						display: block;
						font-weight: 750;
						padding-bottom: 5px;
					}
				}
			}
		}

		// second menu - bottom row of header
		.second-menu {
			flex-direction: column;
			background-color: $white;
			border-radius: 0 0 $bu $bu;

			// item name
			.name {
				font-size: $body-3-font-size-m;
				font-weight: 700;
			}

			// backlink
			.back {
				padding: $bu*1.5;
				padding-inline-start: $bu*4;
				font-size: $body-1-font-size-m;
				font-weight: 750;
				cursor: pointer;
				position: relative;

				&:before {
					content: "";
					position: absolute;
					top: 50%;
					inset-inline-start: $bu*1.5;
					transform: translateY(-50%);
					@include sprite-icon("back", 32);
					
					html[dir="rtl"] & {
						transform: rotate(180deg) translateY(50%);
					}

				}
			}

			// horizontal separator
			&:not(.for-home):not(.for-business) > ul:not(.mobile-links-top) > li:not(:last-child) .subcategory:after,
			&.for-home > ul:not(.mobile-links-top) > li:not(:nth-last-child(-n+2)) .subcategory:after,
			&.for-business > ul:not(.mobile-links-top) > li:not(:nth-last-child(-n+2)) .subcategory:after {
				display: block;
				content: "";
				position: absolute;
				left: $bu*1.5;
				bottom: 0;
				width: calc(100% - #{$bu} * 3);
				height: 1px;
				background-color: $gray07;
			}


			ul {
				li {
					cursor: pointer;
					position: relative;

					// grey background
					&.resources,
					&.trials {
						background-color: $gray09; // bg for bottom items
					}
				}
			}

			ul:not(.mobile-links-top) {
				li {
					.subcategory {
						position: relative;
						display: flex;
						padding: $bu*1.5;
						padding-inline-end: $bu*3.5;
						font-weight: 750;
						background-color: $gray10;

						// this means that it can be toggled
						&.arrow,
						&.arrow-inline {
							cursor: pointer;
							position: relative;

							&:before {
								content: "";
								position: absolute;
								top: 50%;
								inset-inline-end: 20px;
								transform: translateY(-50%);
								@include sprite-icon("next", 16);
					
								html[dir="rtl"] & {
									transform: rotate(180deg) translateY(50%);
								}
							}
						}


						&.avast-one-item {
							background-color: $gray10;
							padding: $bu $bu*1.5;

							.string-label-wrap {
								.product-name-string {
									padding-inline-end: $bu;
								}
							}
						}

					}
				}

				// removes the last line on the bottom of the "li" in all second menu items
				li:last-child .subcategory:after {
					display: none;
				}
			}

			// Top mobile links (Home, Support, Store, Account..)
			.mobile-links-top {
				position: relative;
				display: flex;
				flex-direction: row;
				padding: 0 $bu/4 0;
				background-color: $white;

				// All items should have equal size
				> li {
					flex: 1;
				}

				.subcategory {
					padding: 0 10px $bu*1.5;
					text-align: center;
					font-weight: 750;

					.icon {
						display: block;
						margin: 0 auto $bu*0.5;
					}
				}

				// Create space for login buttons
				&.isOpen {
					padding-bottom: $bu*7;
				}

				// Account/Login section
				.login {
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					display: none;

					&.isOpen {
						display: block;

						// Login buttons
						.mobile-link {
							padding: $bu*0.5 $bu;
						}
					}
				}
			}

			// blogs
			&.blogs {
				li:not(.back) {
					padding: $bu*1.5;
					padding-right: 60px;
					position: relative;

					&:not(:last-child):after {
						content: "";
						display: block;
						position: absolute;
						left: $bu*1.5;
						bottom: 0;
						background-color: $gray07;
						width: calc(100% - 48px);
						height: 1px;
					}

					&:last-child {
						border-bottom: none;
					}

					span {
						display: block;

						&.txt-blogs-title {
							padding-bottom: $bu*0.5;
						}
					}
				}
			}
		}

		// third menu
		.third-menu {
			.block-products {
				.product {
					padding: $bu*1.5;
					padding-bottom: 0;

					.logo {
						margin-right: 7px;
						vertical-align: middle;
					}
					.os {
						padding-bottom: $bu;

						.icon {
							margin: 0 4px;
							width: $bu;
						}
					}

					.category {
						margin: $bu 0;
						color: $gray03;
						display: flex;
						align-items: center;

						&:before {
							content: "";
							margin-right: $bu/2;
							filter: $filter-gray03;
							display: inline-block;
							@include sprite-icon("account", 16);
						}
					}

					.name {
						font-size: $body-3-font-size-m;
						font-weight: 700;
					}

					.description {
						margin-bottom: $bu*1.5;
					}

					.product-links {
						margin-bottom: $bu;

						a {
							display: block;
							padding: $bu 0;
							position: relative;
						}
					}
				}
			}

			// all items with this class has arrow
			.mobile-link {
				a {
					display: block;
				}
			}

			// Section modifications

			&.security {
				// hint with platform selection
				.hint {
					display: block;
					position: relative;
					margin: 0 $bu*1.5 $bu*1.5;
					padding: $bu;
					background-color: $blue-faint;
					border: 1px solid $blue-pale;
					border-radius: $bu*0.75;

					&.hint-prompt {
						text-decoration: underline;
					}

					// helper element
					.hint-togler {
						display: block;
						padding-inline-end: $bu*2.5; // to prevent text overlap the arrow
					}

					.hint-title {
						display: block;
					}

					// hidden until .show-hint
					.hint-content {
						display: none;
						margin: $bu $bu/-4 0;
					}

					// button inside hint
					a {
						display: inline-block;
						margin: $bu/4;
						padding: 7px 20px;
						background-color: $white;
						border: 1px solid $blue-light;
						border-radius: 50px;
					}

					.close-hint {
						position: absolute;
						top: 0;
						inset-inline-end: $bu/2;
						height: 54px;
						width: 54px;
						display: flex;
						justify-content: center;
						align-items: center;

						&::before {
							content: "";
							display: block;
							@include sprite-icon("down-s", 16);
						}
					}

					.js-hint.hint-open {
						&~.hint-content {
							display: block;
						}

						.close-hint {
							transform: rotate(180deg);
						}
					}
				}
			}
		}

		// Category modifications
		// for home
		.for-home .third-menu {
			.block-products {
				& > li {
					padding: $bu $bu*3 0;

					&:first-child {
						padding-top: $bu*1.5;
					}

					&:last-child {
						padding-bottom: $bu*1.5;
					}

					.block-header,
					.os,
					.description,
					.bottom-link {
						display: none;
					}
				}
			}
		}

		// For business
		.for-business .third-menu {
			.block-products {
				.product {
					padding-left: $bu*3;
				}
			}

			.hint {
				padding: $bu*1.5;
			}

			// products section
			&.products {
				.block-products {
					.product {
						&:not(:last-child):after {
							content: "";
							display: block;
							height: 1px;
							background-color: $gray07;
						}

						.name {
							display: flex;
							gap: $bu;
						}

						.product-name {
							font-size: $body-3-font-size-m;
							line-height: $body-4-line-height-m;
							font-weight: 750;
						}

						.category {
							color: $gray05;
						}

						.description {
							a {
								text-decoration: underline;
							}
						}
					}

					.product.speciality-products {
						li {
							padding: $bu 0 $bu $bu*1.5;

							&:first-child {
								padding-top: $bu*1.5;
							}

							&:last-child {
								padding-bottom: $bu*2;
							}
						}
					}
				}

				.hint {
					margin: $bu*1.5;
					margin-top: 0;
					padding: $bu*1.5;
					border-radius: $bu/2;
					border: 1px solid $gray07;
					background-color: $gray10;

					p {
						padding-bottom: $bu;
					}
				}
			}

			// business partner section
			&.business-partners {
				.product {
					&:after {
						content: "";
						display: block;
						height: 1px;
						background-color: $gray07;
						margin-left: $bu*-1; // negative margin to counteract bigger left padding on this section
					}
				}

				.hint {
					background-color: $white;
					padding-left: $bu*3;

					.hint-button:not(:last-child) {
						margin-bottom: $bu;
					}
				}

				.mobile-link {
					padding: $bu 0 $bu $bu*1.5;

					&:first-child {
						padding-top: $bu*1.5;
					}

					&:last-child {
						padding-bottom: $bu*2;
					}
				}
			}
		}

		// about us
		.about-us {
			.second-menu {
				ul:not(.mobile-links-top) li {
					.subcategory {
						background-color: $gray09;
					}

					// underline also items that don't have submenu
					.line {
						&:after {
							content: "";
							width: calc(100% - 3rem);
							height: 1px;
							background-color: $gray07;
							position: absolute;
							bottom: 0;
							left: 50%;
							transform: translateX(-50%);
						}
					}
				}
			}

			.third-menu {
				.block-products {
					ul,
					li {
						padding: 0;
					}

					.mobile-link a {
						padding: $bu;
						padding-inline-start: $bu*3;
					}

					.name {
						padding: $bu;
						padding-inline-start: $bu*2;
						display: flex;
						align-items: center;
					}
				}
			}
		}

		// blogs
		.blogs {
			li:not(.back) {
				background-color: $gray09;
			}
		}

		// Header variant - Landing page with Region selector
		&.lp-rs {
			// show only the icon
			.avast-logo img {
				@include media-breakpoint-down(sm) {
					clip-path: inset(0 80px 0 0);
					
					html[dir="rtl"] & {
						transform: translateX(80px);
					}
				}
			}

			// hide the Hamburger
			.toggle-menu {
				display: none;
			}

			nav {
				top: 0;
				z-index: 1010;
				width: auto;
			}

			// show region selector
			.first-menu {
				display: block !important;

				li.region-trigger {
					padding-block-start:$bu*1.5;
					padding-inline-end: $bu*2.75;
					background-color: transparent;

					&:before {
						inset-inline-end: $bu*1.25;
						@include sprite-icon('down-s', 16);
					
						html[dir="rtl"] & {
							transform: translateY(-50%);
						}
					}
				}
			}
		}
	}
}


// Specific styles for opened navigation
body.navigation-for-mobile:not(.navigation-closed) {
	overflow: hidden;

	nav {
		border: 1px solid $gray07;
	}

	&.inverse nav {
		border: 1px solid $gray03;
	}
}

// localisation fixes
[lang="no"],
[lang="sv"],
[lang="da"],
[lang="es"],
[lang="de"],
[lang="fr"],
[lang="it"],
[lang="nl"],
[lang="cs"] {
	.navigation-for-mobile {
		.header {
			.second-menu {
				.mobile-links-top {
					flex-wrap: wrap;
					justify-content: space-around;
				}
			}
		}
	}
}