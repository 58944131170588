//
//	Tooltip – v3
//
.tooltip {
	padding: 0;
	pointer-events: none;	// // FIX: Tooltip flickering issue – https://stackoverflow.com/questions/14326724/twitter-bootstrap-tooltip-flickers-when-placed-on-top-of-button-but-works-fine

	&.show {
		opacity: unset;
	}

	//
	// Light Version – Default
	//
	.tooltip-inner {
		--#{$prefix}tooltip-max-width: 280px;

		display: flex;
		flex-direction: column;
		align-items: start;

		padding: $bu/2 $bu*.75;

		font-weight: 400;
		text-align: start;
		color: $gray05;
		@extend .body-5;

		background-color: $white;
		border: 1px solid $gray07;
		border-radius: $bu/2;
		box-shadow: 0px 12px 24px rgba($blue-dark, .08);

		@include media-breakpoint-up(lg) {
			padding: $bu*.75 $bu;
			border-radius: 10px;
		}

		.h7 {
			color: $blue-dark;
		}

		// in case there is a bullet list
		ul {
			padding-inline-start: $bu;
			margin-bottom: 0;
		}
	}


	//
	//	Dark Version
	//
	&.dark {
		.tooltip-inner {
			background-color: $blue-dark;
			border: 1px solid $blue-dark;
			color: $white;
			box-shadow: 0px 12px 24px rgba($blue-dark, .24);

			.h7 {
				color: $white;
			}
		}
	}


	//
	//	Size – Large
	//
	&.large {
		.tooltip-inner {
			--#{$prefix}tooltip-max-width: 200px;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: $bu/2;

			padding: $bu;

			text-align: center;
			@extend .body-4;

			border-radius: $bu;

			@include media-breakpoint-up(lg) {
				--#{$prefix}tooltip-max-width: 240px;
				padding: $bu*1.5;
				border-radius: $bu;
			}
		}

		&.dark {
			.tooltip-inner {
				color: $gray07;
			}
		}

		&.bs-tooltip-top {
			&.right,
			&.left {
				.tooltip-arrow {
					&:before {
						@include media-breakpoint-down(sm) {
							inset-inline-end: $bu;
						}
					}
				}
			}
		}
	}


	//
	//	Arrows
	//
	.tooltip-arrow {
		width: $bu*.75;
		height: $bu/2;

		&:before {
			border-width: 0;
		}
	}


	//
	//	Tooltip position – Top
	//
	&.bs-tooltip-top {
		padding-bottom: $bu/2;

		.tooltip-arrow {
			&:before {
				content: url("../../i/v3/components/icons/tooltip/arrow-tooltip-light-top.svg");
				top: -8.5px;

				@include media-breakpoint-up(sm) {
					top: -8.5px;
				}
			}
		}

		&.dark {
			.tooltip-arrow {
				&:before {
					content: url("../../i/v3/components/icons/tooltip/arrow-tooltip-dark-top.svg");
				}
			}
		}
	}


	//
	//	Tooltip position – Bottom
	//
	&.bs-tooltip-bottom {
		padding-top: $bu/2;

		.tooltip-arrow {
			&:before {
				content: url("../../i/v3/components/icons/tooltip/arrow-tooltip-light-bottom.svg");
				bottom: -6.5px;

				@include media-breakpoint-between(sm,md) {
					bottom: -6.5px;
				}
			}
		}

		&.dark {
			.tooltip-arrow {
				&:before {
					content: url("../../i/v3/components/icons/tooltip/arrow-tooltip-dark-bottom.svg");
				}
			}
		}
	}


	//
	//	Tooltip position – Start
	//
	&.bs-tooltip-start {
		padding-inline-end: $bu/2;

		.tooltip-arrow {
			&:before {
				content: url("../../i/v3/components/icons/tooltip/arrow-tooltip-light-left.svg");
				inset-inline-start: 3px;
				top: -5px;
			}
		}

		&.dark {
			.tooltip-arrow {
				&:before {
					content: url("../../i/v3/components/icons/tooltip/arrow-tooltip-dark-left.svg");
				}
			}
		}
	}


	//
	//	Tooltip position – End
	//
	&.bs-tooltip-end {
		padding-left: $bu/2;

		.tooltip-arrow {
			&:before {
				content: url("../../i/v3/components/icons/tooltip/arrow-tooltip-light-right.svg");
				top: -5px;
				inset-inline-end: 3px;

				@include media-breakpoint-up(lg) {
					inset-inline-end: 3.2px;
				}

				@include media-breakpoint-between(xl,xxl) {
					inset-inline-end: 3.5px;
				}

				@include media-breakpoint-up(xxl) {
					inset-inline-end: 3px;
				}
			}
		}

		&.dark {
			.tooltip-arrow {
				&:before {
					content: url("../../i/v3/components/icons/tooltip/arrow-tooltip-dark-right.svg");
				}
			}
		}
	}


	//
	// Arrow positioning
	//
	&.bs-tooltip-top,
	&.bs-tooltip-bottom {
		&.tooltip-arrow-start {
			.tooltip-arrow {
				inset-inline-start: $base-unit !important;
			}
		}

		&.tooltip-arrow-end {
			.tooltip-arrow {
				inset-inline-start: auto !important;
				inset-inline-end: $base-unit !important;
			}
		}
	}
}
