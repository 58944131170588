/**************************
// Message Bar
**************************/

.message-bar {
	&.sticky-message {
		position: sticky;
		top: 0;
		transition: all 150ms ease-out;

		&.hidden {
			top:-100px
		}
	}

	&.with-link {
		.bar-text {
			text-decoration: underline;
		}
   }

	&-wrapper {
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: center;
		text-decoration: none;
		width: 100%;
		padding: $base-unit $base-unit*1.25;
		overflow: hidden;
		gap: $base-unit;

		@include media-breakpoint-up(md) {
			padding: $base-unit*.5 $base-unit*1.25;
			gap: $base-unit*1.5;
			min-height: 64px;
		}

		@include media-breakpoint-up(xxl) {
			padding: $base-unit 0;
			min-height: 80px;
		}

		&:hover {
			.bar-text {
				text-decoration: none;
			}
		}
	}

	&-illustrations,
	&-content {
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: center;
		text-decoration: none;
		gap: $base-unit;

		@include media-breakpoint-up(md) {
			gap: $base-unit*1.5;
		}
	}

	&-illustrations {
		@include media-breakpoint-down(md) {
			align-items: flex-start;
		}

		.bar-image {
			max-width: $base-unit*5.5;
			height: 48px;

			@include media-breakpoint-up(md) {
				max-width: $base-unit*10;
			}
		}
	}

	&-content {
		@include media-breakpoint-down(md) {
			flex-direction: column;
			align-items: flex-start;
		}
	}

	&.with-button, &.with-counter {
		.message-bar-wrapper {
			@include media-breakpoint-down(md) {
				align-items: flex-start;
			}
		}
	}

	a:not(.btn) {
		text-decoration: none;
	}

	img[class*='bar-icon-'] {
		height: $base-unit*3;
	}

	

	// Icon filter by themes (bg colors)
	img[class*='bar-icon-']:not(.nofilter) {
		filter: $filter-blue-dark;
	}

	&.bg-red {
		img[class*='bar-icon-']:not(.nofilter) {
			filter: $filter-white;
		}
	}
	// -----



	// Hover
	&.bg-blue-dark {
		&:hover {
			background-color: $gray01 !important;
		}
	}

	&.bg-orange-faint {
		&:hover {
			background-color: $orange-off-white !important;
		}
	}

	&.bg-white {
		&:hover {
			background-color: $gray10 !important;
		}
	}

	&.bg-blue-faint {
		&:hover {
			background-color: $white !important;
		}
	}

	&.bg-green-bright {
		&:hover {
			background-color: $green-light !important;
		}
	}

	&.bg-red {
		&:hover a {
			background-color: rgba($blue-dark, .16);
		}
	}

	.btn {
		&:hover {
			background-color: transparent;
		}
	}

	// GLOWEB-5448 RUBY users limitation
	&#ru-message-bar {
		display: none;
	}

	&.with-logo {
		.message-bar-wrapper {
			@include media-breakpoint-down(lg) {
				justify-content: start;
			}
		}
		.message-bar-illustrations {
			.bar-image {
				margin-top: 20px;
				height: 36px;
				padding-left: 40px;
				position: absolute;
				top: 0;
				left: 0;

				@include media-breakpoint-down(xxl) {
					margin-top: 14px;
				}
				@include media-breakpoint-down(lg) {
					max-width: none;
					height: 36px;
					position: relative;
					padding-left: 0;
					margin-top: 0;
				}
			}

			.bar-icon-left {
				@include media-breakpoint-down(xl) {
					display: none;
				}
			}
		}
	}
}
