// SET OF ITEMS
//------------------
.feature-items, // <- Do not use this class name anymore, pls
.process {
	display: flex;
	flex-direction: column;
	align-content: center;

	@include media-breakpoint-up(lg) {
		flex-direction: row;
		justify-content: center;
	}

	.feature-item {
		height: auto;

		@include media-breakpoint-up(lg) {
			width: 33.33%;
		}
	}

	// STEPS
	&.feature-items-steps {
		padding: 0;
		background-color: $white;
		border-radius: $bu*2;
		border: solid 1px $gray07;
		width: fit-content;
		margin: 0 auto;

		.feature-item {
			border-radius: 0;
			border-color: transparent;

			&:not(:last-child) {
				border-bottom-color: $gray07;

				@include media-breakpoint-down(lg) {
					padding-bottom: $bu*2.5;
				}
			}
		}

		@include media-breakpoint-up(lg) {
			.feature-item {
				&:not(:last-child) {
					border-bottom-color: transparent;
					border-inline-end-color: $gray07;
				}
			}
		}
	}

	// SEQUENCE
	&.sequence {
		gap: $bu*1.5;

		.feature-item:not(:last-of-type) {
			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 100%;
				inset-inline-end: calc(50% - 12px);
				@include sprite-icon('down', 32);

				@include media-breakpoint-up(lg) {
					top: calc(50% - 12px);
					inset-inline-end: -$bu*1.5;
					@include sprite-icon('down', 24);
					transform: rotate(-90deg);

					[dir="rtl"] & {
						transform: rotate(90deg);
					}
				}
			}
		}

		&.arrow-back {
			.feature-item:not(:last-of-type) {
				&:after {
					@include media-breakpoint-up(lg) {
						transform: rotate(90deg);

						[dir="rtl"] & {
							transform: rotate(-90deg);
						}
					}
				}
			}
		}
	}
}
