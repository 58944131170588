.collapse-btn {
	cursor: pointer;
	
	.icon { transition: all ease-in .2s; }

	&.collapsed {
		.hide-label { display: none; }
		.show-label { display: inline-block; }

		.icon {
			transform: rotate(0deg);
		}
	}
	
	&:not(.collapsed) {
		.hide-label { display: inline-block; }
		.show-label { display: none; }

		&.rotate45 {
			.icon { transform: rotate(45deg); }
		}

		&.rotate90 {
			.icon { transform: rotate(90deg); }
		}

		&.rotate180 {
			.icon { transform: rotate(180deg); }
		}
		
		&.rotate-45 {
			.icon { transform: rotate(-45deg); }
		}
		
		&.rotate-90 {
			.icon { transform: rotate(-90deg); }
		}
		
		&.rotate-180 {
			.icon { transform: rotate(-180deg); }
		}
	}
}