/*********
HOVERABLE ELEMENT WITH BUTTON INSIDE
**********/
.hoverable-wrap {
	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
		background-color: $white;

		// hovered cards have shadow but those with orange background
		&:not(.bg-orange-faint) {
			box-shadow: 0px 32px 56px rgba($blue-dark, .08);
		}

		// Inner BUTTON states changes too
		.btn:not(:disabled):not(.disabled) {
			.btn-icon-hover { display: inline; }
			.btn-icon-normal { display: none; }

			&.size-sm:before {
				height: calc(100% + 4px);
				width: calc(100% + 4px);
			}

			&.size-md:before {
				height: calc(100% + 4px);
				width: calc(100% + 4px);

				@include media-breakpoint-up(lg) {
					height: calc(100% + 6px);
					width: calc(100% + 6px);
				}
			}

			&.size-lg:before {
				height: calc(100% + 6px);
				width: calc(100% + 6px);

				@include media-breakpoint-up(lg) {
					height: calc(100% + 8px);
					width: calc(100% + 8px);
				}
			}
			
			@each $color, $value in $btn-themes {
				&.theme-#{$color} {
					@include btn-hover($color);
					@include btn-hover-shadow($color);
				}
			}

			&.link {
				text-decoration: none;
			
				.inverse & {
					color: $white;
					.btn-icon { filter: $filter-orange-bright; }
				}
		
				&,
				.light & {
					color: $blue-bright;
					.btn-icon { filter: $filter-blue-bright; }
				}
			}
		}

		// Titles turn to blue
		&.article .article-title,
		&.award .award-title,
		&.feature-item .feature-item-title,
		&.product-card .h5,
		&.signpost .signpost-title {
			color: $blue-bright-alt;
		}
	}
}