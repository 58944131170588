/* ❗ If you do some changes in this file, please inform in the #help-crm Slack channel about it. ❗ */

// region selector
@import "region-selector";

.footer-links .links,
.footer {
	a:not(.btn) {
		color: $gray07;

		&:hover {
			color: $white;
			text-decoration: underline;
		}
	}
}


// bottom links
.footer-links {
	@include media-breakpoint-down(lg) {
		text-align: center;
	}

	.container {
		position: relative;
		padding-top: $bu*5;

		@include media-breakpoint-up(lg) {
			padding-top: $bu*7.5;
			padding-bottom: $bu*3.5;
		}
	}

	// basic settins for this column
	.select-region {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: $bu*2;

		@include media-breakpoint-up(lg) {
			align-items: flex-start;
			padding-bottom: 0;
		}

		.logo-avast {
			margin-bottom: $bu*1.5;

			@include media-breakpoint-up(lg) {
				margin-bottom: $bu*2;
			}
		}

		.select-region-wrap {
			flex-grow: 2;
		}

		// region selector button
		.select-region-button {
			display: inline-flex;
			align-items: center;
			height: $bu*2.5;
			max-width: 100%; // overflow fix
			border: 1px solid $gray04;
			border-radius: $bu*3;
			box-shadow: 0 6px 12px rgba($blue-dark, .08);
			padding: 0 $bu 0 12px;
			margin-bottom: $bu*2;
			cursor: pointer;
			@extend .body-3;

			@include media-breakpoint-up(lg) {
				height: $bu*3;
				margin-bottom: 0;
			}

			&:hover {
				text-decoration: none;
			}

			&:before {
				position: static;
				vertical-align: middle;

				@include region-selector-flag;
			}

			span {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				display: inline-block;
				padding: 0 12px;
			}
		}
	}


	// links blocks
	.links {
		.h7 {
			@include media-breakpoint-up(lg) {
				min-height: $h7-line-height*2 + $bu/2; // this is a fix to prepare space for two line header
				overflow: hidden;
				text-overflow: ellipsis;

				.icon {
					display: none;
				}
			}
		}

		.list-unstyled {
			margin-bottom: 0; // override bootstrap style
			padding: 0;

			li {
				@extend .body-4;
				line-height: 14px;
				padding: 2px 0;

				@include media-breakpoint-up(lg) {
					line-height: 18px;
					min-height: 22px;
				}
			}

			li:not(:last-child) {
				margin-bottom: 12px;

				@include media-breakpoint-up(lg) {
					margin-bottom: $bu/2;
				}
			}
		}
	}

	// links colapse on mobile; class is added by JS
	&.js-colapse {
		.links {
			padding-top: 23px;
			padding-bottom: 23px;
			text-align: start;
			position: relative;

			&:before {
				content: "";
				display: block;
				position: absolute;
				bottom: 0;
				left: $bu;
				height: 1px;
				width: calc(100% - (#{$bu}*2));
				background-color: $gray04;
			}

			.h7 {
				padding: 23px 0; // expand clickable area
				margin: -23px 0; // collapse paddings by negative margin
				display: flex;
				justify-content: space-between;
				align-items: center;
				cursor: pointer;

				.icon {
					transition: all ease-in .2s;
				}
			}

			.list-unstyled {
				display: none;
			}
		}

		.expand-list {
			&~.list-unstyled {
				display: block;
				padding-top: $bu*1.5;

				li a {
					display: flex; // stretch to full width of the container
				}
			}

			&.h7 .icon {
				transform: rotate(180deg);
			}
		}
	}

	// social links
	.social {
		align-self: flex-end;
	}


	// hr divider
	@include media-breakpoint-up(lg) {
		&+.footer .footer-wrapper:before {
			display: block;
			content: "";
			position: absolute;
			top: 0;
			left: $bu;
			height: 1px;
			width: calc(100% - (#{$bu}*2));
			background-color: $gray04;
		}
	}
}

// footer
.footer {
	.footer-wrapper {
		position: relative; // for .divider
		display: flex;
		flex-direction: column-reverse;
		gap: $bu*2.5;
		padding-top: $bu*2;
		padding-bottom: $bu*2.5;
		text-align: center;

		@include media-breakpoint-up(lg) {
			flex-direction: column;
			gap: $bu;
			padding-top: $bu*3;
			padding-bottom: $bu*3;
			text-align: start;
		}
	}

	.middle,
	.right {
		& > a,
		& > button {
			display: flex;
			gap: $bu/2;

			@include media-breakpoint-up(lg) {
				gap: $bu;
			}
		}
	}

	&.lp {
		.footer-wrapper {
			flex-direction: column-reverse;
			align-items: center;

			@include media-breakpoint-down(lg) {
				gap: $bu*2;
			}

			@include media-breakpoint-up(lg) {
				flex-flow: wrap;
				flex-direction: row;
			}
		}

		.middle {
			& > a,
			& > button {
				&:before {
					content: "•";
					display: inline-block;

					&:hover {
						text-decoration: none;
					}
				}
				
				&:first-child:before {
					@include media-breakpoint-down(lg) {
						display: none;
					}
				}
			}

		}

		.right {
			flex-grow: 2;
			gap: $bu/2;
			display: inline-flex;

			@include media-breakpoint-up(xl) {
				justify-content: flex-end;
			}

			@include media-breakpoint-up(lg) {
				gap: $bu;
			}

			// add bullets to all but first items
			& > a {
				&:not(:first-child):before {
					content: "•";
					display: inline-block;

					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}

	// footer links
	a {
		@extend .body-4;

		// do not wrap links on bigger screens
		@include media-breakpoint-up(lg) {
			white-space: nowrap;
		}
	}

	// middle block
	.middle {
		display: flex;
		gap: $bu/2;
		flex-wrap: wrap;
		justify-content: center;

		@include media-breakpoint-up(lg) {
			gap: $bu;
			justify-content: start;
		}

		// add bullets to all but first items
		& > a,
		& > button {
			&:not(:first-child) {
				&:before {
					content: "•";
					display: inline-block;

					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}

	// footer text
	.copyright {
		color: $gray07;

		// GEN Logo
		.copyright-company {
			display: inline-block;

			img {
				margin-inline-end: 4px;
				vertical-align: bottom;

				@include media-breakpoint-up(lg) {
					vertical-align: text-bottom;
				}
			}
		}

		.copyright-rights {
			white-space: nowrap;
		}
	}


	// Unsubscribe button (DE + FR)
	.termination {
		@include media-breakpoint-down(lg) {
			margin-bottom: $bu*2.5;
		}
	}

	//SSVG for retentions
	.ssvg-disclaimer {
		a {
			display: inline;
			text-decoration: underline;
			margin: 0;
		}
	}
}
