.money-back {
	display: flex;
	align-items: center;
	gap: $bu/2;

	.icon {
		.inverse & {
			filter: $filter-white;
		}

		&,
		.light & {
			filter: $filter-blue-dark;
		}
	}

	&.size-lg .icon {
		background-image: url("../../i/v3/components/icons/sprites/icons-32.svg");
		background-position: -144px -192px;
		width: 32px;
		height: 32px;

	}

	&.size-md .icon {
		background-image: url("../../i/v3/components/icons/sprites/icons-24.svg");
		background-position: -120px -160px;
		width: 24px;
		height: 24px;
	}

	&.size-sm .icon {
		background-image: url("../../i/v3/components/icons/sprites/icons-16.svg");
		background-position: -96px -128px;
		width: 16px;
		height: 16px;
	}

	&.money-back-center {
		justify-content: center;
	}
}
