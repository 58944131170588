/* REGION SELECTOR
===================
*/
.js-language-selector-open {
	overflow: hidden;

	.region-selector {
		display: flex;
	}
}


// common
.region-selector {
	display: none;
	position: fixed;
	z-index: map-get($z-index, cmp-region-selector);
	left: 0;
	top: 0;
	width: 100%;
	padding-top: $bu*5;
	padding-bottom: $bu*5;
	background-color: $white;
	position: fixed;
	bottom: 0;
	right: 0;
	overflow: auto;
	min-height: 100vh;

	.custom-regions {
		display: flex;
		flex-direction: column;

		&:after {
			@include media-breakpoint-up(lg) {
				content: "";
				display: block;
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				border-top: 1px solid $gray07;
				height: 40px;
			}

			@include media-breakpoint-up(xl) {
				height: 56px;
			}

			@include media-breakpoint-up(xxl) {
				height: 64px; 
			}
		}

		// main regions on the top
		.main-regions {
			display: flex;
			gap: $bu;
			padding-bottom: $bu;
			flex-direction: column;

			@include media-breakpoint-up(md) {
				flex-direction: row;
				gap: $bu*2;
			}
		}

		// area
		.area {
			display: flex;
			flex-direction: column;
			border-bottom: 1px solid $gray07;

			.inverse & {
				border-color: $white;
			}

			// subcategory
			.subcategory {
				cursor: pointer;
				display: flex;
				justify-content: space-between;
				align-items: center;
		
				// this means that it can be toggled
				&.arrow:after {
					@include sprite-icon('down', 24);
					content: '';
					cursor: pointer;
					filter: $filter-blue-dark;
					display: inline-block;
					transition: transform 250ms ease-in;
		
					.inverse & {
						filter: $filter-white;
					}
				}
			}

			// GRID definition
			.regions-wrapper {
				display: none;
				gap: $bu 50px;
				padding-bottom: $bu*2.5;

				@include media-breakpoint-down(lg) {
					.region.global {display: none;} // hide desktop global regions 
				}

				@include media-breakpoint-up(sm) {
					grid-template-columns: repeat(2, 1fr);
					grid-auto-flow: row;
				}

				@include media-breakpoint-up(md) {
					grid-template-columns: repeat(3, 1fr);
				}


				@include media-breakpoint-up(lg) {
					display: grid;
					grid-template-rows: repeat(16, 1fr) 16px 1fr;
					grid-template-columns: none;
					grid-auto-flow: column;
					padding-bottom: 0;
					gap: $bu/2 $bu;

					.region.global {grid-row: 18;}

					&.area-2 {
						//grid-template-columns: repeat(2, auto);
						.region-spacer {grid-column: 1 / span 2; grid-row: 17;}
						
						.region.global {
							&.en-eu {grid-column: 1;}
							&.en-ww {grid-column: 2;}
						}
					}

					&.area-3 {
						//grid-template-columns: repeat(2, auto);
						.region-spacer {grid-column: 1 / span 2; grid-row: 15 / span 4;}
					}
				}

				@include media-breakpoint-up(xl) {
					grid-template-rows: repeat(11, 1fr) 32px 1fr;
					gap: $bu $bu*2;

					.region.global {grid-row: 13;}

					&.area-2 {
						//grid-template-columns: repeat(3, auto);
						.region-spacer {grid-column: 1 / span 3; grid-row: 12;}
					}

					&.area-3 {
						.region-spacer {grid-row: 12 / span 2;}
					}
				}

				@include media-breakpoint-up(xl2) {
					grid-template-rows: repeat(11, 1fr) 48px 1fr;
					gap: $bu/2 50px;
				}

				@include media-breakpoint-up(xxl) {
					gap: $bu 50px;
				}
			}

			&.isOpen {
				.subcategory:after {
					transform: rotate(180deg);
				}

				.regions-wrapper {
					display: grid;
				}
			}
		}
			
		// Items with flag
		.region {
			list-style-type: none;
			display: inline-flex;

			// Default font for region names as they are written in native languages and our font may not support the letters
			font-family: Helvetica, Arial, sans-serif;
			font-size: 14px;
			line-height: 22px;

			&.with-flag {
				text-decoration: none;
				display: inline-flex;
				align-items: center;
				gap: $bu/2;

				&:before {
					@include region-selector-flag;
				}

				&:hover {
					text-decoration: underline;
				}
			}
		}

		.subcategory,
		.main-regions {
			padding: $bu*1.5 0;
		}

		.subcategory,
		.main-regions,
		a.region {
			color: $gray03;

			.inverse & {
				color: $white;
			}
		}
	}

	// Desktop
	@include media-breakpoint-up(lg) {
		align-items: center;
		justify-content: center;

		// custom regions
		.custom-regions {
			position: relative;
			flex-direction: row;
			justify-content: space-between;
			overflow-y: auto;

			// main regions - used only on mobile
			.main-regions {
				display: none;
			}

			.area {
				position: relative;
				z-index: 1;
				border-bottom: none;
				gap: 10px;

				.subcategory {
					display: inline;
					cursor: initial;
					pointer-events: none;
					justify-content: start;
					align-items: start;
					padding: 0;
					min-height: 44px; // twice the lineheights value

					&.arrow:after {
						display: none;
					}
				}
			}
		}
	}
	
	@include media-breakpoint-up(xl) {
		.custom-regions {
			.area {
				.subcategory {
					gap: 4px;
				}
			}
		}
	}

	.inverse & {
		background-color: $blue-dark;
	}

	// close icon
	.region-selector-close {
		position: absolute;
		right: $bu*1.25;
		top: $bu*1.5;
		cursor: pointer;

		@include media-breakpoint-up(md) {
			right: $bu*2.5;
			top: $bu*2.5;
		}
	}
}
