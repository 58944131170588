/******************
// FEATURE ITEM COMPONENT
******************/

.feature-item {
	display: flex;
	height: 100%;
	width: 100%;
	position: relative;
	margin: 0 auto;
	padding: $bu*1.5;
	flex-direction: column;

	// Default borders. May be overridden via custom classes
	border: 1px solid $gray07;

	@include media-breakpoint-up(sm) {
		max-width: 418px;
	}

	@include media-breakpoint-up(md) {
		max-width: 448px;
	}

	@include media-breakpoint-up(lg) {
		max-width: none;
		padding-bottom: $bu*2;
	}

	&.centered {
		align-items: center;
		text-align: center;
	}

	&:not(.centered) {
		&:not(.in-stripe) {
			min-height: 128px; // icon + paddings

			@include media-breakpoint-up(lg) {
				min-height: 144px; // icon + paddings
			}
		}

		.feature-item-img {
			position: absolute;
		}

		.feature-item-body,
		.feature-item-footer {
			align-self: flex-end;
		}
	}

	&.size-lg {
		gap: $bu;
		border-radius: $bu*2;

		@include media-breakpoint-up(lg) {
			padding: $bu*2;
			gap: $bu*1.5;

			&:not(.centered) {
				padding-inline-start: $bu*1.5;
			}
		}

		&:not(.centered) {
			.feature-item-body,
			.feature-item-footer {
				width: calc(100% - (80px + 16px));//icon + gap

				@include media-breakpoint-up(lg) {
					width: calc(100% - (80px + 24px));//icon + gap
				}
			}

			.label {
				inset-inline-start: calc(24px + 80px + 16px);//outer padding + icon + gap

				@include media-breakpoint-up(lg) {
					inset-inline-start: calc(24px + 80px + 24px);//outer padding + icon + gap
				}
			}
		}
	}

	&.size-sm {
		gap: 12px;
		border-radius: $bu*1.5;

		@include media-breakpoint-up(lg) {
			gap: $bu;

			&:not(.centered) {
				padding: $bu*2 $bu*1.5;
			}
		}

		&:not(.centered) {
			.feature-item-body,
			.feature-item-footer {
				width: calc(100% - (80px + 12px));//icon + gap

				@include media-breakpoint-up(lg) {
					width: calc(100% - (80px + 16px));//icon + gap
				}
			}

			.label {
				inset-inline-start: calc(24px + 80px + 12px);//outer padding + icon + gap

				@include media-breakpoint-up(lg) {
					inset-inline-start: calc(24px + 80px + 16px);//outer padding + icon + gap
				}
			}
		}
	}

	.label {
		position: absolute;
		top: 0;
		transform: translateY(-50%);
	}

	.feature-item-body {
		position: relative;
		flex-grow: 2;
		display: flex;
		flex-direction: column;
		gap: $bu/2;
	}

	// Features in Stripe
	//-------------------
	&.in-stripe {
		padding: 0;
		flex-direction: row;
		margin: 0;

		@include media-breakpoint-down(sm) {
			width: 100%;
		}

		@include media-breakpoint-between(sm, lg) {
			width: calc(50% - #{$bu});
		}

		&,
		.feature-item-body {
			justify-content: center;
			align-self: auto;
		}

		.feature-item-img {
			position: relative;
			display: flex;
			align-items: center;
		}

		&.centered {
			@include media-breakpoint-up(lg) {
				flex-direction: column;
			}
		}

		&.size-lg {
			gap: $bu;

			@include media-breakpoint-up(lg) {
				max-width: 316px;
			}

			&:not(.centered) {
				padding-inline-start: 0; // overrides exception for desktop variant

				.feature-item-body {
					min-height: 80px;
				}

				.feature-item-body,
				.feature-item-footer {
					width: auto;
				}

				.feature-item-img img {
					height: 80px;
					width: auto;
				}
			}
		}

		&.size-sm {
			gap: $bu/2;

			@include media-breakpoint-up(lg) {
				max-width: 256px;
			}

			&:not(.centered) {
				padding: 0; // overrides exception for desktop variant

				.feature-item-body {
					min-height: 48px;
				}

				.feature-item-body,
				.feature-item-footer {
					width: auto;
				}

				.feature-item-img img {
					height: 48px;
					width: auto;
				}
			}
		}
	}
}