/*
Platform-switcher
=================
*/

// Show default variant for users without javascript
html.no-js {
	.js-android,
	.js-ios,
	.js-mac {
		&:not(.js-pc) {
			display: none !important;
			position: absolute !important;
			left: -9999px !important;
		}
	}
}
// Default non js

// PC
html.js-pc {
	.js-mac,
	.js-android,
	.js-ios {
		&:not(.js-pc) {
			display: none;
		}
	}

	.js-not-pc {
		display: none;
	}
}

// MAC
html.js-mac {
	.js-pc,
	.js-android,
	.js-ios {
		&:not(.js-mac) {
			display: none;
		}
	}

	.js-not-mac {
		display: none;
	}
}

// ANDROID
html.js-android {
	.js-pc,
	.js-mac,
	.js-ios {
		&:not(.js-android) {
			display: none;
		}
	}

	.js-not-android {
		display: none;
	}
}

// IOS
html.js-ios {
	.js-pc,
	.js-mac,
	.js-android {
		&:not(.js-ios) {
			display: none;
		}
	}

	.js-not-ios {
		display: none;
	}
}


// browser detection
/* [data-browser] {
	display: none;

	&:first-child {
		display: initial;
	}
}
 */