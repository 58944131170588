
/* Badge */
.ribbon {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: $blue-dark;
	position: relative;

	&.bg-gray03,
	&.bg-blue-bright,
	&.bg-red {
		color: $white;
	}

	&.ribbon-lg {
		padding: 10px 12px;
		//max-height: $bu*2.5;

		&:before,
		&:after {
			content: "";
			background-size: 12px 100%;
			background-repeat: no-repeat;
			position: absolute;
			top: 0;
			width: 12px;
			height: 100%;

			@include media-breakpoint-up(lg) {
				background-size: 14px 100%;
			}
		}

		&:before {
			left: -12px;
		}

		&:after {
			right: -12px;
			transform: rotate(180deg);
		}

		@include media-breakpoint-up(lg) {
			padding: 13px $bu;
			//max-height: $bu*3;

			&:before,
			&:after {
				width: 14px;
			}

			&:before {
				left: -14px;
			}

			&:after {
				right: -14px;
			}
		}
	}



	@each $color, $value in $theme-colors {
		$noHash: str-slice(#{$value},2);

		&.bg-#{$color}:before,
		&.bg-#{$color}:after
		 {
			background-image: url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' viewBox='0 0 14 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.53651 0H14V48H7.53651C1.85555 48 -1.45936 41.5897 1.82433 36.9539L7.72457 28.6241C9.68697 25.8537 9.68698 22.1463 7.72457 19.3759L1.82434 11.0461C-1.45936 6.41032 1.85555 0 7.53651 0Z' fill='%23#{$noHash}'/%3E%3C/svg%3E");
		}
	}

	&.bg-gold {
		&:before {
			background-image: url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' viewBox='0 0 14 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.53651 0H14V48H7.53651C1.85555 48 -1.45936 41.5897 1.82433 36.9539L7.72457 28.6241C9.68697 25.8537 9.68698 22.1463 7.72457 19.3759L1.82434 11.0461C-1.45936 6.41032 1.85555 0 7.53651 0Z' fill='%23FFBF00'/%3E%3C/svg%3E");
		}
		&:after {
			background-image: url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' viewBox='0 0 14 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.46349 0H0V48H6.46349C12.1445 48 15.4594 41.5897 12.1757 36.9539L6.27543 28.6241C4.31303 25.8537 4.31302 22.1463 6.27543 19.3759L12.1757 11.0461C15.4594 6.41032 12.1445 0 6.46349 0Z' fill='url(%23paint0_linear_821_122176)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_821_122176' x1='0' y1='0' x2='-4.94541e-06' y2='48' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FFBF01'/%3E%3Cstop offset='1' stop-color='%23FF7800'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
			transform: rotate(0);
		}
	}
}
