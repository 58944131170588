/******************
// METRICS COMPONENT
******************/

.metrics {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	width: 100%;
	position: relative;
	text-align: center;
	padding: $bu*2;
	margin: 0 auto;
	border-radius: $bu*2;

	// Default borders. May be overridden via custom classes
	border: 1px solid $gray07;

	@include media-breakpoint-up(sm) {
		max-width: 418px;
	}

	@include media-breakpoint-up(md) {
		max-width: 448px;
	}

	@include media-breakpoint-up(lg) {
		padding: $bu*1.5 $bu*1.5 $bu*2;
		max-width: none;
	}

	@include media-breakpoint-up(xl) {
		padding: $bu*2 $bu*2 $bu*2.5;
	}

	.metrics-img {
		height: $bu*5;
		margin-bottom: $bu/2;
	}

	.metrics-body {
		position: relative;
		flex-grow: 2;
		display: flex;
		flex-direction: column;
		gap: 12px;

		@include media-breakpoint-up(lg) {
			gap: $bu;
		}

		.tagline-lg {
			color: $blue-bright
		}
	}

	.metrics-footer {
		padding-top: 12px;

		@include media-breakpoint-up(lg) {
			padding-top: $bu;
		}
	}
}