#e-capture-btn {
	.btn {
		position: fixed;
		top: auto;
		right: $bu*1.25;
		bottom: $bu;
		z-index: 999; // lower than 1000 so its inder download popup, region selector and overlay

		@include media-breakpoint-up(lg) {
			right: $bu*2;
			bottom: $bu*3;
		}

		&:before {
			transition: all ease-in-out .2s;
		}
	}

	.outline {
		padding: 0 $bu;

		@include media-breakpoint-down(lg) {
			padding: 0 12px;
		}

		span {
			display: none;
			opacity: 0;
		}

		&:not(:disabled):not(.disabled) {
			&:hover,
			&:active {
				padding: 0 $bu*2;
				color: $blue-dark;
				box-shadow: none;

				span {
					display: block;
					opacity: 1;
				}

				.btn-icon {
					filter: $filter-blue-dark;
				}

				&:before {
					height: 100%;
					width: 100%;
					border-radius: 60px;
					background-color: $white;
				}
			}
		}
	}
}

#email-capture {
	.modal-content {
		text-align: start;

		.headline {
			margin-bottom: $bu;
		}

		.subheadline {
			margin-bottom: $bu*2;
		}

		.modal-body {
			padding-left: 7.5rem;

			&:before {
				content: url("../../i/v3/components/icons/feature-icons/48x48/contact.svg");
				background-repeat: no-repeat;
				position: absolute;
				left: $bu*3;
			}
		}

		.form-container {
			display: flex;
			flex-direction: column;
			gap: $bu*2;

			.form-text {
				display: none;
			}

			.form-control {
				width: 100%;
			}
			
			#frm-captcha {
				@extend .form-control;
				width: calc(100% - 120px);
				flex-grow: 2;
				order: -1;
			}

			// submit
			input.button {
				height: 56px;
				padding: 0 28px;
				background: $blue-bright;
				box-shadow: 0px 16px 32px -16px rgba($blue-dark, 0.32), 0px 8px 32px -16px rgba(0, 112, 246, 0.32);
				border-radius: 60px;
				font-weight: 750;
				color: $white;
				border: none;
				font-size: $bu*.875;
				line-height: $bu;
				letter-spacing: .8px;
			
				@include media-breakpoint-up(lg) {
					font-size: $bu;
					line-height: $bu*1.25;
					letter-spacing: 1px;
					padding: 0 20px;
				}
			}

			.captcha {
				display: flex;
				flex-direction: row;

				.form-control-wrapper {
					flex-direction: row;
					flex-wrap: wrap;

					img {
						width: 120px;
						height: 42px;
					}

					br {
						display: none;
					}
				}
			}
		}

		#thank-page {
			#email-placeholder {
				font-weight: bold;
				display: block;
				margin-bottom: 32px;
			}
		}
	}
}
