.productbox.productbox-compact {
    padding: 0;
    gap: 0;
    border: 1px solid $orange-light;
    background-color: $white;

    @include media-breakpoint-up(xs) {
        border-radius: $bu*1.5;
    }

    @include media-breakpoint-up(lg) {
        flex-direction: row;
        border-radius: $bu*2.5;
    }

    .productbox-prices {
        height: 100%;
        justify-content: center;

        @include media-breakpoint-up(lg) {
            background-color: $orange-off-white;

            .productbox-pricebox.collapse:not(.show) {
				display: none;
			}
        }

        .collapse-btn {
            margin-top: $bu*.5;

            @include media-breakpoint-up(md) {
                display: none;
            }
        }

        .pricing-desktop, 
        .pricing-mobile {
            @include media-breakpoint-up(xs) {
                margin-top: $bu*1.5;
            }
        }

        .pricing-desktop {
            height: 100%;
            background-color: $orange-off-white;

            @include media-breakpoint-up(lg) {
                margin-top: $bu*2.5;
            }
        }
    }
    
    .product-info {
        
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(xs) {
            padding: $bu*1.5;
        }

        @include media-breakpoint-up(lg) {
            padding: $bu*2.5 $bu*2 $bu*2.5 $bu*2.5;
            height: 100%;
        }

        
        .list-item {
            @include media-breakpoint-up(lg) {
                margin-top: $bu*.5; 
            }
            
            @include media-breakpoint-down(md) {
                height: 0;
                opacity: 0;
                overflow: hidden; 
                transition: height 1s ease-in, opacity .1s ease-in;
        
                &.show {
                    height: auto; 
                    opacity: 1;
                    margin-top: $bu*.5;
                }
            } 
        }

        .product-name {
            @include media-breakpoint-up(xs) {
                margin-bottom: $bu*.75;
            }
        
            @include media-breakpoint-up(lg) {
                margin-bottom: $bu*1;
            }
        }
        
        .platform-icons {
            display: flex;
            gap: .5rem;
            margin-top: $bu*.75;

            @include media-breakpoint-up(lg) {
                margin-top: $bu*1;
            }
        }

        .link {
            margin-top: $bu*.75;

            @include media-breakpoint-up(lg) {
                margin-top: $bu*1;
            }
        }
    }

    .pricebox {
        background-color: $orange-off-white;

        [abox],
        .box-container {
            border: none;
            background-color: transparent;
        }

        .box-container {
            &.boxcount-1 {
                @include media-breakpoint-up(xs) {
                    .box-toggler {
                        margin: 0;
                    }
                }
                @include media-breakpoint-up(lg) {
                    grid-template-columns: 280px;
                    -ms-grid-columns: 256px; //IE
                }

                @include media-breakpoint-up(xl) {
                    grid-template-columns: 256px;
                    -ms-grid-columns: 256px; //IE
                }

                @include media-breakpoint-up(xxl) {
                    grid-template-columns: 280px;
                    -ms-grid-columns: 280px; //IE
                }
            }

            @include media-breakpoint-down(lg) {
                &.show-toggler {
                    .box-guts[set="1"] .box-top {
                        margin-top: 0;
                        padding-top: $bu*1.5;
                    }
                }
            }
        }
    }

    
    &.hide-toggler {
        @include media-breakpoint-down(lg) {
            .box-toggler {
                display: none;
            }

            .productbox-pricebox .box-container.show-toggler {
                .box-guts[set="1"] .box-top {
                    margin-top: 0;
                    padding-top: $bu*1.5;
                }
            }
    
            .pricing-mobile {
                margin-top: 0;
            }
        }
    }

    // productbox without dual pricing
    &:not(.dual-pricing) {
        .pricing-desktop {
            display: none;
        }

        @include media-breakpoint-up(lg) {
            .productbox-prices {
                justify-content: flex-start;

                .productbox-pricebox.collapse:not(.show) {
                    display: block;
                }
                .pricing-mobile {
                    margin-top: 0;

                    .box-container.show-toggler {
                        margin-top: $bu*2.5;
                    }

                    .box-container:not(.show-toggler) {
                        margin-top: $bu*.5;
                    }                    
                }
            }
        }

        
        @include media-breakpoint-down(lg) {
            .productbox-prices {
                .pricing-mobile.no-toggler {
                    margin-top: 0;
                }
            }
        }
    }
}