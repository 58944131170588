.social-icons {
	display: inline-flex;
	align-items: center;
	gap: $bu*1.5;

	.icon {
		filter: $filter-blue-dark;

		.inverse & {
			filter: $filter-gray07;
		}

		&:hover {
			filter: $filter-blue-light;

			.inverse & {
				filter: $filter-white;
			}
		}
	}
}
