.table {
	display: grid;
	margin: 0 auto;
	position: relative;

	// widths of row headers
	$rowWidth2: 38.29787%;
	$rowWidth3: 31.7647%;
	$rowWidth45: 23.2353%;


	// STRUCTURE DESCRIPTION:
	// - tr: any row in the table
	// -- header-tr: topmost table row (Table Header)
	// -- top-price-tr: top pricing boxes (Top Price box row)
	// -- content-tr: ordinary row with informations (Content Row)
	// -- bottom-price-tr: bottom pricing boxes (Bottom Price box row)


	@include media-breakpoint-up(lg) {
		// Columns border
		&.cols-border:before {
			content: '';
			display: block;
			position: absolute;
			z-index: 4;
			border: 1px solid $gray07;
			pointer-events: none;
			inset-inline-end: 0;
			height: 100%;
			width: 100%;
		}

		// Higlight
		&[class*='highlight-'] {
			&:after {
				content: '';
				display: block;
				position: absolute;
				z-index: 4;
				border: 2px solid $orange-bright;
				pointer-events: none;
				height: 100%;
			}
		}

		// highlight frame
		@for $h from 1 through 5 {
			&.highlight-#{$h} {
				@for $c from 2 through 5 {
					&.columns-#{$c} {
						// highlight frame position on tables without row headers
						&:after {
							width: calc(100%/#{$c});
							inset-inline-start: calc(calc(100%/#{$c}) * calc(#{$h} - 1));
						}

						// highlight frame position on tables with row headers
						&:not(.no-row-headers):after {
							inset-inline-start: auto;

							@if $c == 2 { // for 2 columns
								width: calc(calc(100% - #{$rowWidth2}) / #{$c});
								inset-inline-end: calc(calc(calc(100% - #{$rowWidth2}) / #{$c}) * calc(#{$c} - #{$h}));
							}
							@if $c == 3 { // for 3 columns
								width: calc(calc(100% - #{$rowWidth3}) / #{$c});
								inset-inline-end: calc(calc(calc(100% - #{$rowWidth3}) / #{$c}) * calc(#{$c} - #{$h}));
							}
							@if $c > 3 { // for 4 and 5 columns
								width: calc(calc(100% - #{$rowWidth45}) / #{$c});
								inset-inline-end: calc(calc(calc(100% - #{$rowWidth45}) / #{$c}) * calc(#{$c} - #{$h}));
							}
						}
					}
				}
			}
		}
	}


	// Grid based on number of columns
	@for $i from 2 through 5 {
		&.columns-#{$i} {
			&,
			.tr {
				@if $i < 4 { // for 2 and 3 columns
					grid-template-columns: repeat($i, 1fr);
				}
				@if $i == 4 { // for 4 and 5 columns
					grid-template-columns: repeat($i, 25%);
				}
				@if $i == 5 { // for 4 and 5 columns
					grid-template-columns: repeat($i, 20%);
				}
			}

			@include media-breakpoint-up(lg) {
				&:not(.no-row-headers) {
					&,
					.tr {
						@if $i == 2 { // for 2 columns
							grid-template-columns: $rowWidth2 repeat($i, 1fr);
						}
						@if $i == 3 { // for 3 columns
							grid-template-columns: $rowWidth3 repeat($i, 1fr);
						}
						@if $i > 3 { // for 4 and 5 columns
							grid-template-columns: $rowWidth45 repeat($i, 1fr);
						}
					}

					// Columns border
					&.cols-border:before {
						@if $i == 2 { // for 2 columns
							width: calc(100% - #{$rowWidth2});
						}
						@if $i == 3 { // for 3 columns
							width: calc(100% - #{$rowWidth3});
						}
						@if $i > 3 { // for 4 and 5 columns
							width: calc(100% - #{$rowWidth45});
						}
					}
				}
			}
		}
	}

	// Fixed text breaks for JP Store Badge buttons as its too long
	.mod-ja-jp & {
		.store-badge-btn span {
			@include media-breakpoint-down(sm) {
				line-height: 12px;
				br {display: none;}
			}

			@include media-breakpoint-only(lg) {
				line-height: 14px;
				br {display: none;}
			}
		}
	}

	// Grid for 2 columns without priceboxes - row headers are in exact size
	&.columns-2.no-pricebox {
		@include media-breakpoint-up(lg) {
			&:not(.no-row-headers) {
				&,
				.tr {
					grid-template-columns: 316px repeat(2, 1fr);
				}

				// Columns border
				&.cols-border:before {
					width: calc(100% - 316px);
				}
			}

			@for $h from 1 through 2 {
				&.highlight-#{$h} {
					&:not(.no-row-headers):after {
						width: calc(calc(100% - 316px) / 2);
						inset-inline-end: calc(calc(calc(100% - 316px) / 2) * calc(2 - #{$h}));
					}
				}
			}
		}

		@include media-breakpoint-up(xl2) {
			&:not(.no-row-headers) {
				&,
				.tr {
					grid-template-columns: 432px repeat(2, 1fr);
				}

				// Columns border
				&.cols-border:before {
					width: calc(100% - 432px);
				}
			}

			@for $h from 1 through 2 {
				&.highlight-#{$h} {
					&.columns-2 {
						&:not(.no-row-headers):after {
							width: calc(calc(100% - 432px) / 2);
							inset-inline-end: calc(calc(calc(100% - 432px) / 2) * calc(2 - #{$h}));
						}
					}
				}
			}
		}
	}




	.tr {
		grid-column: 1 / last-line; // rows stretch across whole main table
		display: grid;
		background-color: $white;

		.row-header,
		.cell {
			display: flex;
			flex-direction: column;
			justify-content: center;
			background-color: $white;
		}

		.cell {
			text-align: center;
			align-items: center;

			@extend .body-4;
		}
	}


	// Top border/shadow on first content line
	.content-tr:first-child,
	.table-header-tr + .content-tr,
	.top-price-tr + .content-tr {
		.row-header,
		.cell {
			@include media-breakpoint-up(lg) {
				box-shadow: inset 0 -1px $gray07, inset 0 1px $gray07;

				&[class*='bg-orange-'] {
					box-shadow: inset 0 -1px $orange-pale, inset 0 1px $orange-pale;
				}
			}
		}
	}


	// TABLE HEADER ROW
	.table-header-tr {
		&.full {
			.row-header {
				padding-block: 12px;
				padding-inline: $bu/2 $bu;
			}

			.cell {
				padding: $bu*1.5;
				justify-content: flex-start;
			}
		}

		&.simple {
			.row-header {
				padding-block: $bu;
				padding-inline: $bu/2 $bu*2;
			}

			.cell {
				padding: $bu $bu*1.5;
			}

			.header-name {
				font-size: $h6-font-size;
				line-height: $h6-line-height;
				font-weight: 750;
			}

			@include media-breakpoint-up(lg) {
				.row-header,
				.cell {
					gap: $bu/2;
				}
			}
		}

		// Button that on click scrolls to specific pricebox
		.scroll-button {
			margin-top: $bu;
			display: flex;
			justify-content: center;
			align-items: flex-end;
			flex: 1;

			@include media-breakpoint-up(lg) {
				display: none;
			}
		}
	}

	// INFLUENCER - Dropdown select on top of the table influencing table content
	&.with-influencer {
		&:before,
		&:after {
			bottom: 0;
			height: calc(100% - 20px);
		}

		// z-index reorganization
		&:after { z-index: 1;}
		&.sticky .tr.bottom-price-tr { z-index: 3; }
		&.sticky .tr.table-header-tr:not(.unsticked) { z-index: 2; }
		&:before { z-index: 1; }
		// /z-index reorganization


		.influencer {
			display: flex;
			flex-direction: column;
			gap: $bu/4;
			position: absolute;
			z-index: 2;
			top: 0;
			max-width: 100%;

			@include media-breakpoint-up(lg) {
				.influencer-hint {
					display: none;
				}
			}
		}

		.table-header-tr.full {
			.cell {
				padding-top: $bu*4;
				position: relative;

				// product icon are hidden on small mobile displays to make more space
				@include media-breakpoint-down(md) {
					.header-product-icon {
						display: none;
					}
				}

				// Top Row borders again since Z-indexes are different with influencer
				@include media-breakpoint-up(lg) {
					&:before,
					&:after {
						content: '';
						position: absolute;
						display: block;
						background-color: $gray07;
					}

					&:before {
						width: 100%;
						height: 1px;
						top: 20px;
					}

					&:after {
						width: 1px;
						height: calc(100% - 20px);
						bottom: 0;
						inset-inline-start: 0;
					}

					& ~ .cell:not(:last-of-type):after {
						content: none;
					}

					&:last-of-type {
						&:after {
							inset-inline-start: auto;
							inset-inline-end: 0;
						}
					}
				}
			}

			.col-header .header-content {
				.header-subname.hidden {
					display: none;
				}
			}
		}

		.bottom-price-tr .cell {
			position: relative;

			// Bottom Row borders again since Z-indexes are different with influencer
			@include media-breakpoint-up(lg) {
				&:after {
					content: '';
					position: absolute;
					display: block;
					z-index: 1;
					width: 100%;
					height: 100%;
					border: 1px solid $gray07;
					border-top: none;
					pointer-events: none;
				}
			}
		}

		@include media-breakpoint-down(lg) {
			.influencer {
				left: 50%;
				transform: translateX(-50%);
				top: 43px; // height of table headline (31px) + 12px top offset
			}

			.table-header-tr.full .cell {
				padding-top: $bu*5.5;
				position: static;
			}
		}

		// fixed highlight frame
		@include media-breakpoint-up(lg) {
			// Influencer dropdown width
			@for $c from 2 through 5 {
				&.columns-#{$c} .influencer {
					@if $c < 4 { // for 2 and 3 columns
						max-width: calc(100% - 48px);
					}
					@if $c > 3 { // for 4 and 5 columns
						max-width: calc(100% - 32px);
					}
				}
			}

			@for $h from 1 through 5 {
				&.highlight-#{$h} {
					.table-header-tr.full .cell:nth-of-type(#{$h + 1}) {
						&:before {
							display: none;
						}

						&:after {
							content: '';
							position: absolute;
							display: block;
							z-index: 1;
							background-color: transparent;
							pointer-events: none;
							width: 100%;
							height: calc(100% - 20px);
							border: 2px solid $orange-bright;
							border-bottom: none;
							top: 20px;
						}
					}

					@for $c from 2 through 5 {
						&.columns-#{$c} {
							.bottom-price-tr { position: relative;}

							// highlight frame position on tables without row headers
							.bottom-price-tr:after {
								display: block;
								content: '';
								position: absolute;
								z-index: 1;
								pointer-events: none;
								border: 2px solid $orange-bright;
								border-top: none;
								height: 100%;
								width: calc(100%/#{$c});
								inset-inline-start: calc(calc(100%/#{$c}) * calc(#{$h} - 1));
							}
	
							// highlight frame position on tables with row headers
							&:not(.no-row-headers) .bottom-price-tr:after {
								inset-inline-start: auto;
	
								@if $c == 2 { // for 2 columns
									width: calc(calc(100% - #{$rowWidth2}) / #{$c});
									inset-inline-end: calc(calc(calc(100% - #{$rowWidth2}) / #{$c}) * calc(#{$c} - #{$h}));
								}
								@if $c == 3 { // for 3 columns
									width: calc(calc(100% - #{$rowWidth3}) / #{$c});
									inset-inline-end: calc(calc(calc(100% - #{$rowWidth3}) / #{$c}) * calc(#{$c} - #{$h}));
								}
								@if $c > 3 { // for 4 and 5 columns
									width: calc(calc(100% - #{$rowWidth45}) / #{$c});
									inset-inline-end: calc(calc(calc(100% - #{$rowWidth45}) / #{$c}) * calc(#{$c} - #{$h}));
								}
							}
						}
					}
				}
			}
		}
	}
	// / INFLUENCER



	// PRICEBOXES
	.top-price-tr,
	.bottom-price-tr {
		@for $c from 1 through 5 {
			.cell.span-#{$c} {
				grid-column: span $c; // span cell so it can contain pricebox
			}
		}

		.cell {
			.pricebox {
				width: 100%;
				padding: 0;

				.box-container {
					border: 0;
					width: 100%;
					padding: 0;

					&.multibox [abox] {
						border-color: transparent !important;
					}

					.box-top,
					.box-bottom {
						border-radius: 0;
					}

					@include media-breakpoint-between(lg,xl) {
						&.boxcount-4,
						&.boxcount-5 { // xxx.xx price format needs more space in 4 and 5 columns
							.box-price {
								padding-inline: 0;
							}
						}

						&.boxcount-5 { // CTA needs more space in 5 columns
							.box-cta,
							.box-cta-note {
								padding-inline: $bu/4;
								min-width: 100%;
								//overflow-x: auto;
							}
						}
					}

					.box-price {
						.price-header.h3 {
							@include media-breakpoint-down(lg) {
								font-size: $h3-font-size-m;
								line-height: $h3-line-height-m;
							}

							// Word 'Free' is sometimes translated too long, thus smaller size and word break
							@include media-breakpoint-only(lg) {
								.mod-sv-se &,
								.mod-uk-ua &,
								[lang*='ru-'] & {
									word-break: break-word;
									font-size: $h3-font-size-m;
									line-height: $h3-line-height-m;
								}
							}
						}
					}
				}
			}
		}
	}


	// TOP PRICEBOX ROW
	.top-price-tr {
		@include media-breakpoint-down(lg) {
			display: none;
		}

		.row-header:not(.empty) {
			padding-block: $bu*2;
			padding-inline: 0 $bu;
		}

		.cell {
			.pricebox .box-container {
				.box-top {
					display: none;
				}

				.box-toggler {
					display: none;
				}
			}
		}
	}


	// BOTTOM PRICEBOX ROW
	.bottom-price-tr {
		.row-header {
			display: flex;
			flex-direction: column;
			justify-content: start;
			align-items: center;
			gap: $bu;

			&:not(.empty) {
				padding: $bu*1.5 0 0;

				@include media-breakpoint-up(lg) {
					padding: $bu*2 0 0;
				}
			}

			.list-item {
				margin-bottom: $bu*1.5;
				align-self: flex-start;
			}

			@include media-breakpoint-up(lg) {
				align-items: start;

				.list-item {
					display: none;
				}
			}

			@include media-breakpoint-down(lg) {
				grid-row: 2;
			}
		}
	}

	// Not spanned price cells
	&.no-span {
		.bottom-price-tr {
			.cell {
				padding: $bu*2 $bu;

				@include media-breakpoint-up(lg) {
					padding: $bu*2.5 $bu*1.5;
				}
			}

			.btn {
				width: 100%;
				justify-content: center;
			}
		}
	}


	// CONTENT ROW
	.content-tr {
		.row-header,
		.cell {
			min-height: $bu*3.5;
			box-shadow: inset 0 -1px $gray07;

			&[class*='bg-orange-'] {
				box-shadow: inset 0 -1px $orange-pale;
			}
		}

		.row-header {
			padding: 12px 0;
		}

		.cell {
			align-items: center;
			gap: $bu/4;
			padding: $bu $bu/2;

			.icon.icon-cross-table {
				filter: $filter-gray07;
				color: $gray07;
			}

			//GLOWEB-9397
			.icon.icon-32.icon-check-oval-color {
				margin-top: -4px;
			}
		}
	}

	// STICKY HEADERS
	&.sticky {
		.tr.table-header-tr:not(.unsticked) {
			-webkit-sticky: sticky;
			position: sticky;
			top: 0;
			z-index: 2;

			.row-header.hidden {
				@include media-breakpoint-down(md) {
					//
				}
			}
		}

		.tr.bottom-price-tr {
			z-index: 3;

			/* Safari 11+ specific styles */
			@supports (-webkit-appearance: none) {
				transform: translate3d(0, 0, 0)
			}
		}
	}

	// MOBILE STYLES
	@include media-breakpoint-down(lg) {
		.row-header {
			// row header stretch across whole table on mobile
			grid-column: 1 / last-line;
		}

		.content-tr,
		.table-header-tr.simple {
			box-shadow: inset 0 -1px $gray07;

			.row-header,
			.cell {
				background-color: transparent !important;

				&,
				&[class*='bg-orange-'] {
					box-shadow: none;
				}
			}
		}


		// TABLE HEADER ROW
		.table-header-tr {
			.cell {
				justify-content: flex-start;
			}

			.row-header {
				text-align: center;
				align-content: center;
			}

			&.full {
				.row-header {
					padding: 0 $bu/2 $bu/4;
				}

				.cell {
					padding: $bu $bu/2;
				}
			}

			&.simple {
				.row-header {
					padding: 12px $bu/2 0;

					.h6,
					.header-name {
						font-size: $h5-font-size-m;
						line-height: $h5-line-height-m;
					}
				}

				.cell {
					padding: 12px $bu/2;
					gap: $bu/2;
					
					.header-name {
						font-size: $h6-font-size-m;
						line-height: $h6-line-height-m;
					}
				}
			}
		}

		&.columns-4 {
			.table-header-tr {
				.h6,
				.header-name {
					font-size: $h7-font-size-m;
					line-height: $h7-line-height-m;
				}
			}
		}

		&.columns-5 .table-header-tr {
			.cell {
				padding-inline: $bu/4;
			}

			&:not(.simple) {
				.header-name {
					font-size: $body-4-font-size-m;
					line-height: $body-4-line-height-m;
				}
			}
		}
		// /TABLE HEADER ROW


		// CONTENT ROW
		.content-tr {
			.cell {
				justify-content: flex-start;
				padding: 12px $bu/2 20px;
			}

			.row-header {
				padding: 20px $bu/2 $bu/4;
			}
		}
		// /CONTENT ROW

		// COLORS
		&.theme-m-orange {
			.tr.content-tr:nth-child(odd) {
				background-color: $orange-faint;
			}

			.pricebox {
				.box-container {
					&.multibox {
						.box-top:not([multibox=start]):before,
						.box-bottom[multibox=end]:before {
							background-color: $orange-pale;
						}
					}
				}
			}
		}

		&.theme-m-white,
		&.theme-m-orange {
			.tr.content-tr {
				box-shadow: inset 0 -1px $orange-pale;
			}

			.tr.table-header-tr {
				border-bottom: 1px solid $orange-pale;
			}
		}
		// /COLORS
	}
	// /MOBILE STYLES
}




// Body Row Header Component -----------------------------------------------------
.tr-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	// wrapper with icon
	.tr-header-icon-wrap {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: $bu/2;
	}

	// texts
	.tr-header-texts {
		display: flex;
		flex-direction: column;
		padding-inline-end: $bu;

		.head {
			font-weight: 700;
		}
	}

	// tooltip
	.cell-tooltip {
		@include sprite-icon("info", 16);
		cursor: pointer;
		outline: none;
		margin-block: 0;
		margin-inline: $bu/2 12px;
		flex-shrink: 0;

		&:hover,
		&:focus {
			@include sprite-icon("hover-info", 16);
		}
	}

	// Mobile Grid
	@include media-breakpoint-down(lg) {
		display: block;
		text-align: center;
		line-height: 0; // remove default body line height

		.product-icon {
			display: none; // no product icon on mobile
		}

		.tr-header-icon-wrap,
		.tr-header-texts {
			display: contents;

			.text {
				display: inline-block; // so the tooltip icon is on one line with text
			}
		}

		.cell-tooltip {
			display: inline-flex;
			margin: 0;
			vertical-align: text-bottom;
		}
	}
}




// Column Header Component -----------------------------------------------------
// Clickable header
a.col-header {
	text-decoration: none;

	&:hover {
		.header-name {
			color: $blue-bright;
		}
		

		// Inner BUTTON states changes too
		.btn:not(:disabled):not(.disabled) {
			.btn-icon-hover { display: inline; }
			.btn-icon-normal { display: none; }
			
			&.link {
				text-decoration: none;
			
				.inverse & {
					color: $white;
					.btn-icon { filter: $filter-orange-bright; }
				}
		
				&,
				.light & {
					color: $blue-bright;
					.btn-icon { filter: $filter-blue-bright; }
				}
			}
		}
	}
}

.col-header {
	display: flex;
	gap: $bu/2 $bu;

	.header-product-icon {
		display: flex;
		align-items: start;
	}

	.header-content {
		display: flex;
		flex-direction: column;
		text-align: start;

		.header-name + .header-note {
			padding-top: 2px;
		}

		.header-name {
			@extend .h6;
		}

		.header-subname {
			font-size: $body-4-font-size;
			line-height: $body-4-line-height;
			font-weight: 700;

			@include media-breakpoint-down(lg) {
				font-size: $body-5-font-size-m;
				line-height: normal;
			}
		}

		.platforms {
			padding: $bu/2 0;
		}

		// button link needs to have top spacing 16px so we need to expand the touch area
		.btn.link {
			margin-top: -$bu/4;
		}
	}

	&.centered {
		flex-direction: column;
		align-items: center;

		.header-content {
			text-align: center;

			.platforms {
				justify-content: center;
			}

			.btn.link {
				margin-inline: auto;
			}
		}
	}

	@include media-breakpoint-down(lg) {
		flex-direction: column;
		align-items: center;

		.header-content {
			text-align: center;

			.header-note,
			.platforms,
			.btn.link {
				display: none;
			}
		}
	}
}
