
/* MEDIA object
===============
*/
// adjacent media sections
.media {
	.container {
		padding-top: $bu*5;
		padding-bottom: $bu*5;

		&.pack-middle {
			padding-top: $bu*2.5;
			padding-bottom: $bu*2.5;

			@include media-breakpoint-up(lg) {
				padding-top: 0;
				padding-bottom: 0;
			}
		}

		&.pack-top {
			padding-bottom: $bu*2.5;

			@include media-breakpoint-up(lg) {
				padding-bottom: 0;
			}
		}
		
		&.pack-bottom {
			padding-top: $bu*2.5;

			@include media-breakpoint-up(lg) {
				padding-top: 0;
			}
		}
	}
}
// /adjacent media sections


.media {
	.container {
		position: relative;
		z-index: 1;

		.media-row {
			flex-direction: column;
			align-items: center;
		}
	}

	@include media-breakpoint-up(lg) {
		&.img-position-left .media-row {
			flex-direction: row-reverse;
		}
		
		&.img-position-right .media-row {
			flex-direction: row;
		}
	}

	// Media play button over image
	.img-desktop-wrap,
	.img-mobile-wrap {
		text-align: center;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;

		@include media-breakpoint-up(lg) {
			padding-top: $bu*2;
			padding-bottom: $bu*2;
		}

		.play-icon-wrap {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: scale(1) translate(-50%, -50%);
			animation: .3s transform ease;
			background-color: $white;
			border-radius: 50%;
			box-shadow: 0 0 0 12px rgba($blue-dark, .32);

			&:hover { transform: scale(1.1) translate(-45%, -45%); }
			.icon { margin: 12px; }

			@include media-breakpoint-up(lg) {
				box-shadow: 0 0 0 18px rgba($blue-dark, .32);

				.icon { margin: $bu; }
			}
		}
	}

	&[class*='splat-'] {
		.img-desktop-wrap,
		.img-mobile-wrap {
			min-height: 278px;

			&:before {
				content: '';
				display: block;
				position: absolute;
				z-index: -1;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				background-image: url('../../i/v3/components/illustrations/splat7s.svg');
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
			}
		}

		.img-mobile-wrap {
			&:before {
				width: 320px;
				height: 278px;
			}
		}

		&[class*='bg-orange-'] {
			.img-desktop-wrap,
			.img-mobile-wrap {	
				&:before {
					filter: $filter-orange-off-white;
				}
			}
		}

		@include media-breakpoint-up(lg) {
			&.splat-40 .img-desktop-wrap {
				min-height: 470px;

				&:before {
					width: 464px;
					height: 406px;
				}
			}

			&.splat-48 .img-desktop-wrap {
				min-height: 544px;
				
				&:before {
					background-image: url('../../i/v3/components/illustrations/splat7l.svg');
					width: 548px;
					height: 480px;
				}
			}
		}
	}

	// Image sizes
	.img-desktop,
	.img-mobile {
		max-width: 100%;
		height: auto;
		image-rendering: -moz-crisp-edges;
		image-rendering: -o-crisp-edges;
		image-rendering: -webkit-optimize-contrast;
		image-rendering: crisp-edges;
		-ms-interpolation-mode: nearest-neighbor;

		&[class*='rounded-'] {
			border-radius: $bu*2;

			@include media-breakpoint-up(lg) {
				&.rounded-48 {
					border-radius: $bu*3;
				}
				
				&.rounded-40 {
					border-radius: $bu*2.5;
				}
			}
		}
	}
	
	@include media-breakpoint-down(lg) {
		.img-desktop-wrap { // this container is visible on mobile if image is placed on bottom
			margin-top: $bu*2;
		}

		.img-mobile-wrap {
			margin: $bu*2 auto 20px;
		}
	}

	.media-text {
		display: flex;
		flex-direction: column;
		padding-top: 0;
		padding-bottom: 0;

		@include media-breakpoint-up(lg) {
			padding-top: $bu*2;
			padding-bottom: $bu*2;
		}
	}

	.media-body {
		.h2,
		h2,
		.h3,
		h3 {
			padding-top: $bu*1.5;

			@include media-breakpoint-up(lg) {
				padding-top: $bu*2;
			}
		}

		.body-2 {
			padding-top: 12px;

			@include media-breakpoint-up(lg) {
				padding-top: $bu;
			}
		}

		.list-item {
			margin-top: $bu;

			@include media-breakpoint-up(lg) {
				margin-top: $bu*1.5;
			}
		}
	
		.buttons {
			display: flex;
			flex-wrap: wrap;
			gap: $bu;
			justify-content: center;
	
			@include media-breakpoint-up(lg) {
				gap: $bu*1.5;
				justify-content: flex-start;
			}
		}

		.buttons,
		.techcorner,
		.media-asterix {
			margin-top: $bu*2;

			@include media-breakpoint-up(lg) {
				margin-top: $bu*2.5;
			}
		}
		
		.techcorner {
			@include media-breakpoint-up(lg) {
				margin-bottom: $bu*2;
			}
		}

		// button link needs to have top spacing 40px so we need to expand the touch area
		&>.btn.link {
			margin-top: 20px;

			@include media-breakpoint-up(lg) {
				margin-top: 28px;
			}
		}
	}

	.media-title {
		gap: 12px;

		@include media-breakpoint-up(lg) {
			gap: $bu;
		}

		.buttons,
		.techcorner,
		.media-asterix {
			margin-top: $bu*2;

			@include media-breakpoint-up(lg) {
				margin-top: $bu*2.5;
			}
		}
	}

	.media-title,
	.media-body {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;

		@include media-breakpoint-up(lg) {
			align-items: flex-start;
			text-align: initial;
		}
	}

	// preventively styling platforms for cases where these are not inserted via cmp.platforms
	.platforms {
		display: flex;
		margin-top: 12px;
		gap: $bu/2;

		@include media-breakpoint-up(lg) {
			margin-top: $bu;
			gap: $bu;
		}
	}

	&.bg-size-container {
		.container {
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;

			@include media-breakpoint-up(sm) {
				border-radius: $bu*3;
			}
		}
	}

	.media-custom {
		width: 100%;
	}

	
	//BG position
	//------------------
	$bg-offset-lg: calc(
		((100% - #{$container-lg}) / 2) + ((#{$container-lg} / 4) + #{$bu})
	);
	$bg-offset-xl: calc(
		((100% - #{$container-xl}) / 2) +
		((#{$container-xl} / 4) + #{$bu})
	);
	$bg-offset-xl2: calc(
		((100% - #{$container-xl2}) / 2) +
		((#{$container-xl2} / 4) + #{$bu})
	);
	$bg-offset-xxl: calc(
		((100% - #{$container-xxl}) / 2) + ((#{$container-xxl} / 4) + #{$bu})
	);
	
	
	&.bg-size-partial {
		position: relative;
		overflow-x: hidden;
		background-color: transparent !important;

		.partial-cover{
			position: absolute;
			height: 100%;
			width: 100vw;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			top: 0;
			
			@include media-breakpoint-up(lg) { width: calc(100vw - #{$bg-offset-lg}); }
			@include media-breakpoint-up(xl) { width: calc(100vw - #{$bg-offset-xl}); }
			@include media-breakpoint-up(xl2) { width: calc(100vw - #{$bg-offset-xl2}); }	
			@include media-breakpoint-up(xxl) { width: calc(100vw - #{$bg-offset-xxl}); }
		}

		&.img-position-right .partial-cover {
			left: 0;
		}
	
		&.img-position-left .partial-cover {
			right: 0;
		}

		[dir='rtl'] & {
			&.img-position-right .partial-cover {
				left: auto;
				right: 0;
			}
		
			&.img-position-left .partial-cover {
				right: auto;
				left: 0;
			}
		}
	}
}



