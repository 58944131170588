@import "./components/typo";
@import "./components/colors";

@import "./components/accordion";
@import "./components/alert";
@import "./components/article";
@import "./components/award-badges";
@import "./components/award";
@import "./components/bft";
@import "./components/browser-switcher";
@import "./components/button-circle";
@import "./components/button";
@import "./components/carousel";
@import "./components/collapse";
@import "./components/compare-box";
@import "./components/countdown";
@import "./components/cross-sell-modal";
@import "./components/dtyp";
@import "./components/email-capture";
@import "./components/feature-item";
@import "./components/forms";
@import "./components/hero";
@import "./components/hoverable-wrap";
@import "./components/chrome-banner";
@import "./components/icon";
@import "./components/label";
@import "./components/list-item";
@import "./components/media";
@import "./components/message-bar";
@import "./components/metrics";
@import "./components/modal";
@import "./components/money-back";
@import "./components/platform-switcher";
@import "./components/platforms";
@import "./components/pricebox";
@import "./components/pricing";
@import "./components/process";
@import "./components/productbox";
@import "./components/product-card";
@import "./components/product-icon";
@import "./components/product-info";
@import "./components/rating";
@import "./components/ribbon";
@import "./components/signpost";
@import "./components/social-icons";
@import "./components/sticky-bar";
@import "./components/stripe";
@import "./components/system-requirements";
@import "./components/table";
@import "./components/table-basic";
@import "./components/tabs";
@import "./components/techcorner";
@import "./components/testimonial";
@import "./components/toggler";
@import "./components/tooltip";
@import "./components/trustpilot";
@import "./components/unsupported-browser-message";
@import "./components/usp-pricebox";
@import "./components/pricebox-compact";