/******************
// AWARD COMPONENT
******************/

.award {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: $bu;
	text-align: center;
	height: 100%;
	position: relative;
	width: 100%;
	margin: 0 auto;
	padding: $bu*2 $bu*1.5;
	background: $white;
	border-radius: $bu*1.5;

	// Default borders. May be overridden via custom classes
	border: 1px solid $gray07;

	.award-body {
		position: relative;
		flex-grow: 2;
		display: flex;
		flex-direction: column;
		gap: $bu/4;

		.rating .rating-values {
			align-items: center;
		}
	}

	@include media-breakpoint-up(sm) {
		max-width: 418px;
	}

	@include media-breakpoint-up(md) {
		max-width: 448px;
	}


	@include media-breakpoint-up(lg) {
		gap: $bu*1.5;
		max-width: none;
		border-radius: $bu*2;

		.award-body {
			gap: $bu/2;
		}
	}

	@include media-breakpoint-up(xl) {
		padding: $bu*2.5 $bu*2;
	}
}
