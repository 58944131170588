.bg-gold {
	background: $gold;
}


/* FILTERS
================
*/
@each $color, $value in $filter-colors {
	&.filter-#{$color} {
		filter: $value;
	}
}

.no-filter { filter: none !important; }

