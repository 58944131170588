@import "_pricebox-grid.scss";

.pricebox {
	display: flex;
	justify-content: center;
	text-align: center;
	width: fit-content;
	margin: 0 auto;

	.box-container {
		display: grid;
		display: -ms-grid; //IE
		position: relative;
		background: none;
		margin: 0 auto;
		text-align: center;

		// THEMES
		&.theme-orange {
			.inverse & .form-select {
				@extend .gray;
			}

			.box-cta {
				@extend .light; // make sure that buttons have proper colors even if the pricebox is surrounded by inverse background
			}
		}

		&.theme-gray {
			[abox],
			.box-top,
			.box-bottom {
				border-color: $gray07;
			}
		}

		&.theme-blue {
			[abox],
			.box-top,
			.box-bottom {
				border-color: $blue-bright;
				border-width: 2px;
			}

			&.size-sm [abox],
			&.size-sm .box-toggler {
				padding-inline: 23px;

				@include media-breakpoint-up (lg) {
					padding-inline: 31px;
				}
			}

			&.size-lg [abox],
			&.size-lg .box-toggler {
				padding-inline: 31px;

				@include media-breakpoint-up (lg) {
					padding-inline: 39px;
				}
			}
		}

		// padding between standalone boxes on mobile
		@include media-breakpoint-down(lg) {
			padding-bottom: $bu;
		}

		.box-guts, // vue template need only one root element
		.box-table-section-right, // slot for layout displayed on table at mobile resolutions
		.box-table-section-left { // slot for layout displayed on table at mobile resolutions
			display: contents;
		}

		&.show-toggler {
			// on desktop with toggler Container has padding plus half of toggler
			@include media-breakpoint-up(lg) {
				&.size-lg:not(.multibox) {padding-top: $bu*1.5;}
			}

			@include media-breakpoint-up(md) {
				&:not(.multibox) {padding-top: 20px;}
				&.multibox {margin-top: 20px;}
			}

			.box-toggler {
				display: flex;
				justify-content: center;
				position: absolute;
				z-index: 3;
				left: 50%;
				top: 0;
				transform: translate(-50%, -50%);
				width: 100%;

				.toggler:not(.disabled) {
					width: 100%;

					.toggler-item {
						flex-grow: 1;
					}
				}

				.form-select {
					max-width: 100%;

					@include media-breakpoint-down(sm) {
						width: fit-content;
					}
				}
			}

			&.multibox:not(.boxcount-1) {
				// toggler on multibox with several boxes should be set to 'hug' on desktop
				.box-toggler {
					@include media-breakpoint-up(md) {
						width: auto;
					}
				}
			}

			&:not(.multibox) .box-toggler {
				// on mobile grid layout with toggler - only first top slot has padding plus half of toggler
				// - must be box-top from same set as toggler
				// - must be only first box-top from the set
				@for $d from 1 through 5 {
					&[set="#{$d}"] {
						@include media-breakpoint-down(md) {
							margin-top: 20px;
							~.box-guts[set="#{$d}"] {
								.box-top {
									margin-top: 20px; // half of the toggler
								}

								~.box-guts[set="#{$d}"] .box-top {
									margin-top: 0;
								}
							}
						}
					}
				}
			}

			&.multibox {
				@include media-breakpoint-down(md) {
					margin-top: 20px;
				}
			}

			&.size-lg .box-toggler {
				.form-select {
					width: 100%;
				}
			}
		}

		// standalone boxes has vertical gaps on mobile grid layout
		// the gap is on bottom slot, the last set is excluded
		@for $c from 2 through 5 {
			&:not(.multibox).boxcount-#{$c} .box-guts:not([set="#{$c}"]) .box-bottom {
				@include media-breakpoint-down(md) {
					margin-bottom: $bu;
				}
			}
		}



		// Slots styling based on GRID
		// Small box styles
		&.size-sm [abox],
		&.size-sm .box-toggler {
			padding-inline: $bu*1.5;

			@include media-breakpoint-up (lg) {
				padding-inline: $bu*2;
			}
		}

		&.size-sm {
			.box-top {
				padding-top: $bu*1.5;
				border-radius: $bu*1.5 $bu*1.5 0 0;
			}

			// top spacer is bigger with toggler or dropdown
			&.show-toggler:not(.multibox) .box-top {
				padding-top: 44px;
			}

			.box-bottom {
				padding-bottom: $bu*1.5;
				border-radius: 0 0 $bu*1.5 $bu*1.5;
			}

			.box-platforms {
				padding-bottom: 12px;
			}

			.box-instructions {
				padding-bottom: $bu;
			}

			.box-installments {
				font-size: $body-4-font-size-m;
				line-height: $body-4-line-height-m;
			}

			.box-price .pricing {
				margin-top: -2px;
			}

			.box-cta {
				padding-top: $bu;
			}

			@include media-breakpoint-up (lg) {
				&.show-toggler:not(.multibox) .box-top {
					padding-top: 52px;
				}

				.box-note-above-price,
				.box-annual {
					font-size: $body-5-font-size;
					line-height: $body-5-line-height;
				}
				
				.box-installments {
					font-size: $body-4-font-size;
					line-height: $body-4-line-height;
				}
		
				.box-annual:not(.empty) + .box-installments:not(.empty) {
					padding-top: 8px;
				}

				.box-price .pricing {
					margin-top: -4px;
				}

				.box-top {
					padding-top: $bu*2;
				}

				.box-bottom {
					padding-bottom: $bu*2;
				}

				.box-platforms {
					padding-bottom: $bu;
				}
			}
		}

		// Large box styles
		&.size-lg [abox],
		&.size-lg .box-toggler {
			padding-inline: $bu*2;

			@include media-breakpoint-up (lg) {
				padding-inline: $bu*2.5;
			}
		}

		&.size-lg {
			.box-top {
				padding-top: $bu*2;
				border-radius: $bu*2 $bu*2 0 0;
			}

			// top spacer is bigger with toggler or dropdown
			&.show-toggler .box-top {
				padding-top: 52px;
			}

			.box-bottom {
				padding-bottom: $bu*2;
				border-radius: 0 0 $bu*2 $bu*2;
			}

			.box-platforms {
				padding-bottom: 18px;
			}

			.box-instructions {
				padding-bottom: $bu*1.5;
			}

			.box-installments {
				font-size: $body-2-font-size-m;
				line-height: $body-2-line-height-m;
			}

			.box-price .pricing {
				margin-top: -6px;
			}

			.box-cta {
				padding-top: 18px;
			}

			@include media-breakpoint-up (lg) {
				&.show-toggler .box-top {
					padding-top: $bu*4;
				}

				.box-note-above-price,
				.box-annual {
					font-size: $body-4-font-size;
					line-height: $body-4-line-height;
				}

				.box-installments {
					font-size: $body-2-font-size;
					line-height: $body-2-line-height;
				}
		
				.box-annual:not(.empty) + .box-installments:not(.empty) {
					padding-top: 6px;
				}

				.box-price .pricing {
					margin-top: -8px;
				}

				.box-top {
					padding-top: $bu*2.5;
				}

				.box-bottom {
					padding-bottom: $bu*2.5;
				}

				.box-label {
					padding-bottom: 12px;
				}

				.box-platforms {
					padding-bottom: $bu*1.5;
					gap: 12px;

					.icon {
						scale: 1.5;
						margin: 4px;
					}
				}

				.box-cta {
					padding-top: $bu*1.5;
				}

				.box-cta-note {
					padding-top: $bu;
				}
			}
		}



		// SLOTS STYLES (Same for both sizes)
		// ----------------------------------------------------------------------------------
		[abox] {
			background-color: $white;
			border-inline: 1px solid $orange-light;
		}

		.empty {
			padding-block: 0 !important;
			line-height: 0;
		}

		.box-top {
			border-top: 1px solid $orange-light;
		}

		.box-bottom {
			border-bottom: 1px solid $orange-light;
		}

		.box-product,
		.box-product-icon {
			display: none; // visible only in table on mobile
			padding-bottom: $bu/2;
		}

		.box-headline {
			font-weight: 700;
			padding-bottom: 12px;
		}

		.box-platforms {
			align-items: center;
			display: flex;
			justify-content: center;
			gap: $bu/2;
		}

		.box-label {
			display: flex;
			justify-content: center;
			padding-bottom: $bu/2;
		}

		.box-annual {
			padding-top: 4px;

			s.price-old {
				color: $gray05;
			}
		}

		.box-note-above-price,
		.box-annual {
			font-size: $body-5-font-size-m;
			line-height: $body-5-line-height-m;
			font-weight: normal;
		}

		.box-installments {
			font-weight: 700;
		}
		
		.box-annual:not(.empty) + .box-installments:not(.empty) {
			padding-top: 4px;
		}

		.price-vuetag {
			// get rid of extra wrapping div added by vue to render html content
			display: contents;
		}

		.box-price {
			display: flex;
			justify-content: center;
			align-items: center;

			.price-header.h3 {
				@include media-breakpoint-down(lg) {
					font-size: $h2-font-size-m;
					line-height: $h2-line-height-m;
				}
			}
		}

		.box-cta {
			&>*,
			.btn {
				width: 100%;
				justify-content: center;
			}
		}

		.box-cta-secondary {
			padding-top: 12px;
			display: flex;
			justify-content: center;

			@include media-breakpoint-up(lg) {
				padding-top: $bu;
			}

			.btn.link span {
				text-align: center;
			}

			// keep colors in inverse container
			.inverse & .btn.link {
				color: $blue-dark;
			}
		}

		.box-cta-note {
			padding-top: 12px;

			.cta-note-wrapper {
				.subscription-link {
					cursor: pointer;
					white-space: nowrap;

					.sub-text {
						white-space: normal;
					}

					.sub-icon {
						@include sprite-icon('info', 16);
						display: inline-block;
						vertical-align: text-bottom;
						cursor: pointer;
					}

					&:hover .sub-icon {
						@include sprite-icon('hover-info', 16);
					}
				}

				//old price is stroked and has specific colors
				.price-old {
					color: $gray05;
					text-decoration-line: line-through;
				}
			}
		}

		.box-footer {
			padding-top: $bu/2;
		}

		// keep colors in inverse container
		.inverse & {
			color: $blue-dark;

			&:not(.multibox):not(.promo-1) [abox="1"] {
				border: none;
			}

			@for $c from 1 through 5 {
				&:not(.multibox).promo-#{$c} [abox]:not([abox="#{$c}"]) {
					border: none;
				}
			}

			&.multibox {
				border: none;
			}
		}
		// --------------------------------------------------------------------------
		// /SLOTS STYLES (Same for both sizes)



		// PROMO Overlay on Standalone box
		// --------------------------------------------------------------------------
		@for $p from 1 through 5 {
			&.promo-#{$p}:not(.multibox) {
				// single box promo is just an orange border (promo overlay is not rendered)
				[abox="#{$p}"] {
					border-color: $orange-bright;
					background-color: $orange-faint;
					border-width: 2px;

					//old price is stroked and has specific colors
					.price-old {
						color: $gray04;
					}
				}
			}
		}
		// --------------------------------------------------------------------------
		// PROMO Overlay on Standalone box


		// MUTLIBOX & FAKE MULTIBOX
		// --------------------------------------------------------------------------
		&.multibox {
			background-color: $white;
			border: 1px solid $orange-light;
			border-radius: $bu*1.5;
			padding: 0 $bu;

			&.show-toggler {
				padding-top: 20px;

				.box-toggler {
					top: -20px;
				}
			}

			&[class*=promo-] {
				padding: $bu;
			}

			// different padding top when there is a toggler
			@include media-breakpoint-up(md) {
				&[class*=promo-] {
					&.show-toggler {
						padding-top: 36px;

						.box-toggler {
							top: -36px;
						}
					}
				}
			}

			// different padding top when there is a toggler - for mobile only on first box
			@include media-breakpoint-up(down) {
				&[class*=promo-1] {
					&.show-toggler {
						padding-top: 36px;

						.box-toggler {
							top: -36px;
						}
					}
				}
			}

			[abox] {
				@include media-breakpoint-up(md) {
					border-top-color: transparent;
					border-bottom-color: transparent;
				}
			}

			.box-top:not([abox="1"]) {
				&:before {
					content: "";
					display: none;
					position: relative;
					height: 1px;
					width: 100%;
					inset-inline: auto; //left: auto; right: auto;
					top: -$bu*1.5;
				}
			}

			@include media-breakpoint-down(md) {
				[abox] {
					border-color: transparent;
				}

				.box-top:not([abox="1"]) {
					&:before {
						display: block;
						background-color: $orange-light;
					}
				}

				// enlarge the top slot for box to which the toggler belongs
				@for $m from 2 through 5 {
					.box-toggler[set="#{$m}"] {
						top: 44px !important; // half of toggler + padding 24px; Important to override promo paddings;

						& ~ .box-guts[set="#{$m}"] .box-top {
							padding-top: 88px;

							&:before {
								top: -88px;
							}
						}
					}
				}
			}
		}

		// Boxes borders in multibox
		@for $p from 1 through 5 {
			$prevBox: $p - 1;
			$nextBox: $p + 1;

			&.multibox {
				// multibox without promo
				@if $p > 1 {
					@include media-breakpoint-up(md) {
						&.boxcount-#{$p} {
							// ALL boxes
							[abox] {
								border-inline-start-color: transparent;
							}

							// last box + top & bottom slots
							[abox="#{$p}"],
							.box-top,
							.box-bottom {
								border-color: transparent;
							}

							// ALL but last box + top & bottom slots
							[abox]:not([abox="#{$p}"]):not(.box-top):not(.box-bottom) {
								border-inline-end-color: $orange-light;

								.inverse & {
									border-inline-end-color: $gray07;
								}
							}
						}
					}
				}

				// multibox with promo
				&.promo-#{$p} {
					[abox="#{$p}"] {
						border-color: $orange-bright !important;
						background-color: $orange-faint;
						border-width: 2px;

						&.box-top {
							border-radius: $bu $bu 0 0;
						}

						&.box-bottom {
							border-radius: 0 0 $bu $bu;
						}
					}

					@include media-breakpoint-up(md) {
						[abox="#{$prevBox}"]:not(.box-top):not(.box-bottom) {
							border-inline-end-color: transparent !important;
						}
					}

					@include media-breakpoint-down(md) {
						[abox="#{$p}"].box-top,
						[abox="#{$nextBox}"].box-top {
							&:before {
								display: none;
							}
						}
					}
				}
			}
		}
		// --------------------------------------------------------------------------
		// /MUTLIBOX & FAKE MULTIBOX

	}
}



// Change of GRID layout in the mobile table
.table {
	@mixin mobileTablePricebox {
		&.show-toggler {
			&,
			&[class*=promo-] {
				.box-toggler {
					position: static;
					transform: none;
					max-width: 100%;
					padding-bottom: 12px !important;

					.toggler {
						width: auto !important;
						//min-width: 100%;
					}

					@for $m from 2 through 5 {
						&[set="#{$m}"] {
							& ~ .box-guts[set="#{$m}"] {
								.box-top {
									padding-top: $bu*1.5;

									&:before {
										top: -$bu*1.5;
									}
								}

								.box-bottom {
									&:before {
										bottom: -$bu*1.5;
									}
								}
							}
						}
					}
				}
			}
		}

		.box-top {
			padding-top: $bu*1.5;

			&:not([multibox=start]) {
				&:before {
					content: "";
					display: block;
					background-color: $gray07;
				}
			}
		}

		.box-bottom[multibox=end] {
			&:before {
				content: "";
				display: block;
				background-color: $gray07;
				position: relative;
				height: 1px;
				width: 100%;
				inset-inline: auto; //left: auto; right: auto;
				bottom: -$bu*1.5;
			}
		}

		[abox] {
			padding-inline: 0;
		}

		.box-table-section-right,
		.box-table-section-left {
			display: flex;
			flex-direction: column;
		}

		.box-table-section-right {
			align-items: end;
			align-self: end;
		}

		.box-table-section-left {
			align-items: start;
		}

		.box-product,
		.box-product-icon {
			display: block;
		}

		.box-product,
		.box-product-icon,
		.box-headline {
			text-align: start;
		}

		.box-platforms,
		.box-toggler {
			justify-content: start;
		}

		.box-platforms {
			padding-bottom: 12px;
		}

		.box-cta {
			width: 100%;
			max-width: 240px;
		}

		.box-instructions,
		.box-annual,
		.box-note-under-price,
		.box-cta-note,
		.box-cta-secondary,
		.box-footer,
		.box-cta {
			text-align: end;
			justify-content: end;
		}
	}
	// /mixin end

	.pricebox .box-container {
		&.show-toggler {
			margin-top: 0;

			&,
			&[class*=promo-] {
				.box-toggler {
					height: fit-content;

					padding: 0;
					width: fit-content;
					min-width: calc(100% - 48px); // minus paddings
					z-index: 4; // above frame and highlight
					position: absolute;
					top: $bu*3.25; // 20 + 32 (toggler hight and top offset)

					.toggler-item {
						overflow: visible;
					}

					@for $m from 2 through 5 {
						&[set="#{$m}"] {
							& ~ .box-guts[set="#{$m}"] .box-top {
								padding-top: $bu*6.5;

								&:before {
									top: -$bu*6.5;
								}
							}
						}
					}
				}
			}
		}

		[abox] {
			padding-inline: $bu*1.5;
		}

		&.boxcount-4,
		&.boxcount-5 {
			[abox] {
				padding-inline: $bu;
			}
		}

		&[class*=promo-] {
			//remove radiuses
			.box-top,
			.box-bottom {
				border-radius: 0;
			}

			@include media-breakpoint-down(lg) {
				[abox] {
					background-color: $white;
					border-width: 0;
				}
			}
		}
	}

	@for $i from 2 through 5 {
		&.columns-#{$i} {
			.pricebox .box-container {

				@if $i < 4 { // for 2 and 3 columns
					&.show-toggler .box-toggler {
						max-width: calc(100% - 48px);
					}

					@include media-breakpoint-down(md) {
						@include mobileTablePricebox;
					}
				}
				@if $i > 3 { // for 4 and 5 columns
					&.show-toggler .box-toggler {
						max-width: calc(100% - 32px);
					}

					@include media-breakpoint-down(lg) {
						@include mobileTablePricebox;
					}
				}
			}
		}
	}
}
