/**************************
// System requirements
**************************/

.product-info {
	.tabs-wrap {
		margin-top: $bu*3.5;
		margin-bottom: $bu*3.5;
	}

	.tab-contents .tab-panel.active {
		display: flex;
		justify-content: center;
	}

	.product-info-faq {
		>.row + .faq {
			margin-top: $bu*2.5;
		}
	}

	.product-info-howto {
		h3 + .howto-subheader {
			margin-top: $bu;
		}

		.feature-items-steps {
			margin-top: $bu*3;
		}
	}

	.product-info-sysreq {
		h3 {
			margin-bottom: $bu;
		}

		// Platform compatibilites
		.sysreq-platforms {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin-bottom: $bu;

			.sysreq-platform {
				display: flex;
				align-items: center;
				gap: $bu/2;
			}
		}

		.list-item {
			margin-top: $bu*2;
		}
	}
}
