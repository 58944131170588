/******************
// NOTICE COMPONENT
******************/

.article {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	position: relative;
	margin: 0 auto;
	background: $white;

	// Default borders. May be overridden via custom classes
	border: 1px solid $gray07;

	.label {
		position: absolute;
		top: 0;
		transform: translateY(-50%);
	}

	.article-title + .article-text {
		margin-top: 12px;

		@include media-breakpoint-up(lg) {
			margin-top: $bu;
		}
	}

	.article-img-wrapper {
		padding: calc((100%/3)*2) 50% 0;
		width: 100%;
		position: relative;
		overflow: hidden;

		.article-img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			width: 100%;
		}
	}

	.article-body {
		position: relative;
		flex-grow: 2;
	}

	// Images and paddings
	&.size-lg {
		max-width: 320px;
		border-radius: $bu*2;
		padding-bottom: $bu*2;

		@include media-breakpoint-up(lg) {
			max-width: none;
		}

		.article-img-wrapper {
			border-radius: $bu*2 $bu*2 0 0;
		}

		.article-body {
			padding: $bu*2 $bu*2 0;
		}

		.article-footer {
			padding: 0 $bu*2;
		}
	}

	&.size-sm {
		max-width: 264px;
		padding: $bu*1.5;
		border-radius: $bu*2 $bu*2 $bu*1.5 $bu*1.5;

		@include media-breakpoint-up(lg) {
			max-width: none;
			padding: $bu*2;
			border-radius: $bu*2.5 $bu*2.5 $bu*2 $bu*2;
		}

		.article-img-wrapper {
			margin-bottom: 12px;
			border-radius: $bu;

			@include media-breakpoint-up(lg) {
				margin-bottom: $bu;
			}
		}
	}

	.article-footer {
		display: flex;
		flex-direction: column;
		margin-top: $bu;
		width: 100%;

		.article-misc {
			margin-bottom: $bu;
			display: inline-flex;
			align-items: center;
			gap: 12px;

			.article-date {
				display: inline-flex;
				align-items: center;
				gap: 12px;

				&:after {
					content: "";
					width: 1px;
					height: 12px;
					border-left: 1px solid $gray05;
				}
			}

			.timetoread {
				display: inline-flex;
				align-items: center;
				gap: $bu/2;
			}
		}

		.btn {
			align-self: flex-end;
		}
	}
}
