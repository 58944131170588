// Flaoting labels - is shrinking when is text field focused
.form-floating {
  position: relative;

	> label {
		color: $blue-bright;
		position: absolute;
		margin: 0;
		text-align: start;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-overflow: -o-ellipsis-lastline;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		cursor: text;
		pointer-events: none;
		transition: .2s;
		display: none; // visible only on desktop in LG and MD sizes
	}

	.form-control::placeholder {
		color: transparent;
	}

	@include media-breakpoint-up(lg) {
		.form-control {
			padding-top: 16px;
			padding-bottom: 4px;

			&:not(.size-sm) ~ label {
				display: block;
				margin-top: 11px;
				left: 24px;
				width: calc(100% - 48px);
			}

			&.size-lg {
				& ~ label {
					left: 28px;
					width: calc(100% - 56px);
					font-size: $body-2-font-size;
					line-height: $body-2-line-height;
				}

				&[class*='form-icon-'] ~ label {
					width: calc(100% - 68px);
				}

				&.form-icon-before ~ label { left: 52px; }
				&.form-icon-after ~ label { right: 52px; }
			}
			
			&:focus ~ label,
			&:not(:placeholder-shown) ~ label {
				font-size: $body-5-font-size;
				line-height: $body-5-line-height;
			}

			&[class*='form-icon-'] ~ label {
				width: calc(100% - 60px);
			}
			
			&.form-icon-before ~ label { left: 48px; }
			&.form-icon-after ~ label { right: 48px; }
		}
	
		// INPUT TEXT FIELD
		input.form-control {
			&.size-lg {
				padding-top: 19px;
				padding-bottom: 5px;

				& ~ label {
					margin-top: 13px;
				}
			}
			
			&:focus,
			&:not(:placeholder-shown) {
				& ~ label {
					margin-top: 5px;
				}

				&.size-lg ~ label {
					margin-top: 4px;
				}
			}
		}

			
		// TEXT AREA
		textarea.form-control {
			&.size-lg {
				padding-top: 30px;
				padding-bottom: $bu;

				& ~ label {
					margin-top: 15px;
				}
			}
		}
	}
}