// operation system icons
// styles for latte template
.platforms {
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	//

	&.size-16 {
		gap: $bu/2;
	}

	&.size-24 {
		gap: 12px;
	}

	&.size-32 {
		gap: $bu;
	}
}