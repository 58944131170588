.tabs-wrap {
	margin: 0 auto;
	text-align: center;

	.container {
		@include media-breakpoint-down(lg) {
			display: flex;
			justify-content: start;
			overflow-x: auto;

			.tabs-wrap-row {
				flex-shrink: 0;
				margin: 0 auto;
			}

			.tabs-wrap-cols {
				display: flex;
				justify-content: start;
				overflow-x: auto;
			}
		}
	}

	// Default variant has full vieport width on mobile
	&.full-width {
		@include media-breakpoint-down(lg) {
			.container {
				max-width: none;
			}
		}
	}

	.nav {
		display: flex;
		//text-align: center;

		@include media-breakpoint-down(lg) {
			flex-shrink: 0;
			margin: 0 auto;
		}

		&.size-lg .toggler-item {
			min-height: $bu*2;
			white-space: normal;
			line-height: normal;

			@include media-breakpoint-up(lg) {
				min-height: $bu*3;
			}
		}

		.tab-item {
			line-height: normal;
	
			// Even items are on desktop, mobile is auto
			@include media-breakpoint-up(lg) {
				@for $i from 1 through 7 {
					&.fraction#{$i} {
						width: calc(100% / #{$i});
					}
				}
			}

			@include media-breakpoint-down(lg) {
				max-width: 240px;
				flex-shrink: 0;

				&:focus-visible {
					outline-offset: -2px;
				}
			}
			
			&.with-icon {
				text-align: start;
			}
		}

		/* &:not(.tabs-process) {
			a,
			button {
				text-decoration: none;
			}
		} */
		a,
		button {
			text-decoration: none;
		}
	}

	.nav-tabs {
		&[class*='theme-']:not(.tabs-process) {
			&.theme-gray07 {
				box-shadow: 0 -1px $gray07 inset;
				.inverse & { box-shadow: 0 -1px $gray04 inset; }
			}

			&.theme-orange-pale { box-shadow: 0 -1px $orange-light inset; }
		}

		.tab-item {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 12px;
			border-bottom: 3px solid transparent;
			border-top: 3px solid transparent;
			color: $gray05;
			font-weight: 700;

			.icon,
			img {
				filter: $filter-gray05;
			}

			&:hover,
			&.active {
				border-bottom-color: $blue-bright;
				color: $blue-dark;

				.icon,
				img {
					filter: $filter-orange-bright;
				}

				.inverse & {
					border-bottom-color: $orange-bright;
					color: $white;
				}
			}

			.inverse & {
				color: $gray07;
			}
		}
		
		.bg-orange-faint:not(.boxed):not(.tabs-process) .tab-item:not(.active):not(:hover){
			color: $gray04;

			.icon,
			img {
				filter: $filter-gray04;
			}
		}



		// Sizes
		&.size-lg {
			height: 72px;

			&:not(.vertical){
				@extend .body-2;
			}

			.tab-item {
				padding: 0 $bu*1.5;
			}

			@include media-breakpoint-up(lg) {
				height: 96px;

				.tab-item {
					padding: 0 $bu*2;
				}
			}
		}

		&.size-sm {
			height: 56px;

			.tab-item {
				padding: 0 $bu;
			}

			@include media-breakpoint-up(lg) {
				height: 72px;

				.tab-item {
					padding: 0 $bu*1.5;
				}
			}
		}



		// wrapper with border
		&.boxed {
			background-color: $white;
			border-radius: 12px;

			&[class*='theme-'] {
				&.theme-gray07 { box-shadow: 0 0 0 1px $gray07 inset; }
				&.theme-orange-pale { box-shadow: 0 0 0 1px $orange-pale inset; }
			}

			.tab-item {
				box-shadow: none;

				&:first-child {
					border-start-start-radius: 12px;
					border-end-start-radius: 12px;
				}
				&:last-child {
					border-start-end-radius: 12px;
					border-end-end-radius: 12px;
				}
			}

			@include media-breakpoint-up(lg) {
				&.size-lg {
					border-radius: 20px;

					.tab-item:first-child {
						border-start-start-radius: 20px;
						border-end-start-radius: 20px;
					}
					.tab-item:last-child {
						border-start-end-radius: 20px;
						border-end-end-radius: 20px;
					}
				}

				&.size-sm {
					border-radius: $bu;

					.tab-item:first-child {
						border-start-start-radius: $bu;
						border-end-start-radius: $bu;
					}
					.tab-item:last-child {
						border-start-end-radius: $bu;
						border-end-end-radius: $bu;
					}
				}
			}
		}

		
		// wrapper with border
		&.tabs-process {
			height: auto;
			box-shadow: none;

			.tab-item {
				background-color: transparent;
				border: none;
				color: $blue-dark;
				padding-inline: $bu/4;
				padding-bottom: 0;
				padding-top: $bu*2.75;
				font-weight: normal;
				text-align: center;
				align-items: start;

				// line trought items, with half of it on first and last item
				&:before {
					position: absolute;
					display: block;
					content: '';
					width: 100%;
					height: 2px;
					background-color: $orange-light;
					top: 15px;
				}

				&:first-child:before {
					width: 50%;
					inset-inline-end: 0;
				}

				&:last-child:before {
					width: 50%;
					inset-inline-start: 0;
				}
				// /line trought items

				// circle behind icon
				&:after {
					position: absolute;
					display: block;
					content: '';
					width: $bu*1.5;
					height: $bu*1.5;
					border-radius: 50%;
					background-color: $orange-bright;
					top: $bu/4;
				}

				.icon {
					position: absolute;
					filter: $filter-white;
					z-index: 2;
					top: 8px;
				}

				&:hover:not(:disabled),
				&.active:not(:disabled),
				&:hover:not(.disabled),
				&.active:not(.disabled) {
					font-weight: 700;
				}

				// different look for an active item
				&.active {
					&:after {
						width: $bu*2;
						height: $bu*2;
						border-radius: 50%;
						top: 0;
					}
	
					.icon {
						display: none;
					}

					// different look for all items after active item
					&~.tab-item {
						&:after {
							background-color: $white;
							border: 2px solid $orange-light;
						}
	
						.icon {
							display: none;
						}
					}
				}

				@include media-breakpoint-up(lg) {
					padding-inline: $bu/2;
					padding-top: $bu*3.5;
					
					// line trought items, with half of it on first and last item
					&:before {
						top: 19px;
					}

					// circle behind icon
					&:after {
						width: $bu*2;
						height: $bu*2;
					}

					.icon {
						top: 12px;
					}

					// different look for an active item
					&.active {
						&:after {
							width: $bu*2.5;
							height: $bu*2.5;
						}
					}
				}

				@include media-breakpoint-down(lg) {
					width: 104px;
				}
			}


		}



		// Vertical variant
		&.vertical {
			text-align: center;

			&.boxed {
				border-radius: 20px;

				@include media-breakpoint-up(lg) {
					border-radius: $bu*1.5;
				}

				.tab-item {
					&:first-child {
						border-start-start-radius: 20px;
						border-end-start-radius: 20px;
					}
					&:last-child {
						border-start-end-radius: 20px;
						border-end-end-radius: 20px;
					}

					@include media-breakpoint-up(lg) {
						&:first-child {
							border-start-start-radius: $bu*1.5;
							border-end-start-radius: $bu*1.5;
						}
						&:last-child {
							border-start-end-radius: $bu*1.5;
							border-end-end-radius: $bu*1.5;
						}
					}
				}
			}

			.tab-item {
				flex-direction: column;
				gap: $bu/4;

				@include media-breakpoint-up(lg) {
					gap: $bu/2;
				}
			
				&.with-icon {
					text-align: center;
					justify-content: start;
				}
			}

			&.size-lg {
				height: auto;

				.tab-item {
					padding: calc(#{$bu}*1.5 - 3px) $bu*1.5; // minus borders
				}

				@include media-breakpoint-up(lg) {
					.tab-item {
						padding: calc(#{$bu}*2 - 3px) $bu*2;
					}
				}
			}

			&.size-sm {
				height: auto;

				.tab-item {
					padding: calc(#{$bu} - 3px) $bu;
				}

				@include media-breakpoint-up(lg) {
					.tab-item {
						padding: calc(#{$bu}*1.5 - 3px) $bu*1.5;
					}
				}
			}
		}
	}
}


// Content table
.tab-contents {
	.tab-panel {
		display: none;

		&.active {
			display: block;
		}
	}
}
