.chrome-banner {
	padding: $bu*2.5 $bu*1.25;
	background: $gray03;

	@include media-breakpoint-up(lg) {
		padding-top: $bu*5;
		padding-bottom: $bu*5;
	}

	.chrombar-info {
		display: flex;
		gap: $bu;
		align-items: center;

		@include media-breakpoint-up(lg) {
			gap: $bu*1.5;
		}

		img {
			width: $bu*3;
			height: $bu*3;

			@include media-breakpoint-up(lg) {
				width: $bu*4;
				height: $bu*4;
			}
		}

		.h4 {
			br {
				display: none;
			}
		}
	}

	// Buttons
	.chrombar-cta {
		@include media-breakpoint-down(lg) {
			margin-inline-start: $bu*4;
		}

		@include media-breakpoint-up(lg) {
			display: flex;
			justify-content: flex-end;
		}
	}
}
