/**************************
// BEFORE FOOTER TEASER V3
**************************/

.bft {
	text-align: center;
	background-color: $gray01;

	&:not(.inverse) {
		background-color: transparent;
	}

	// Product Icon aka Text above header
	.product-name {
		margin-bottom: $bu*.75;
		justify-content: center;

		@include media-breakpoint-up(lg) {
			margin-bottom: $bu;
		}
	}

	.h2 {
		margin: 0;

		+.bft-text {
			margin-top: $bu*.75;

			@include media-breakpoint-up(lg) {
				margin-top: $bu;
			}
		}
	}

	.bft-buttons {
		display: flex;
		justify-content:start;
		flex-direction: column;
		align-items: center;
		gap: $bu $bu*1.5;
		margin-top: $bu*2;

		@include media-breakpoint-up(lg) {
			flex-direction: row;
			margin-top: $bu*2.5;
			align-items: start;
			justify-content: center;
		}
	}

	.bft-hint {
		margin-top: $bu*.75;

		@include media-breakpoint-up(lg) {
			margin-top: $bu;
		}
	}

	.bft-pricebox {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: $bu*2.5;

		@include media-breakpoint-up(lg) {
			margin-top: $bu*3.5;
		}

		.ribbon {
			margin-bottom: $bu*3;

			@include media-breakpoint-up(lg) {
				margin-bottom: $bu*3.5;
			}
		}
	}

	.bft-notes {
		display: flex;
		gap: $bu;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		margin-top: $bu*1.5;

		@include media-breakpoint-up(lg) {
			flex-direction: row;
			margin-top: $bu*2;

			>*{
				padding-left: $bu*1.5;
				padding-right: $bu*1.5;
			}
		}
	}

	.list-item {
		margin-top: $bu*1.5;

		@include media-breakpoint-up(lg) {
			margin-top: $bu*2;
		}
	}

	// in pricebox has to be override top margin for lists
	.bft-pricebox .list-item {
		margin-top: 0;
	}
}
