/* BODY
=================
*/
body {
	font-size: $body-3-font-size-m;
	line-height: $body-3-line-height-m;

	@include media-breakpoint-up(lg) {
		font-size: $body-3-font-size;
		line-height: $body-3-line-height;
	}
}


/* ACCESSIBILITY
=================
*/
// reader only content - hidden from viewport - inherit styles from bootstrap class
.reader-only {
	@extend .visually-hidden;
}


/* Headings
=================
*/
h1,
.h1 {
	font-size: $h1-font-size-m;
	line-height: $h1-line-height-m;
	font-weight: $heading-font-weight;

	@include media-breakpoint-up(lg) {
		font-size: $h1-font-size;
		line-height: $h1-line-height;
	}

	.h0,
	&.h0 {
		font-size: $h1-font-size-m;
		line-height: $h1-line-height-m;
		font-weight: $heading-font-weight;

		@include media-breakpoint-up(lg) {
			font-size: $bu*4.75;
			line-height: 94px;
		}
	}
}

h2,
.h2 {
	font-size: $h2-font-size-m;
	line-height: $h2-line-height-m;
	font-weight: $heading-font-weight;

	@include media-breakpoint-up(lg) {
		font-size: $h2-font-size;
		line-height: $h2-line-height;
	}
}

h3,
.h3 {
	font-size: $h3-font-size-m;
	line-height: $h3-line-height-m;
	font-weight: $heading-font-weight;

	@include media-breakpoint-up(lg) {
		font-size: $h3-font-size;
		line-height: $h3-line-height;
	}
}

h4,
.h4 {
	font-size: $h4-font-size-m;
	line-height: $h4-line-height-m;
	font-weight: $heading-font-weight;

	@include media-breakpoint-up(lg) {
		font-size: $h4-font-size;
		line-height: $h4-line-height;
	}
}

h5,
.h5 {
	font-size: $h5-font-size-m;
	line-height: $h5-line-height-m;
	font-weight: $heading-font-weight;

	@include media-breakpoint-up(lg) {
		font-size: $h5-font-size;
		line-height: $h5-line-height;
	}
}

h6,
.h6 {
	font-size: $h6-font-size-m;
	line-height: $h6-line-height-m;
	font-weight: $heading-font-weight;

	@include media-breakpoint-up(lg) {
		font-size: $h6-font-size;
		line-height: $h6-line-height;
	}
}

.h7 {
	font-size: $h7-font-size-m;
	line-height: $h7-line-height-m;
	font-weight: $heading-font-weight;

	@include media-breakpoint-up(lg) {
		font-size: $h7-font-size;
		line-height: $h7-line-height;
	}
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6, .h7 {
	position: relative; // position for header stripes/highlights
	margin-top: 0;
	border-bottom: none;
	margin-bottom: 0;
}



// Header stripe
.highlight {
	display: inline-flex;
	justify-content: center;
	position: relative;
	width: fit-content;
	z-index: 0;
	white-space: nowrap;

	&:before {
		content: "";
		position: absolute;
		z-index: -1;
		width: 105%;
		height: 61%;
		bottom: 4px;
		left: 50%;
		top: calc(39% - 4px);
		transform: translateX(-50%);
		background: url('../../i/v3/components/illustrations/brush.svg') no-repeat;
		background-position: center;
		background-size: 100% 100%;
	}

	&.green:before { filter: $filter-green-pale;}

	&.orange:before { filter: $filter-orange-pale;}

	&.gray:before { filter: $filter-gray04;}
}


/* Texts
=================
*/
p {margin-bottom: 0;} // overrides BS reboot

// Body Texts (1-5)
@for $s from 1 through 5 {
	$set: map-get($bodyFontSizes, $s);

	.body-#{$s} {
		font-size: map-get($set, "mf");
		line-height: map-get($set, "ml");
	
		@include media-breakpoint-up(lg) {
			font-size: map-get($set, "df");
			line-height: map-get($set, "dl");
		}
		
		// paragraph margin
		@at-root p#{&}:not(:last-of-type) {
			margin-bottom: map-get($set, "ml");
	
			@include media-breakpoint-up(lg) {
				margin-bottom: map-get($set, "dl");
			}
		}
	}
}


small,
.small {
	@extend .body-4;
	font-weight: normal;
}


/* Taglines
=================
*/
.tagline-lg {
	font-size: $bu;
	line-height: $bu*1.25;
	letter-spacing: 1px;

	@include media-breakpoint-up(lg) {
		font-size: 18px;
		line-height: 22px;
		letter-spacing: 1.2px;
	}
}

.tagline-md {
	font-size: 14px;
	line-height: $bu;
	letter-spacing: .8px;

	@include media-breakpoint-up(lg) {
		font-size: $bu;
		line-height: $bu*1.25;
		letter-spacing: 1px;
	}
}

.tagline-sm {
	font-size: $bu*.75;
	line-height: 14px;
	letter-spacing: .6px;

	@include media-breakpoint-up(lg) {
		font-size: 14px;
		line-height: $bu;
		letter-spacing: .8px;
	}
}

[class*='tagline-'] {
	font-weight: 750;

	&.text-blue-bright {
		color: $blue-bright-alt !important;
	}
}



/* Buttons
=================
*/
.btn {
	&.size-lg {
		font-size: $bu;
		line-height: 20px;
		letter-spacing: 1px;

		@include media-breakpoint-up(lg) {
			font-size: 20px;
			line-height: $bu*1.5;
			letter-spacing: 1.2px;
		}
	}

	&.size-md {
		font-size: 14px;
		line-height: $bu;
		letter-spacing: .8px;
	
		@include media-breakpoint-up(lg) {
			font-size: $bu;
			line-height: $bu*1.25;
			letter-spacing: 1px;
		}
	}

	&.size-sm {
		font-size: $bu*.75;
		line-height: 14px;
		letter-spacing: .6px;
	
		@include media-breakpoint-up(lg) {
			font-size: 14px;
			line-height: $bu;
			letter-spacing: .8px;
		}
	}
}


/* LINKS
=================
*/
a:not(.btn) {
	text-decoration: underline;
	color: $blue-dark;

	&.academy-link {
		text-decoration: dotted underline 1px;
	}

	&:hover {
		text-decoration: none;
	}

	&.hoverable-wrap,
	.footer &,
	.footer-links & {
		text-decoration: none;
	}
}



/* Color themes
=================
*/
.text-orange,
.text-orange-all * {
	color: $orange-bright;
}

// on inverse backgrounds
.inverse {
	color: $white;
	background-color: $blue-dark;

	a:not(.btn) {
		color: $white;
	}

	// secondary text
	.text-muted {
		color: $gray07 !important;

		/* a:not(.btn) {
			color: inherit;
		} */
	}
}

// on default and light backgrounds (to be used for light boxes inside inverse theme)
body,
.light {
	color: $body-color;
	background-color: $body-bg;

	a:not(.btn) {
		color: $body-color;
	}

	// secondary text
	.text-muted {
		color: $gray05 !important;

		/* a:not(.btn) {
			color: inherit;
		} */
	}
}

// on gray10 and orange-faint backgrounds
.bg-gray10,
.bg-orange-faint {
	// secondary text
	.text-muted {
		color: $gray04 !important;

		/* a:not(.btn) {
			color: inherit;
		} */
	}
}

