/* Pricing
========================
*/

.pricing {
	direction: ltr; // forced flow even for RTL langs
	display: inline-grid;
	justify-content: center;
	align-items: baseline;
	grid-template-areas:
		"i d . . ."
		"i d . c p"; // CZ

	&.with-old {
		grid-template-areas:
		"i d . o o"
		"i d . c p"; // CZ
	}

	&.currency-left { // EN
		grid-template-areas:
			"c . i d . ."
			"c . i d . p";
		
		&.with-old {
			grid-template-areas:
			"c . i d . o"
			"c . i d . p";
		}
	}

	.integer {grid-area: i;}
	.decimal {grid-area: d;}
	.price-old {grid-area: o;}
	.price-wrapper>.currency {grid-area: c;}
	.period {grid-area: p;}

	// SMALL PRICEBOX ----------------------------------
	&.size-sm {
		grid-template-rows: repeat(2, 17px);
		grid-template-columns: repeat(2, auto) 2px auto; // CZ
		
		&.currency-left {
			grid-template-columns: auto 2px repeat(2, auto) 2px auto; // EN
		}

		.price-old {
			top: 3px;
		}

		.period,
		.currency {
			bottom: 3px;
		}

		@include media-breakpoint-up(lg) {
			grid-template-rows: repeat(2, 22px);

			.price-old {
				top: 4px;
			}
	
			.period,
			.currency {
				bottom: 3px;
			}
		}
	}
	
	// SMALL PRICEBOX ----------------------------------
	&.size-lg {
		grid-template-rows: repeat(2, 22px);
		grid-template-columns: repeat(2, auto) 3px auto; // CZ
		
		&.currency-left {
			grid-template-columns: auto 1px repeat(2, auto) 3px auto; // EN
		}

		.price-old {
			top: 3px;
		}

		.period,
		.currency {
			bottom: 2px;
		}

		@include media-breakpoint-up(lg) {
			grid-template-rows: repeat(2, 30px);
			grid-template-columns: repeat(2, auto) 4px auto; // CZ
		
			&.currency-left {
				grid-template-columns: auto 2px repeat(2, auto) 4px auto; // EN
			}

			.price-old {
				top: 5px;
			}
	
			.period,
			.currency {
				bottom: 6px;
			}
		}
	}
	
	.period {
		align-self: flex-end;
		position: relative;

		// regions with currency and period next to each other need space before the period since there is no slash
		// TODO
		/* 
		.mod-ru-ru &,
		.mod-uk-ua &,
		.mod-ru-ua & */
		// .mod-hu-hu &,
		[lang*='cs-'] & {
			margin-inline-start: 2px;
			
			@include media-breakpoint-up(lg) {
				margin-inline-start: 4px;
			}
		}
	}

	.price-old {
		align-self: flex-start;
		text-align: start;
		position: relative;
	}

	.price-wrapper {
		display: contents;

		.currency {
			align-self: flex-end;
			position: relative;
		}
	}


	.price-wrapper>.installments {
		display: none;
	}
	
	.integer,
	.decimal {
		font-weight: 750;
		margin-bottom: 0;
		align-self: flex-end;
	}

	.period,
	.currency {
		font-weight: 700;
	}

	.period {
		text-align: start;
		white-space: pre;
	}

	.price-old {
		color: $gray05;
		text-decoration-line: line-through;
	}


	&.size-lg {
		.integer,
		.decimal {
			font-size: $h2-font-size-m;
			line-height: $h2-line-height-m;

			@include media-breakpoint-up(lg) {
				font-size: $h2-font-size;
				line-height: $h2-line-height;
			}
		}

		.period,
		.currency,
		.price-old{
			font-size: $body-2-font-size-m;
			line-height: $body-2-line-height-m;

			@include media-breakpoint-up(lg) {
				font-size: $body-2-font-size;
				line-height: $body-2-line-height;
			}
		}
	}

	&.size-sm {
		.integer,
		.decimal {
			font-size: $h3-font-size-m;
			line-height: $h3-line-height-m;
			
			@include media-breakpoint-up(lg) {
				font-size: $h3-font-size;
				line-height: $h3-line-height;
			}
		}

		.period,
		.currency,
		.price-old {
			font-size: $body-4-font-size-m;
			line-height: $body-4-line-height-m;

			@include media-breakpoint-up(lg) {
				font-size: $body-4-font-size;
				line-height: $body-4-line-height;
			}
		}
	}
}

// MIXINS
@mixin priceVertical_S {
	// Vertical price layout for Small variant
	grid-template-rows: repeat(2, auto);
	grid-template-columns: repeat(2, auto) 2px auto;
	// CZ:
	grid-template-areas:
		"i d . c"
		"p p p p";
	
	&.with-old {
		grid-template-rows: repeat(3, auto);
		grid-template-areas:
			"o o o o"
			"i d . c"
			"p p p p";
	}

	// EN:
	&.currency-left {
		grid-template-columns: auto 2px repeat(2, auto);
		grid-template-areas:
			"c . i d"
			"p p p p";
		
		&.with-old {
			grid-template-areas:
				"o o o o"
				"c . i d"
				"p p p p";
		}
	}

	.price-old {
		text-align: center;
	}

	.period {
		text-align: center;
		grid-column: 1 / span 4;
	}
}

@mixin tableVerticalFixes_S($flip: false) {
	// Additional fixes of vertical layout on mobile tables
	&,
	&.currency-left {
		@if $flip {
			justify-items: start;
		} @else {
			justify-items: end;
		}
		grid-template-columns: 1fr;
		grid-template-areas:
			"x"
			"p";
		
		&.with-old {
			grid-template-areas:
				"o"
				"x"
				"p";
		}

		[lang*='ar-'] &,
		[lang*='he-'] & {
			@if $flip {
				justify-items: end;
			} @else {
				justify-items: start;
			}
			
		}

		.price-wrapper {
			display: block;
			grid-area: x;
		}

		.currency {
			vertical-align: bottom;
		}
			
		&:not(.currency-left) .currency {
			padding-inline-start: 2px;
		}
			
		&.currency-left .currency {
			padding-inline-end: 2px;
		}
	}
}


// -------------------------------------------------------------
// Context and Local exceptions for SMALL variant
// -------------------------------------------------------------

// Some locales has vertical stacking on all columns and breakpoints
[lang*='-id'] {
	.pricing.size-sm {
		@include priceVertical_S;
	}
		
	.table .pricing.size-sm {
		@include media-breakpoint-down(md) {
			@include tableVerticalFixes_S;
		}
	}

	.offer-box .pricing.size-sm {
		@include tableVerticalFixes_S($flip: true);
	}
}

// When Pricebox has 5 boxes, on LG the pricing is always stacked vertically
.pricebox .boxcount-5 .pricing.size-sm {
	@include media-breakpoint-up(lg) {
		@include priceVertical_S;
	}
}

// on SM breakpoints in the Table the pricing is always stacked vertically with LONG periods
.table .pricebox .pricing.size-sm.long-period {
	@include media-breakpoint-down(sm) {
		@include priceVertical_S;
		@include tableVerticalFixes_S;
	}
}

// Pricebox with 4 boxes are vertical from MD up and only with LONG periods
.pricebox .boxcount-4 .pricing.size-sm {
	@include media-breakpoint-up(md) {
		&.long-period {
			@include priceVertical_S;
		}
	}
	
	@include media-breakpoint-only(md) {
		&.long-period {
			@include tableVerticalFixes_S;
		}
	}
}


// When table has 5 columns, some locales needs further size modification between LG and XL
[lang*='-id'],
.mod-es-co {
	.table.columns-5 .pricing.size-sm {
		@include media-breakpoint-between(lg, xl) {
			.integer,
			.decimal {
				font-size: $h5-font-size;
				line-height: $h5-line-height;
			}
		}
	}
}

// Pricebox with 3 boxes are vertical from MD with LONG periods, on certain locales
[lang*='ja-'] {
	.pricebox .boxcount-3 .pricing.size-sm {
		@include media-breakpoint-up(md) {
			&.long-period {
				@include priceVertical_S;
			}
		}
	}
}

// Pricebox with 3 boxes in Table are vertical on LG with LONG periods, on certain locales
[lang*='-my'],
.mod-en-za,
.mod-sv-se,
[lang*='es-'] body:not(.mod-es-es),
[lang*='de-'],
[lang*='fr-'],
.mod-it-it,
[lang*='nl-'],
[lang*='pt-'],
.mod-el-gr,
[lang*='cs-'],
.mod-hu-hu,
.mod-ro-ro,
.mod-tr-tr,
[lang*='ar-'],
.mod-he-il,
[lang*='-ph'],
.mod-vi-vn,
.mod-hi-in {
	.table .pricebox .boxcount-3 .pricing.size-sm {
		@include media-breakpoint-only(lg) {
			&.long-period {
				@include priceVertical_S;
			}
		}
	}
}

// OFFERBOX needs for some locales / breakpoints also vertical layout
.mod-en-za,
[lang*='-ph'],
[lang*='es-'],
[lang*='fr-'] body:not(.mod-fr-ch):not(.mod-fr-ca),
[lang*='de-'],
[lang*='pt-'],
.mod-el-gr,
.mod-hu-hu,
.mod-ro-ro,
[lang*='ru-'],
.mod-tr-tr,
.mod-ms-my {
	.offer-box .pricing.size-sm.long-period {
		@include media-breakpoint-up(lg) {
			@include priceVertical_S;
			@include tableVerticalFixes_S($flip: true);
		}
	}
}

.mod-fr-ch {
	.offer-box .pricing.size-sm.long-period {
		@include media-breakpoint-up(sm) {
			@include priceVertical_S;
			@include tableVerticalFixes_S($flip: true);
		}
	}
}

.mod-fr-ca {
	.offer-box .pricing.size-sm.long-period {
		@include priceVertical_S;
		@include tableVerticalFixes_S($flip: true);
	}
}