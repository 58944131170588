.dtyp {
	.dtyp-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: $bu*2.5;

		.h1 {
			margin-bottom: $bu*1.5;
		}

		.h4 {
			margin-bottom: 0;
		}

		// Central image
		.dtyp-img {
			max-width: 100%;
		}

		// if the img is first in content, then remove top margin
		& > :first-child {
			.dtyp-img {
				margin: 0 auto;
			}
		}

		// Manual Download Note
		.dtyp-note {
			display: flex;
			flex-direction: column;
			align-content: center;
			gap: $bu;

			a { font-weight: 750; }
		}

		// Loading Spinner
		.dtyp-progress {
			.spinner {
				display: inline-block;
				width: 30px;
				height: 30px;
				margin: 0 auto;
				background: url('../../i/v3/components/gifs/spinner-dark.gif') no-repeat center center;
			}
		}

		// Manual Download and Spinner elements animation; driven by JS
		.dtyp-note,
		.dtyp-progress {
			opacity: 0;
			transition: opacity 0.33s;
			display: flex;
			align-items: center;
			gap: $bu/2;

			&-show { opacity: 1; }
		}

		// Support
		.dtyp-misc {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: $bu;
		}

		.dtyp-support {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: $bu/2;

			.icon {
				filter: $filter-blue-dark;
			}
		}
	}

	&.inverse .dtyp-support .icon {
		filter: $filter-white;
	}

	// Close button
	.dtyp-close {
		position: absolute;
		right: $bu*2;
		top: $bu*2;
		z-index: 1;
		text-decoration: none;

		.btn-icon {
			filter: $filter-blue-dark;
		}
	}

	&.inverse .dtyp-close .btn-icon {
		filter: $filter-white;
	}

	// Popup
	&.dtyp-popup {
		overflow-y: auto;
		overflow-x: hidden;

		background-color: rgba($white, .98);

		&.inverse {
			background-color: rgba($blue-dark, .98);
		}

		opacity: .8;
		display: flex;
		justify-content: center;
		padding: $bu*5 0 $bu*7;

		position: fixed;
		z-index: 9000;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;

		transition: transform .25s linear;
		transform: scale3d(.8, .8, .8);
		will-change: transform;

		&.is-open {
			opacity: 1;
			transform: scale3d(1, 1, 1);

			@at-root {
				body.dtypup-open {
					overflow-y: hidden;
				}
			}
		}
	}

	// Page -> Not transfered to DS3 yet
	/* &.dtyp-page {
		padding: $bu*4;

		&-firefox {
			position: relative;
			padding-top: $bu*5.5;
		}

		&-chrome {
			.dtyp-arrow-chrome {
				position: fixed;
				z-index: 1;
			}
		}
	} */


	// Chrome Arrow
	.dtyp-arrow-chrome {
		position: absolute;
		width: 160px;
		left: $bu*2;
		bottom: $bu*5.75;
		display: none;
		opacity: 0;
		//will-change: transform; // this should be last resort if performing issues

		@include media-breakpoint-up(lg) {
			display: none; //flex
			flex-direction: column;
		}

		animation:
			animateArrow1 2500ms ease-in-out 3500ms 1 normal,
			animateArrow2 2000ms ease-in-out 6000ms infinite normal;

		.dtyp-arrow-top {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			padding: 24px 0px;
			background-color: $white;
			border-radius: $bu $bu 0 0;
		}

		.dtyp-arrow-bottom {
			position: relative;
			z-index: 1;
			background: $orange-pale;
			padding: $bu*1.5 $bu;
			border-radius: 0 0 $bu*1.5 $bu*1.5;
			text-align: center;
		}

		.dtyp-arrow-pointer {
			position: absolute;
			width: 102px;
			height: 102px;
			left: 50%;
			bottom: 6px;
			background-color: $orange-pale;
			border-radius: 0 0 $bu 0;
			transform: translate(-50%,50%) rotate(45deg);

			.icon {
				position: absolute;
				left: 60%;
				top: 60%;
				transform: rotate(45deg);
			}
		}

		@keyframes animateArrow1 {
			0% {bottom: 500px;}
			33% {bottom: $bu*5.75; opacity: 1}
			60% {bottom: 220px; opacity: 1}
			100% {bottom: $bu*5.75; opacity: 1}
		}

		@keyframes animateArrow2 {
			0% {bottom: $bu*5.75; opacity: 1}
			50% {bottom: 150px; opacity: 1}
			100% {bottom: $bu*5.75; opacity: 1}
		}
	}


}

/* GLOWEB-7941 DTYP chrome UI AB test */
.dtyp.dtyp-popup.dtyp-popup-7941 {
    justify-content: unset;
    flex-direction: column;
	padding: 0 0 7rem;

	.dtyp-download-top {
		font-size: 20px;
		line-height: 24px;
		font-weight: 750;
		padding: $base-unit*1;
		background-color: $green-bright;
		color: $blue-dark;

		.dtyp-top-arrow {
			display: inline-block;
			margin: 0 $base-unit*.5;
			width: 48px;
			height: 48px;
			vertical-align: middle;
			background-image: url('../../i/v2/components/download-popup/dtp-arrow-circle.svg');
		}
	}

	.dtyp-close-container {
		display: flex;
		justify-content: end;
		padding: 32px;

		.dtyp-close {
			position: unset;
			right: unset;
			top: unset;
		}
	}

	.container {
		height: 100%;
    	display: flex;
    	align-items: center;
    	justify-content: center;
	}

	.dtyp-arrow-chrome {
		display: none !important;
	}

}
/* GLOWEB-7941 DTYP chrome UI AB test */

