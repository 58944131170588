/**************************
// List Item
**************************/

.list-item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-wrap: wrap;
	gap: $bu/2;


	&--ordered,
	&--unordered {
		padding: 0;
		margin: $bu*.75 0;

		ul, ol {
			margin-bottom: 0;

			li {
				margin: $bu/2 0 0 $bu/2;
			}
		}

		ol {
			padding-left: $bu*.5;
		}
	}

	&--ordered {
		margin-left: 0;
		list-style-type: none;
		position: relative;

		counter-reset: number;

		> li {
			counter-increment: number;

			&:before {
				content: counter(number) ".";
				font-weight: 700;
				position: absolute;
				left: 0;
			}
		}
	}

	.list-item__body {
		// Grid definition
		display: grid;
		grid-template-areas:
			"none label"
			"icon content"
		;
		grid-template-rows: 1fr;

		.icon {
			grid-area: icon;
		}

		.list-item__label {
			grid-area: label;
			padding-bottom: $bu/2;
		}

		.list-item__content {
			grid-area: content;
			text-align: start;
		}
		// /Grid definition


		&:not(.nofilter) .icon:not([class*='filter-']) {
			filter: $filter-orange-bright;
		}

		.list-item__title {
			font-weight: 700;
		}

		.list-item__button {
			margin-top: $bu/2;
		}
	}


	// LARGE
	&.list-item--large {
		.list-item__body {
			grid-template-columns: $bu*1.5 auto;
			gap: 0 $bu/2;

			@include media-breakpoint-up(lg) {
				grid-template-columns: $bu*2 auto;
			}

			.list-item__title {
				padding-top: 3px;

				@include media-breakpoint-up(lg) {
					padding-top: 4px;
				}
			}

			.list-item__title+.list-item__text {
				@include media-breakpoint-up(lg) {
					margin: -3px 0 0;
				}
			}
		}
	}

	// MEDIUM
	&.list-item--medium {
		.list-item__body {
			grid-template-columns: $bu auto;
			gap: 0 6px;

			@include media-breakpoint-up(lg) {
				grid-template-columns: $bu*1.5 auto;
				gap: 0 $bu/2;
			}

			.icon {
				margin: 3px 0;

				@include media-breakpoint-up(lg) {
					margin: 1px 0;
				}
			}

			.list-item__title+.list-item__text {
				margin-top: -2px;
			}
		}
	}

	// SMALL
	&.list-item--small {
		.list-item__body {
			grid-template-columns: $bu auto;
			gap: 0 $bu/2;

			@include media-breakpoint-up(lg) {
				gap: 0 6px;
			}

			.list-item__label {
				padding-bottom: $bu/4;
			}

			.icon {
				margin: 1px 0;

				@include media-breakpoint-up(lg) {
					margin: 3px 0;
				}
			}

			.list-item__title+.list-item__text {
				margin-top: -2px;
			}

			.list-item__button {
				margin-top: $bu/4;
			}
		}
	}



	// Centered
	&.centered {
		align-items: center;
	}

	// Inline
	&.inline {
		@include media-breakpoint-up(lg) {
			flex-direction: row;
			justify-content: center;
			gap: $bu*3;
		}
	}
}

// Breadcrumbs
.breadcrumb {
	.breadcrumb-item {
		+ .breadcrumb-item {
			&:before {
				//content: var(--#{$prefix}breadcrumb-divider, escape-svg($breadcrumb-divider)) #{"/* rtl:"} var(--#{$prefix}breadcrumb-divider, escape-svg($breadcrumb-divider-flipped)) #{"*/"};
			}
		}
	}
}
