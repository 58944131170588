$areas: ('top', 'headline', 'platforms', 'instructions', 'label', 'annual', 'installments', 'price', 'note-under-price', 'cta', 'cta-note', 'cta-secondary', 'footer', 'bottom');
$rows: length($areas);

$areasTable: ('top', 'product-icon', 'table-section-left', 'toggler', 'table-section-right', 'empty', 'cta-note', 'cta-secondary', 'footer', 'bottom');
$rowsTableMobile: length($areasTable) - 1;


@function createTemplateAreas($count, $list) {
	$areas: null;

	//rows
	@for $x from 1 through length($list) {
		$item: nth($list, $x);
		$result: '';

		//cols
		@for $y from 1 through $count {
			@if $y > 1 { $result: $result + ' '; }
			$result: $result + $item+$y ;
		}
		$areas: $areas $result;
	}
	@return $areas;
}

@function createTemplateAreasMobile($count, $list) {
	$areas: null;

	//rows
	@for $x from 1 through $count {
		@each $item in $list {
			$areas: $areas $item+$x;
		}
	}
	@return $areas;
}

@function createTemplateAreasMobileTable($count, $list) {
	$areas: null;

	// each box
	@for $x from 1 through $count {
		$result: nth($list, 1)+$x + ' ' + nth($list, 1)+$x; //top area is across both inner columns
		$result: $result nth($list, 2)+$x + ' ' + nth($list, 2)+$x; //product icon slot is across both inner columns
		$result: $result nth($list, 3)+$x + ' ' + nth($list, 5)+$x; //left column and right column
		$result: $result nth($list, 4)+$x + ' ' + nth($list, 5)+$x; //toggler and right column
		$result: $result nth($list, 6)+$x + ' ' + nth($list, 5)+$x; //empty filler

		// remaining areas are across both inner columns
		@for $z from 7 through length($list) {
			$result: $result nth($list, $z)+$x + ' ' + nth($list, $z)+$x;
		}

		$areas: $areas $result;
	}
	@return $areas;
}

@function repeatIeValue($count, $value) {
	$result: '';

	@for $x from 1 through $count {
		@if $x > 1 { $result: $result + ' '; }
		$result: $result + $value ;
	}
	@return $result;
}

/*
Attributes description:
	CONTAINER - all settings connected in grid
	ABOX - one setting (may contain more options)
	ABOX MULTIBOX - one setting where option has more maintenances (displays more aboxes per one option)
*/

// Structure styles
.pricebox .box-container {
	// standalone boxes has gaps
	&:not(.multibox) {
		column-gap: $bu;

		@include media-breakpoint-up(lg) {
			column-gap: $bu*2;
		}
	}

	// toggler possition in the grid
	&.show-toggler .box-toggler {
		grid-row-start: 1;
		grid-row-end: 2;
		-ms-grid-row: 1;

		grid-column-start: 1;
		grid-column-end: 1;
		-ms-grid-column: 1;
		-ms-grid-column-span: 1;

		// spread toggler accross more solumns if required
		@include media-breakpoint-up(md) {
			@for $d from 1 through 5 {
				&[span-start="#{$d}"] {
					grid-column-start: $d;
					-ms-grid-column: $d;
				}

				&[span-end="#{$d}"] {
					grid-column-end: span $d;
					-ms-grid-column-span: $d;

					&.nospan {
						grid-column-end: $d;
					}
				}
			}
		}

		@include media-breakpoint-down(md) {
			@for $m from 1 through 5 {
				// vertical position = slots count * abox number
				&[span-start="#{$m}"] {
					$m: $rows * ($m - 1);

					grid-row-start: $m + 1;
					grid-row-end: $m + 2;
				}
			}
		}
	}

	// GRID Areas Assesment
	@for $i from 1 through 5 {
		&.boxcount-#{$i} {
			// basic grid and rows structure
			grid-template-areas: createTemplateAreasMobile($i, $areas);
			grid-template-rows: repeat($rows*$i, auto);
			-ms-grid-rows: repeatIeValue($rows*$i, auto); //IE

			@include media-breakpoint-up(md) {
				grid-template-areas: createTemplateAreas($i, $areas);
				grid-template-rows: repeat($rows, auto);
				-ms-grid-rows: repeatIeValue($rows, auto); //IE
			}

			// column widths for SMALL
			grid-template-columns: 288px;
			-ms-grid-columns: 288px; //IE

			@if $i == 3 {
				// 3 standalone boxes
				@include media-breakpoint-only(md) {
					grid-template-columns: repeat($i, 218px);
					-ms-grid-columns: repeatIeValue($i, 218px); //IE
				}

				&.multibox {
					// 3 boxes in multibox
					@include media-breakpoint-up(md) {
						grid-template-columns: repeat($i, 218px);
						-ms-grid-columns: repeatIeValue($i, 218px); //IE
					}

					@include media-breakpoint-up(lg) {
						grid-template-columns: repeat($i, 256px);
						-ms-grid-columns: repeatIeValue($i, 256px); //IE
					}
				}
			}

			@if $i == 2 {
				// 2 standalone boxes
				@include media-breakpoint-only(md) {
					grid-template-columns: repeat($i, 288px);
					-ms-grid-columns: repeatIeValue($i, 288px); //IE
				}

				&.multibox {
					// 2 boxes in multibox
					@include media-breakpoint-up(md) {
						grid-template-columns: repeat($i, 288px);
						-ms-grid-columns: repeatIeValue($i, 288px); //IE
					}

					@include media-breakpoint-up(lg) {
						grid-template-columns: repeat($i, 280px);
						-ms-grid-columns: repeatIeValue($i, 280px); //IE
					}
				}
			}

			@include media-breakpoint-up(lg) {
				grid-template-columns: repeat($i, 280px);
				-ms-grid-columns: repeatIeValue($i, 280px); //IE
			}

			@include media-breakpoint-up(xl) {
				grid-template-columns: repeat($i, 316px);
				-ms-grid-columns: repeatIeValue($i, 316px); //IE
			}


			// Assign grid area names to listed elements
			@each $area in $areas {
				@for $j from 1 through $i {
					.box-#{$area}[abox="#{$j}"] {
						grid-area: #{$area}#{$j};
						-ms-grid-row: index($areas, $area); //IE
						-ms-grid-column: $j; //IE
					}
				}
			}
		}
	}


	// column widths for LARGE (these shouldnt be in multibox anyway)
	&.size-lg {
		grid-template-columns: max(320px);
		-ms-grid-columns: 320px; //IE

		@include media-breakpoint-up(lg) {
			grid-template-columns: minmax(100%, 380px);
			-ms-grid-columns: 100%; //IE
		}
	}


	[abox] {
		// spanning when there are missing slots (eg: in android/ios options)
		@for $i from 1 through 6 {
			&.box-instructions[span="#{$i}"] {
				grid-row: span $i;
			}
		}
	}
}

// Change of GRID layout in the mobile table
.table {
	@for $b from 1 through 5 {
		// columns width - should be same on higher res. This will change on breakpoints based by number of table columns
		&.columns-#{$b} {
			.pricebox .box-container {
				grid-template-columns: repeat($b, 1fr);

				@if $b < 4 { // for 2 and 3 columns, $b is number of boxes under each other
					@include media-breakpoint-down(md) {
						grid-template-rows: repeat((($rowsTableMobile - 1) * $b), auto);
						grid-template-columns: 1fr 1fr;
						grid-template-areas: createTemplateAreasMobileTable($b, $areasTable);
						column-gap: $bu;

						// Assign grid area names to listed elements
						@each $area in $areasTable {
							@for $j from 1 through $b {
								.box-#{$area}[abox="#{$j}"] {
									grid-area: #{$area}#{$j};
								}
							}
						}

						// give an area name to toggler so it can be positioned
						@for $t from 1 through $b {
							.box-toggler[set="#{$t}"] {
								grid-area: toggler#{$t};
							}
						}
					}
				}

				@if $b > 3 { // for 4 and 5 columns, $b is number of boxes under each other
					@include media-breakpoint-down(lg) {
						grid-template-rows: repeat((($rowsTableMobile - 1) * $b), auto);
						grid-template-columns: 1fr 1fr;
						grid-template-areas: createTemplateAreasMobileTable($b, $areasTable);
						column-gap: $bu;

						// Assign grid area names to listed elements
						@each $area in $areasTable {
							@for $j from 1 through $b {
								.box-#{$area}[abox="#{$j}"] {
									grid-area: #{$area}#{$j};
								}
							}
						}

						// give an area name to toggler so it can be positioned
						@for $t from 1 through $b {
							.box-toggler[set="#{$t}"] {
								grid-area: toggler#{$t};
							}
						}
					}
				}
			}
		}
	}
}