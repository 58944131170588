// Main elements import
@import "./layout/grid";
@import "./layout/header";
@import "./layout/footer";
@import "./layout/region-flags";

// Body overflowing fix
body {
	overflow-x: hidden;
}

// Body pseudo elements to display media info
body:after {
	display: none !important;

	@include media-breakpoint-up(lg) {
		content: 'desktop' !important;
	}
	@include media-breakpoint-down(lg) {
		content: 'mobile' !important;
	}
}

// Smooth scroll (eg. when clicking on achor link)
html {
	// scroll-behavior: smooth; // ---> enabled in bootstrap variables by $enable-smooth-scroll <-----
}
