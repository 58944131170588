.usp-pricebox {
	display: flex;
	border: 1px solid $orange-light;
	border-radius: $bu*1.5;
	width: fit-content;
	margin: 0 auto;

	// LIST
	.usp-list {
		display: flex;
		flex-direction: column;
		align-items: start;
		text-align: start;
		gap: $bu;
		background-color: $orange-faint;
		border-start-start-radius: $bu*1.5;
		border-end-start-radius: $bu*1.5;
		padding: $bu*2 $bu*1.5;

		@include media-breakpoint-up(lg) {
			gap: $bu*1.5;
			padding: $bu*2;

			& > div:first-child {
				padding-top: $bu;
			}
		}

		.usp-list-header,
		.list-item,
		.btn {
			@include media-breakpoint-only(lg) {
				max-width: 528px;
			}

			@include media-breakpoint-down(md) {
				width: 272px;
			}
		}
	}


	// some tweaks when pricebox is rendered
	&.with-toggler {
		@include media-breakpoint-down(xl) {
			&:not(.usp-m-bottom) .usp-list {
				padding-bottom: $bu*3.25;
			}
		}
		
		.usp-price-content {
			justify-content: start;
		}

		.pricebox .box-container.show-toggler {
			margin-top: 0;

			&:not(.multibox) .box-toggler {
				@for $d from 1 through 5 {
					&[set="#{$d}"] {
						@include media-breakpoint-down(md) {
							~.box-guts[set="#{$d}"] {
								.box-top {
									margin-top: 0; // override of margin on pricebox
								}
							}
						}
					}
				}
			}
		}
	}


	@include media-breakpoint-down(xl) {
		flex-direction: column;

		.usp-list {
			border-radius: $bu*1.5 $bu*1.5 0 0;
		}

		&.usp-m-bottom {
			flex-direction: column-reverse;

			.usp-list {
				border-radius: 0 0 $bu*1.5 $bu*1.5;
			}
		}
	}

	&.size-md .usp-list {
		@include media-breakpoint-up(xl) {
			width: 316px;
		}
	}
	
	&.size-lg .usp-list {
		@include media-breakpoint-up(xl) {
			width: 388px;
		}
	}

	// price-content
	.usp-price-content {
		display: flex;
		flex-direction: column;
		justify-content: center;

		.usp-prices {
			.pricebox .box-container.box-container {
				border-width: 0;

				&.boxcount-1 [abox] {
					border: none;
				}

				&.show-toggler {
					@include media-breakpoint-up(md) {
						&:not(.multibox) {padding-top: 0;}
					}

					@include media-breakpoint-down(md) {
						&:not(.multibox) .box-toggler {margin-top: 0;}
					}
				}
			}
		}

		.usp-footer {
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			gap: $bu;
			position: relative;
			padding: $bu;
			margin: 0 $bu;
			min-height: 52px;
			flex-grow: 2;

			@extend .body-5;

			@include media-breakpoint-up(md) {
				flex-direction: row;
			}

			&:before {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				width: 100%;
				height: 1px;
				background-color: $orange-light;
			}
		}
	}
}
